import Vue from 'vue'
import App from './App.vue'
import router from './library/router'
import './library/store'
import './library/axios'
import './library/globalFunctions'
import './library/reuseableFunction'
import './library/globalConstants'
import VueApexCharts from 'vue-apexcharts'
import TrendChart from "vue-trend-chart"
// import PerfectScrollbar from '@nima2142/vue2-perfect-scrollbar'
import VueSweetalert2 from 'vue-sweetalert2'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueCookies from 'vue-cookies'
import VueFroala from 'vue-froala-wysiwyg'
import 'froala-editor/css/froala_editor.pkgd.min.css'
// import $ from 'jquery'
import "./style/index.scss"
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

Vue.use(PerfectScrollbar)
Vue.component('apexchart', VueApexCharts)
Vue.component('date-picker', VuePersianDatetimePicker)
Vue.config.productionTip = false
// Vue.use(VueApexCharts)
Vue.use(VueSweetalert2);
// Vue.use(PerfectScrollbar,{
//   options:{
//       // swipeEasing: false,
//       wheelPropagation: false,
//   }
// });
Vue.use(VueCookies)
Vue.use(TrendChart)
Vue.use(VueFroala)
Vue.$cookies.config('session')
new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount('#app')
