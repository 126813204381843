<template>
<div class='history'>
    <p class="title">
        تاریخچه
    </p>
    <div class="filters">
        <div class="show-selected" for="" @click="openTitleDrop = !openTitleDrop" v-click-outside="onClickOutside">
            <span>{{dropItem}}</span>
            <inline-svg :src="require('@/assets/guid-v.svg')" :class="{'active-svg' : openTitleDrop , 'deActive-svg' : !openTitleDrop}"></inline-svg>
            <!-- <svg class="deActive-svg" width="15" height="7" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.6" d="M5.00012 5C4.69998 5 4.39988 4.88709 4.17135 4.66122L0.114688 0.659464C-0.0380076 0.508831 -0.0382615 0.264347 0.114121 0.113385C0.266543 -0.0375759 0.513848 -0.0378271 0.666563 0.112825L4.7235 4.11485C4.87609 4.26568 5.12389 4.2657 5.27621 4.11514L9.33344 0.112825C9.48617 -0.0378078 9.73348 -0.0375759 9.88588 0.113385C10.0383 0.264347 10.038 0.508831 9.88531 0.659464L5.82834 4.66149C5.6001 4.88713 5.30008 5 5.00012 5Z" />
            </svg> -->
            <div class="dropdown" v-if="openTitleDrop">
                <p v-for="drop , index in drop" :key="index" @click="drop.click">
                    {{drop.title}}
                </p>
            </div>
        </div>
        
        

    </div>
    <div class="tables">
        <AdvancedBasic v-if="basic" />
        <Exchange v-if="exchange" />
        <Withdraw v-if="withdrawal"/>
        <Deposits v-if="deposit"/>
        <Logins v-if="logins" />
    </div>
</div>
</template>

<script>
import AdvancedBasic from './AdvancedBasic.vue'
import Vue2ClickOutside from 'vue2-click-outside'
import Exchange from './Exchange.vue'
import Withdraw from './Withdraw.vue'
import Deposits from './Deposits.vue'
import Logins from './Logins.vue'
import InlineSvg from 'vue-inline-svg';
export default {
    name: 'History',
    components: {
        AdvancedBasic,
        Exchange,
        Deposits,
        Logins,
        InlineSvg,
        Withdraw
    },
    data() {
        return {
            dropdown: false,
            dropItem: 'معاملات آسان و حرفه ای',
            basic: true,
            exchange: false,
            deposit: false,
            withdrawal: false,
            logins: false,
            type: '',
            drop: [{
                    title: "معاملات آسان و حرفه ای",
                    click: () => {
                        this.basic = true
                        this.exchange = false
                        this.deposit = false
                        this.withdrawal = false
                        this.logins = false
                        this.dropItem = "معاملات آسان و حرفه ای"
                    }
                },
                {
                    title: "معاملات صرافی",
                    click: () => {
                        this.basic = false
                        this.exchange = true
                        this.deposit = false
                        this.withdrawal = false
                        this.logins = false
                        this.dropItem = "معاملات صرافی"
                    }
                },
                {
                    title: "برداشت ها",
                    click: () => {
                        this.basic = false
                        this.exchange = false
                        this.deposit = false
                        this.withdrawal = true
                        this.logins = false
                        this.type = 'with'
                        this.dropItem = "برداشت ها"
                    }
                },
                {
                    title: "واریز ها",
                    click: () => {
                        this.basic = false
                        this.exchange = false
                        this.deposit = true
                        this.withdrawal = false
                        this.logins = false
                        this.type = 'dep'
                        this.dropItem = "واریز ها"
                    }
                },
                {
                    title: "ورود ها",
                    click: () => {
                        this.basic = false
                        this.exchange = false
                        this.deposit = false
                        this.withdrawal = false
                        this.logins = true
                        this.dropItem = "ورود ها"
                    }
                }
            ],
            openTitleDrop: false
        }
    },
    directives: {
        clickOutside: Vue2ClickOutside.directive
    },
    methods: {
        openDrop() {
            this.dropdown = true
        },
        onClickOutside() {
            this.dropdown = false
        },
        chooseItem(index) {
            this.dropItem = this.drop[index].title
            console.log(this.drop[index].title)
            this.dropdown = false
            if (index == 0) {
                this.basic = true
                this.exchange = false
                this.deposit = false
                this.logins = false
            } else if (index == 1) {
                this.basic = false
                this.exchange = true
                this.deposit = false
                this.logins = false
            } else if (index == 2) {
                this.basic = false
                this.exchange = false
                this.deposit = true
                this.logins = false
            } else if (index == 3) {
                this.basic = false
                this.exchange = false
                this.deposit = false
                this.logins = true
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.history {
    // max-width:995px;
    background: #FFFFFF;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    box-sizing: border-box;
    padding: 20px;
    flex-grow: 1;
}

.title {
    font-size: 22px;
}

.filters {
    display: flex;
    align-items: center;
    column-gap: 15px;
    row-gap: 20px;
    flex-wrap: wrap;
}

label {
    position: relative;

}

input {
    border: 1px solid rgba(61, 73, 204, 0.1);
    box-sizing: border-box;
    border-radius: 3px;
    width: 200px;
    height: 42px;
    outline: none;
}

input:focus {
    border: 1px solid rgba(61, 73, 204, 1);
}

svg {
    fill: #3D49CC;
    opacity: 0.2;
    position: absolute;
    left: 10px;
    top: 15px;
}

input:focus+svg {
    opacity: 1;
    transform: rotate(-180deg);
    transition: ease-in 0.1s;
}

.dropdown {
    background: #FFFFFF;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    width: 199px;
    height: 200px;
    position: absolute;
    // display: none;
    box-sizing: border-box;
    padding: 10px;
    z-index: 10;
    top: 44px;

    p {
        font-size: 14px;
        cursor: pointer;
    }
}

.deActive-svg {
    opacity: 0.5;
    width: 15px;
}

.active-svg {
    opacity: 1;
    width: 15px;
    transform: rotate(180deg);
}

.show-selected {
    border: 1px solid rgba(61, 73, 204, 0.1);
    box-sizing: border-box;
    border-radius: 3px;
    width: 200px;
    height: 42px;
    display: flex;
    align-items: center;
    position: relative;

    span {
        padding: 0 15px;
    }
}
</style>
