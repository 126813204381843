<template>
<div class="Profile" :key="change +'main'">
    <PersonalInformation :data.sync="userInfo" />
    <BankInfo :key="change" :data.sync="userInfo" :error="duplicateBank" />
    <Authentication :data.sync="userInfo" />
    <p class="error-p" v-if="disabled">
        {{disabled}}
    </p>
    <button class="profile-btn" @click.prevent="submit" :title="disabled" :disabled="disabled">
        ثبت و ارسال اطلاعات
    </button>
</div>
</template>

<script>
import PersonalInformation from './PersonalInformation.vue'
import BankInfo from './BankInfo.vue'
import Authentication from './Authentication.vue'
const inputLabel = {
    'firstName': 'نام',
    'lastName': 'نام خانوادگی',
    'email': 'ایمیل',
    'birthDay': 'تاریخ تولد',
    'mobileNumber': 'شماره موبایل',
    'telephone': 'شماره ثابت',
    'address': 'آدرس',
    'image': 'عکس',
}

const statusLabel = {
    CONFIRMED_IDENTITY: 'اطلاعات هویتی تایید شده',
    AUTHORIZED: 'احراز هویت شده',
    UNAUTHORIZED: 'اطلاعات تکمیل نشده',
    PENDING: 'در انتظار تایید',
    NOT_COMPLETE: 'احراز هویت تکمیل نشده',
    UNIDENTIFIED: 'اطلاعات هویتی تایید نشده',
    INFORMATION_CHANGED_PENDING: 'در انتظار تایید',
    INFORMATION_CHANGED: 'احراز هویت تکمیل نشده'
}
const statusVariant = {
    CONFIRMED_IDENTITY: 'royalblue',
    AUTHORIZED: 'green',
    UNAUTHORIZED: 'red',
    PENDING: 'gold',
    NOT_COMPLETE: 'orange',
    UNIDENTIFIED: 'red',
    INFORMATION_CHANGED_PENDING: 'gold',
    INFORMATION_CHANGED: 'orange'
}

export default {
    name: 'Profile',
    components: {
        PersonalInformation,
        BankInfo,
        Authentication
    },
    data() {
        return {
            bankChange: 'noop',
            profile: true,
            userInfo: {
                // authorized: this.state.userInfo.authorized,
                // pending: this.state.userInfo.pending,
                authenticationType: '',
                firstName: '',
                lastName: '',
                email: '',
                nationalCode: '',
                birthDay: '',
                mobileNumber: '',
                telephone: '',
                address: '',
                bankAccounts: [{
                    shebaCode: "",
                    cardNumber: "",
                    bankName: ""
                }],
                image: '',
            },
            duplicateBank: '',
            change: 0,
        }
    },
    computed: {
        disabled() {
            for (let key in this.userInfo) {
                if (['firstName', 'lastName', 'email', 'nationalCode', 'birthDay', 'mobileNumber', 'telephone', 'bankAccounts', 'image'].includes(key)) {
                    if (key === 'bankAccounts') {
                        if (this.userInfo.bankAccounts.length) {
                            for (let data of this.userInfo.bankAccounts) {
                                if (!data.shebaCode || !data.cardNumber || data.cardNumber.replace(/-/g, '').length < 16) {
                                    return 'اطلاعات بانکی اشتباه است';
                                }

                            }
                        } else {
                            return 'حداقل یک حساب بانکی اضافه نمایید';
                        }
                    } else if (key === 'nationalCode') {
                        if (this.userInfo[key] < 10) {
                            return 'کدملی اشتباه است';
                        }
                    } else if (!this.userInfo[key]) {
                        return inputLabel[key] + ' اجباریست'
                    }
                }
            }
            return false
        },
        label() {
            return statusLabel[this.state.userInfo.authenticationType]
        },
        variant() {
            return statusVariant[this.state.userInfo.authenticationType]
        }
    },
    methods: {
        async check() {
            const res = await this.$axios("/users/account?imageNeeded=true")
            this.userInfo = res.baseDTO
            this.change++
        },
        bankCheck(items) {
            for (let item of items) {
                if (!item.cardNumber || !item.shebaCode) {
                    this.$error('اطلاعات ناقص', "شماره شبا یا کارت بانکی را وارد نکرده اید");
                    return false;
                } else {
                    return true;
                }
            }
        },
        dataURLtoFile(dataurl, filename) {

            let arr = dataurl.split(",");
            let mime = arr[0].match(/:(.*?);/)[1];
            let bstr = atob(arr[1]);
            let n = bstr.length;
            let u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, {
                type: mime
            });
        },
        async submit() {
            if (this.state.loading) return
            this.state.loading = true
            let form_data = new FormData();

            for (let key in this.userInfo) {
                if (key === 'image') {
                    if (typeof this.userInfo.image !== 'string') {
                        form_data.append("file", this.userInfo.image);
                    }
                }

                form_data.append(key, this.userInfo[key]);
            }
            form_data.append("bankAccountsString", JSON.stringify(this.userInfo.bankAccounts));

            if (this.bankCheck(this.userInfo.bankAccounts)) {

                try {
                    let vm = this
                    const config = {
                        onUploadProgress: function (progressEvent) {
                            vm.state.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        }
                    }
                    const res = await this.$axios.post("/users/account", form_data, config)

                    this.state.userInfo.authenticationType = res.baseDTO.authenticationType
                    this.state.userInfo.email = res.baseDTO.email
                    this.state.userInfo.authorized = res.baseDTO.authenticationType === "AUTHORIZED"
                    this.state.userInfo.pending = res.baseDTO.authenticationType === "PENDING"
                    await this.$error('', 'اطلاعات با موفقیت ذخیره شد', 'success')

                    if (res.message.includes("need to be verified")) {
                        this.state.loading = true
                        await this.$axios(`/finotech/send-sms-to-customer?email=${this.userInfo.email}`)
                        this.$router.push('/fino-verifying')
                    } else {
                        this.$getuserInfo()
                    }
                } catch (e) {
                    if (e.response && e.response.data.message.includes("ShebaCode or CardNumber Is For Another User")) {
                        this.$error('', ' شماره شبا یا کارت قبلا وارد شده است.')
                        this.duplicateBank = e.response.data.message
                    }
                }
            }
            this.state.loading = false
        },
    },
    created() {
        this.check()
    }
}
</script>

<style lang="scss" scoped>
.Profile {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    // max-width: 995px;
    flex-grow: 1;
}

.profile-btn {
    background: #80CC52;
    border-radius: 3px;
    color: #FFFFFF;
    font-size: 14px;
    border: none;
    max-width: 360px;
    height: 44px;
    align-self: center;
    width: 100%;
}
.error-p {
    text-align: center;
    color: #EB5757;;
    font-size: 14px;
}
</style>
