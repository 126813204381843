<template>
<div class='advanced'>
    <perfect-scrollbar @ps-y-reach-end="check" ref="scroll">
        <table>
            <tr class="tr-head">
                <td :class="{
                    'toolTd':title.heade==='نوع دستگاه',
                    'browser':title.heade==='مرورگر'
                }" v-for="title , index in title" :key="index">
                    {{title.heade}}
                </td>
            </tr>
            <tr class="tr-body" v-for="tableContent , index in tableContents" :key="index">
                <td>
                    {{$G2J(tableContent.loginRequestedDateTime)}}
                </td>
                <td>
                    {{tableContent.ipAddress}}
                </td>
                <td class="toolTd">
                    {{tableContent.deviceType}}
                </td>
                <td class="browser">
                    {{tableContent.browser}}
                </td>
                <td :class="{'buy':tableContent.isSuccessful , 'sell':tableContent.isSuccessful===false}">
                    <span v-if="tableContent.isSuccessful">موفق</span>
                    <span v-else>ناموفق</span>
                </td>
            </tr>
        </table>
    </perfect-scrollbar>
</div>
</template>

<script>
export default {
    name: 'LoginsHistory',
    components: {

    },
    data() {
        return {
            lastPage: 1,
            tableContents: [],
            page: 0,
            loading: false,
            title: [{
                    heade: "زمان"
                },
                {
                    heade: "آی پی"
                },
                {
                    heade: "نوع دستگاه"
                },
                {
                    heade: "مرورگر"
                },
                {
                    heade: "وضعیت"
                },
            ],
        }
    },
    methods: {
        async check() {
            this.state.loading = true
            if (this.page <= this.lastPage && !this.loading) { //for scroll loading
                this.loading = true
                this.page++ //for scroll loading
                const [res, ] = await this.$http.get('/users/login-history', {
                    params: {
                        size: 10,
                        page: this.page
                    }
                })
                if (res) {
                    this.lastPage = res.totalPages
                    this.tableContents = [...this.tableContents, ...res.content] //for scroll loading
                    // this.tableContents = res.content //for pagination
                }
                this.loading = false
            }
            this.state.loading = false
        },
    },
    mounted() {
        this.check()
    }
}
</script>

<style lang="scss" scoped>
.advanced {
    overflow: scroll
}

table {
    width: 100%;
    text-align: center;
}

tr td:first-of-type {
    text-align: right;
}

tr td:last-of-type {
    text-align: left;
}

.tr-head {
    font-size: 12px;
    color: #999999;
    height: 40px;
    position: sticky;
    top: 0;
    background: #FFFFFF;
}

.tr-body {
    font-size: 12px;
    color: #404040;
    height: 30px;
}

.buy {
    color: #80CC52;
}

.sell {
    color: #D72F70;
}

@media only screen and (max-width:900px) {

    .browser,
    .toolTd {
        display: none;
    }
}
</style>
