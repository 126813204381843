<template>
    <div class="trade-container" :class="{'dark' : state.dark , 'light':state.light , 'neon':state.neon}">
        <NavbarProfile  v-on:onNavNotice="openNotive" v-on:onNavNotif="openNotif" v-on:onNavThem="changeTheme" class="nav-bar"/>
        <Notices v-if="notices" v-on:onChildNotice="closeNotice" />
        <Notification v-if="notif" v-on:onChildNotif="closeNotife"/>
        <div class="trade">
            <AdvancedTrade v-if="$route.name=='AdvancedTrade'" :backGround="backColor" :childs="childsColor"/>
            <BasicTrade v-if="$route.name=='BasicTrade'"/>
        </div>
    </div>
</template>

<script>
import AdvancedTrade from './AdvancedTrade.vue'
import BasicTrade from './BasicTrade.vue'
import NavbarProfile from './NavbarProfile.vue'
import Notices from './Notices.vue'
import Notification from './Notification.vue'
export default {
  name: 'Trade',
  components :{
    AdvancedTrade,
    BasicTrade,
    NavbarProfile,
    Notices,
    Notification,
    
  },
  data() {
      return {
          notices:false,
          notif:false,
          backColor:"#FFFFFF",
          childsColor:"#1F224D",
      }
  },
  methods : {
      openNotive(value) {
          this.notices=value
        //   console.log(this.notices)
      },
      closeNotice(value) {
          this.notices=value
        //   console.log(this.notices)
      },
      closeNotife(value) {
          this.notif=value
      },
      openNotif(value) {
          console.log(this.notif)
          this.notif=value
      },
      changeTheme(value) {
          this.childsColor=value
          this.backColor=value
          console.log(this.backColor)
      },
      
  },
  }
</script>

<style scoped>
    .trade {
        /* margin-top: 50px; */
        min-height: calc(100vh - 50px);
        flex-grow: 1;

    }
    .trade-container {
        height: 100vh;
    }
</style>
