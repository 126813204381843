<template>
<div class="change">
    <p class="title">
        تغییر رمز عبور
    </p>
    <p class="describe">
        برای تغییر رمز عبور، ابتدا رمز عبور فعلی خود را وارد کنید.
    </p>
    <form class="inputs-container" @submit.prevent="submit">
        <label class="personal-label">
            <input class="personal-input" :type="(type1 ? 'password' : 'text')" placeholder="رمز عبور فعلی" @input="passError = ''" v-model="oldpassword">
            <span class="input-title">
                رمز عبور فعلی
            </span>
            <img class="show-pass" src="@/assets/pass.png" alt="" @click="type1=!type1" :class="{'disable_icon' : type1===true}">
            <a>{{passError}}</a>
        </label>
        <label class="personal-label">
            <input class="personal-input" :type="(type2 ? 'password' : 'text')" placeholder="رمز عبور جدید" @input="pass1Error = ''" v-model="newpassword1">
            <span class="input-title">
                رمز عبور جدید
            </span>
            <img class="show-pass" src="@/assets/pass.png" alt="" @click="type2=!type2" :class="{'disable_icon' : type2===true}">
            <a>{{pass1Error}}</a>
        </label>
        <label class="personal-label">
            <input class="personal-input" :type="(type3 ? 'password' : 'text')" placeholder="تکرار رمز عبور" @input="pass2Error = ''" v-model="newpassword2">
            <span class="input-title">
                تکرار رمز عبور
            </span>
            <img class="show-pass" src="@/assets/pass.png" alt="" @click="type3=!type3" :class="{'disable_icon' : type3===true}">
            <a>{{pass2Error}}</a>
        </label>
    </form>
    <div class="change-btn-container">
        <button class="change-btn" @click="submit" :disabled="!oldpassword||
                !newpassword1||
                !newpassword2">
            ثبت تغییرات
        </button>
    </div>

</div>
</template>

<script>
export default {
    name: 'Change',
    components: {

    },
    data() {
        return {
            oldpassword: '',
            newpassword1: '',
            newpassword2: '',
            passError: '',
            pass1Error: '',
            pass2Error: '',
            type1: true,
            type2: true,
            type3: true,
        }
    },
    methods: {
        async submit() {

            let passSent = {
                oldPassword: this.oldpassword,
                password: this.newpassword1
            }
            await this.check2Pass()
            if (this.newpassword1 && this.newpassword2) {
                if (this.checkPass())
                    this.state.loading = true
                try {
                    const res = await this.$axios.post(`/users/change-password`, passSent)
                    if (res.message === "password changed successfully") {
                        this.$error('رمز شما تغییر یافت', '', 'success')
                        this.oldpassword = ''
                        this.newpassword1 = ''
                        this.newpassword2 = ''
                    }
                } catch (error) {
                    if (error.response.data.message === 'Old Password Is Incorrect') {
                        this.passError = 'رمز قبلی اشتباه است.'
                    }
                }
            }
        },
        checkPass() {
            if (!this.oldpassword) {
                this.passError = 'رمز فعلی اجباری';
            } else {
                this.passError = ''
            }
            if (this.newpassword1) {
                if (this.newpassword1.length < 8) {
                    this.pass1Error = 'حداقل 8 کاراکتر';
                } else if (!this.validPass(this.newpassword1)) {
                    this.pass1Error = 'ترکیبی از حروف کوچک و بزرگ و عدد';
                } else if (!this.newpassword2) {
                    this.pass1Error = ''
                    this.pass2Error = 'تکرار رمز اجباری';
                } else if (this.newpassword2 !== this.newpassword1) {
                    this.pass1Error = ''
                    this.pass2Error = 'تکرار رمز مطابقت ندارد';
                } else {
                    this.pass2Error = ''
                }
            }
            if (!this.passError.length && !this.pass1Error.length && !this.pass2Error.length) {
                return true;
            }
        },
        async check2Pass() {
            if (!this.$validPass(this.oldpassword) || this.oldpassword.length < 8) {
                this.$error('اطلاعات وارد شده اشتباه است', 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد')
                return Promise.reject('wrong pass : ' + this.oldpassword)
            }
            if (this.newpassword1 !== this.newpassword2) {
                this.$error('اطلاعات وارد شده اشتباه است', 'تکرار رمز اشتباه است')
                return Promise.reject('repeat failed : ' + this.newpassword1 + '!==' + this.newpassword2)
            }
        },
        validPass(password) {
            const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
            return re.test(password);
        }
    }
}
</script>

<style scoped>
.change {
    background: #FFFFFF;
    border-radius: 3px;
    min-height: 255px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 15px 20px;
    row-gap: 20px;
}

.title {
    font-size: 22px;
    margin: 0;
}

.describe {
    margin: 0;
}

.change-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.show-pass {
    width: 20px;
    height: 15px;
    position: absolute;
    top: 13px;
    left: 7px;
    cursor: pointer;
}

.change-btn {
    color: #FFFFFF;
    font-size: 14px;
    background: #80CC52;
    border-radius: 3px;
    border: none;
    width: 170px;
    height: 44px;
}
.disable_icon {
    opacity: 0.5;
}
.inputs-container {
    display: flex;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
}
</style>
