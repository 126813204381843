<template>
<div class="advanced" :class="{'dark':state.dark , 'light':state.light , 'neon':state.neon}">
    <div class="trade">
        <Markets />
        <div class="chart-trade">
            <AdvancedChart />
            <Exchange :key="'Exchange' + state.orderChanged + $route.params.tradeTo + $route.params.tradeFrom " />
        </div>
        <div class="history">
            <MarketRange :key="'MarketRange'+state.orderChanged + $route.params.tradeTo + $route.params.tradeFrom" />

        </div>
    </div>
    <div class="history">
        <History :key="$route.params.tradeTo + $route.params.tradeFrom"/>
    </div>
</div>
</template>

<script>
import History from './History.vue'
import Markets from './Markets.vue'
import AdvancedChart from './AdvancedChart.vue'
import Exchange from './Exchange.vue'
import MarketRange from './MarketRange.vue'
export default {
    name: 'AdvancedTrade',
    components: {
        History,
        Markets,
        AdvancedChart,
        Exchange,
        MarketRange
    },
    data() {
        return {
            //   backChilds: JSON.parse(JSON.stringify(this.childs))
        }
    },
    //   props : {
    //       backGround:"",
    //       childs:"",
    //   },
    methods: {
    },
    mounted() {
        // alert('eeeee')
        //   console.log(this.backChilds)
    },
}
</script>

<style scoped>
.advanced {
    width: 100%;
    height: 100%;
    /* background: #F2F4F5; */
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.trade {
    display: flex;
    column-gap: 5px;
    row-gap: 10px;
}

.chart-trade {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    /* max-width: 785px; */
    width: 57vw;
    flex-grow: 1;
    /* order: 1; */

}
.history {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
@media only screen and (max-width:1000px) {
    .trade {
        flex-wrap: wrap;
        justify-content: center;
    }

    .chart-trade {
        order: 2;
    }
}
</style>
