<template>
<transition name="modal-fade">
    <div class="modal-backdrop" @click.self="$emit('close')">
        <!-- <img class="close-img" src="@/assets/close.png" alt="" @click="closeLogin"> -->
        <div class="twofa">
            <svg class="close-svg" width="23" height="22" viewBox="0 0 23 22" fill="black" xmlns="http://www.w3.org/2000/svg" @click="close">
                <path d="M20.1625 0.393398L11.2132 9.34272L2.26388 0.393398C1.80622 -0.0642598 1.06426 -0.0642597 0.606602 0.393398C0.148943 0.851057 0.148943 1.59302 0.606602 2.05068L9.55592 11L0.606602 19.9493C0.148943 20.407 0.148943 21.1489 0.606602 21.6066C1.06426 22.0643 1.80622 22.0643 2.26388 21.6066L11.2132 12.6573L20.1625 21.6066C20.6202 22.0643 21.3621 22.0643 21.8198 21.6066C22.2775 21.1489 22.2775 20.407 21.8198 19.9493L12.8705 11L21.8198 2.05068C22.2775 1.59302 22.2775 0.851057 21.8198 0.393398C21.3621 -0.0642597 20.6202 -0.0642597 20.1625 0.393398Z" fill="black" />
            </svg>
            <p class="title">
                برداشت از کیف پول {{$coins[data.relatedCoin].persianName}}
            </p>
            <template v-if="towStepAuthenticationType === 'EMAIL'">
                <p>کد ۶ رقمی که به ایمیل شما ارسال شده است را وارد نمایید.</p>
            </template>

            <template v-else-if="towStepAuthenticationType === 'SMS'">
                <p>کد ۶ رقمی که به شماره
                    <span dir="ltr" style="display:inline-block;direction:ltr">{{mobile}}</span>
                    ارسال شده است را وارد نمایید.
                </p>
            </template>
            <template v-else-if="towStepAuthenticationType === 'GOOGLE_AUTHENTICATOR'">
                <p class="describe">
                    کد شش رقمی تولید شده توسط برنامه Google Authenticator را وارد نمایید.
                </p>
            </template>
            <div>
                <CodeInput :loading="false" @change="onChange" @complete="submit" />
                <p class="countDown-p">
                    {{countDown}} ثانیه
                </p>
            </div>

            <button v-if="countDown===0" class="active-btn" @click="reSendCode">
                ارسال مجدد
            </button>
            <button v-else :disabled=disablebtn @click="submit" class="active-btn">
                ثبت و برداشت 
            </button>
        </div>
    </div>
</transition>
</template>

<script>
import CodeInput from "vue-verification-code-input";
export default {
    name: 'ContinueBW',
    components: {
        CodeInput
    },
    props: ['twoStep', 'qrCode', 'qrLink', 'mobile', 'data'],
    data() {
        return {
            active: false,
            disablebtn: true,
            towStepAuthenticationType: '',
            countDown: 120,
            otp: {
                code: '',
            },
        }
    },

    methods: {
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        onChange(e) {
            this.otp.code = e
        },
        async submit() {
            this.disablebtn = false
            this.state.loading = true
            this.data.code = this.otp.code
            const [res, ] = await this.$http.post('/wallets/withdrawal-request', this.data)
            if (res) {
                this.$emit('close')
                this.$error('درخواست شما با موفقیت ثبت شد', '', 'success')
                // ++this.state.requestChanged

            } else this.disablebtn = true

        },
        checkCode() {
            this.$store.state.successChange = true
            this.$store.state.WalletBtcWiConti = false
            setTimeout(() => {
                this.$store.state.successChange = false;
            }, 1000);
        },
        async reSendCode() {
            this.otp.code = ''
            this.state.loading = true
            const a = {
                EMAIL: 'ایمیل',
                SMS: 'شماره'
            }
            const res = await this.$axios.post("/users/send-code")
            this.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType
            if (res.message === 'code sent successfully.') {
                this.$error('', 'کد به ' + a[this.towStepAuthenticationType] + ' شما ارسال شد', 'success')
                // if (this.countDown === 0)
                this.countDown = 120
                this.countDownTimer()
            }
        },
        close() {
            this.$emit('close')
        },
    },
    async mounted() {
        const res = await this.$axios.post("/users/send-code")
        this.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType
        // this.otpInput = true
        if (this.towStepAuthenticationType !== 'GOOGLE_AUTHENTICATOR') {
            this.counter = true
            this.countDownTimer()
        }
    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
    },
}
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(93, 109, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 120;
}

.twofa {
    background: #FFFFFF;
    border-radius: 3px;
    max-width: 400px;
    width: 90vw;
    min-height: 260px;
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    position: relative;
}

.close-svg {
    position: absolute;
    right: 10px;
    z-index: 111;
}

.title {
    color: #3D49CC;
    font-size: 22px;
    margin: 0 10px;
}

.describe {
    font-size: 12px;
}

.deActive-btn,
.active-btn {

    border-radius: 3px;
    width: 100%;
    height: 44px;
    border: none;
    color: white;
}

.deActive-btn {
    background: rgba(61, 73, 204, 0.5);
}

.active-btn {
    background: rgba(61, 73, 204, 1);
}

.close-img {
    cursor: pointer;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 30px;
}
.countDown-p {
    text-align: right;
    color: #3D49CC;
    font-size: 14px;
}
</style>
