<template>
<transition name="modal-fade">
    <div class="modal-backdrop" @click.self="state.walletBtcWi = false">
        <!-- <img class="close-img" src="@/assets/close.png" alt="" @click="closeLogin"> -->
        <div class="modal">
            <svg class="close-svg" width="23" height="22" viewBox="0 0 23 22" fill="black" xmlns="http://www.w3.org/2000/svg" @click="closeLogin">
                <path d="M20.1625 0.393398L11.2132 9.34272L2.26388 0.393398C1.80622 -0.0642598 1.06426 -0.0642597 0.606602 0.393398C0.148943 0.851057 0.148943 1.59302 0.606602 2.05068L9.55592 11L0.606602 19.9493C0.148943 20.407 0.148943 21.1489 0.606602 21.6066C1.06426 22.0643 1.80622 22.0643 2.26388 21.6066L11.2132 12.6573L20.1625 21.6066C20.6202 22.0643 21.3621 22.0643 21.8198 21.6066C22.2775 21.1489 22.2775 20.407 21.8198 19.9493L12.8705 11L21.8198 2.05068C22.2775 1.59302 22.2775 0.851057 21.8198 0.393398C21.3621 -0.0642597 20.6202 -0.0642597 20.1625 0.393398Z" fill="black" />
            </svg>
            <p class="title">
                برداشت از کیف پول {{$coins[relatedCoin].persianName}}
            </p>
            <div class="inputs-container">
                <label for="" class="personal-label">
                    <input v-model="amount" @input="amount = $toLocal(amount,$decimal[relatedCoin])" class="personal-input" type="text" name="" id="" :placeholder="'مقدار به '+$coins[relatedCoin].persianName">
                    <span class="input-title">
                        مقدار به {{$coins[relatedCoin].persianName}}
                    </span>
                </label>
                <div class="balance-container">
                    <span>
                        موجودی
                    </span>
                    <span @click.prevent="amount=cash">
                        {{$coinUnit[relatedCoin]}} {{$toLocal(cash,$decimal[relatedCoin])}}
                    </span>
                </div>
                <label for="" class="personal-label">
                    <input class="personal-input" v-model="walletAddress" type="text" name="" id="" placeholder="آدرس کیف ‌پول">
                    <span class="input-title">
                        آدرس کیف ‌پول
                    </span>

                </label>
                <div class="warning">
                    <img src="@/assets/warning.svg" alt="">
                    <p>
                        در وارد نمودن آدرس کیف پول خود دقت فرمایید و از آدرس کیف پول معتبر {{$coins[relatedCoin].persianName}} استفاده کنید. مسئولیت و عواقب آدرس کیف پول نامعتبر به عهده خریدار میباشد.
                    </p>
                </div>
                <template>
                    <label for="" class="selectd-net-lable">
                        <div class="selectd-net" @click="drop = !drop">
                            <span v-if="selected_net">{{selected_net}}</span>
                            <span v-else>انتخاب شبکه</span>
                        </div>
                        <div class="dropdown" v-if="drop">
                            <div v-for="(net , index) in nets" :key="index" @click="selectFun(index)">
                                {{net.tokenType}}
                            </div>
                        </div>
                    </label>
                </template>
            </div>
            <hr>
            <div class="min_max_container" v-if="selected_net">
                <div>
                    <span class="min_max_title">
                        حداقل میزان برداشت
                    </span>
                    <span class="min_max_amount">
                        {{maxWithdrawAmount}}
                    </span>
                </div>
                <div>
                    <span class="min_max_title">
                        حداکثر میزان برداشت
                    </span>
                    <span class="min_max_amount">
                        {{minWithdrawAmount}}
                    </span>
                </div>
            </div>
            <div class="fee" v-if="selected_net">
                <span class="min_max_title">
                    کارمزد انتقال
                </span>
                <span>
                    {{wage}}
                </span>
            </div>
            <button class="toPay" @click="withdraw" :disabled="!!readonly">
                <span v-if="readonly">
                    {{readonly}}
                </span>
                <span v-else>ادامه</span>
            </button>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    name: 'WalletBW',
    components: {

    },
    props: {
        relatedCoin: {
            default: ''
        },
        cash: {
            default: 0
        },
        wage: {
            default: 'نامشخص'
        },
        role: {
            default: {}
        },
        exchange: {
            default: false
        },
        nets: {
            default: []
        },
        coins: {
            default: []
        }
    },
    data() {
        return {
            amount: '',
            walletAddress: '',
            local: false,
            token: false,
            transaction: {
                amount: "",
                destinationWalletAddress: "",
                relatedCoin: "",
                tokenType: "",
                code: "",
                walletType: (this.exchange ? 'EXCHANGE' : 'P2P')
            },
            towStepAuthenticationType: "",
            otpInput: false,
            counter: false,
            timeOut: '',
            countDown: 2,
            code: '',
            selected_net: "",
            txIdIsNeeded: false,
            drop: false,
            enable: false,
            minWithdrawAmount: 0,
            maxWithdrawAmount: 0
        }
    },
    computed: {
        readonly() {
            let priceError = ""
            if (!this.$S2N(this.amount)) {
                priceError = 'مقدار ارز اجباریست'
            } else if (this.$S2N(this.amount) > this.$S2N(this.cash)) {
                priceError = 'موجودی کافی نیست'
            } else if (!this.walletAddress) {
                priceError = 'آدرس کیف پول الزامی است'
            } else if (!this.selected_net) {
                priceError = 'انتخاب شبکه الزامی است'
            }
            return priceError
        },
    },
    methods: {
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        closeLogin() {
            this.state.walletBtcWi = false
        },
        nextStep() {
            this.state.WalletBtcWiConti = true
            this.state.walletBtcWi = false
        },
        async withdraw() {
            if (!this.disable && !this.state.loading) {
                this.state.loading = true
                this.transaction.amount = this.$S2N(this.amount)
                this.transaction.destinationWalletAddress = this.walletAddress
                this.transaction.relatedCoin = this.relatedCoin
                this.$emit('gotoTwoStep', this.transaction)
                this.state.walletBtcWi = false
            }
        },
        async send() {
            this.code = ''
            this.state.loading = true
            const a = {
                EMAIL: 'ایمیل',
                SMS: 'شماره'
            }
            const res = await this.$axios.post("/users/send-code")
            this.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType
            if (res.message === 'Verification Code Sent') {
                this.$error('', 'کد به ' + a[this.towStepAuthenticationType] + ' شما ارسال شد', 'success')
                if (this.countDown === 0)
                    this.countDown = 120
                this.countDownTimer()
            }
        },
        selectFun(index) {
            this.selected_net = this.nets[index].tokenType
            this.transaction.tokenType = this.selected_net
            this.txIdIsNeeded = this.nets[index].txIdIsNeeded
            this.enable = this.nets[index].withdrawEnable
            this.wage = this.nets[index].withdrawalFee
            this.minWithdrawAmount = this.nets[index].minWithdraw
            this.maxWithdrawAmount = this.nets[index].maxWithdraw
            this.drop = false

        },
        async submitWithdrawRequest() {
            this.state.loading = true
            this.transaction.code = this.code
            const [res, ] = await this.$http.post('/wallets/withdrawal-request', this.transaction)
            if (res) {
                this.$emit('closeModaleEmit')
                this.$error('درخواست شما با موفقیت ثبت شد', '', 'success')
                    ++this.state.requestChanged

            }
        },
    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
    },
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(93, 109, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    background: #FFFFFF;
    border-radius: 3px;
    max-width: 400px;
    width: 90vw;
    min-height: 332px;
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    position: relative;
    row-gap: 5px;
}

.close-svg {
    position: absolute;
    right: 10px;
    z-index: 111;
}

.title {
    font-size: 22px;
    color: #3D49CC;
}

.toPay {
    background: #3D49CC;
    border-radius: 3px;
    border: none;
    color: #FFFFFF;
    font-size: 14px;
    width: 100%;
    height: 44px;
}

.inputs-container {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-bottom: 5px;
}

.balance-container {
    font-size: 12px;
    color: #999999;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        border-bottom: 1px solid #999999;
    }
}

.add-bank {
    position: absolute;
    top: 35px;
    left: 0;
    font-size: 12px;
    color: rgba(61, 73, 204, 0.6);
    border-bottom: 1px solid rgba(61, 73, 204, 0.6);
    text-align: left;
}

.warning {
    font-size: 12px;
    color: #EB5757;
    text-align: right;
    display: flex;
    align-items: flex-start;
    column-gap: 5px;

    p {
        margin: 0;
    }
}

hr {
    border: none;
    background-color: rgba(61, 73, 204, 0.1);
    height: 1px;
    width: 100%;
}

.fee {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 10px;
    font-size: 12px;
}

.selectd-net-lable {
    position: relative;
    border: 1px solid #525CCC;
    box-sizing: border-box;
    border-radius: 3px;
    height: 44px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    font-size: 18px;
    color: #3D49CC;
    cursor: pointer;
        .selectd-net {
            width: 100%;
            text-align: right;
            font-size: 14px;
        }
}

.dropdown {
    background: #FFFFFF;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    font-size: 14px;
    color: #404040;
    text-align: right;
    padding: 10px;
    position: absolute;
    top: 44px;
    right: 0;
    left: 0;
    z-index: 2;

    div {
        height: 30px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}

.min_max_container {
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    flex-direction: column;
    font-size: 12px;
    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}
.min_max_title {
    opacity: 0.6;
}
</style>
