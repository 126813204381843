import { render, staticRenderFns } from "./AccountSideMenus.vue?vue&type=template&id=16fd49a0&scoped=true&"
import script from "./AccountSideMenus.vue?vue&type=script&lang=js&"
export * from "./AccountSideMenus.vue?vue&type=script&lang=js&"
import style0 from "./AccountSideMenus.vue?vue&type=style&index=0&id=16fd49a0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16fd49a0",
  null
  
)

export default component.exports