var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"advanced"},[_c('table',[_c('tr',{staticClass:"tr-head"},_vm._l((_vm.title),function(title,index){return _c('td',{key:index,class:{
                    'priceTd':title.heade==='قیمت',
                    'amountTd':title.heade==='مقدار',
                    'totalTd':title.heade==='مجموع',
                    'netTd':title.heade==='شبکه ارسال',
                    'statuseTd':title.heade==='وضعیت',
                    'dateTd':title.heade==='زمان',
                }},[_vm._v(" "+_vm._s(title.heade)+" ")])}),0),_vm._l((_vm.tableContents),function(tableContent,index){return _c('tr',{key:index,staticClass:"tr-body"},[_c('td',[_vm._v(" "+_vm._s(_vm.$coins[tableContent.sourceCoin].persianName)+" ")]),_c('td',{class:{'buy':tableContent.orderType.includes('BUY'), 'sell':tableContent.orderType.includes('SELL')}},[(tableContent.orderType==('EXCHANGE_SELL' || 'EXCHANGE_SELL'))?_c('span',[_vm._v(" فروش ")]):_vm._e(),(tableContent.orderType==('EXCHANGE_BUY' || 'EXCHANGE_BUY'))?_c('span',[_vm._v(" خرید ")]):_vm._e()]),_c('td',{staticClass:"priceTd"},[_vm._v(" "+_vm._s(_vm.$toLocal(tableContent.unitPrice,_vm.precision))+" ")]),_c('td',{staticClass:"amountTd"},[_vm._v(" "+_vm._s(_vm.$toLocal(tableContent.amount))+" ")]),_c('td',{staticClass:"totalTd"},[_vm._v(" "+_vm._s(_vm.$toLocal(tableContent.wholePrice,0))+" ")]),_c('td',[_vm._v(" "+_vm._s(tableContent.id)+" ")]),_c('td',{staticClass:"dateTd"},[_vm._v(" "+_vm._s(_vm.$G2J(tableContent.createdAtDateTime))+" ")])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }