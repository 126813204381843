<template>
<div class="history" :class="{'dark-child':this.state.dark , 'light-child':this.state.light , 'neon-child':this.state.neon}">
    <div class="title">
        <p class="title-p" :class="{'active-open':open}" @click="openorders">
            سفارشات باز
        </p>
        <p class="title-p" :class="{'active-open':history}" @click="historyOrders">
            تاریخچه سفارشات
        </p>
    </div>
    <div class="table-container">
        <ActiveOrders v-if="open" />
        <LastOrders v-if="history" />
    </div>
</div>
</template>

<script>

import ActiveOrders from './Orders/ActiveOrders.vue'
import LastOrders from './Orders/LastOrders.vue'
export default {
    name: 'History',
    components: {
        ActiveOrders,
        LastOrders
    },

    data() {
        return {
            open: true,
            history: false,

            light: "#000000"
        }
    },
    methods: {
        openorders() {
            if (!this.open) {
                this.open = true
                this.history = false
            }
        },
        historyOrders() {
            if (!this.history) {
                this.open = false
                this.history = true
            }
        }
    },
    mounted() {
        console.log("color:", this.state.light.background)
    },
}
</script>

<style lang="scss" scoped>
// $back:#ffffff;
.history {
    width: 100%;
    // background-color: $back;
}

.title {
    border-bottom: 1px solid #EDEFF2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 30px;
}

.title-p {
    font-weight: normal;
    font-size: 14px;
    color: #999999;
    cursor: pointer;
    padding: 5px;
    margin: 0;
}

.active-open {
    color: #404040;
    border-bottom: 3px solid #3D49CC;
}

table {
    width: 100%;
    text-align: center;
    padding: 0 10px;
}

tr {
    height: 30px;
}

.tr-head {
    font-weight: normal;
    font-size: 12px;
    color: #999999;
}

.tr-body {
    font-weight: normal;
    font-size: 12px;
    color: #404040;
}

.buy-td {
    color: #80CC52;
}

.sell-td {
    color: #D72F70;
}

tr td:first-of-type {
    text-align: right;
}

tr td:last-of-type {
    text-align: left;
}

@media only screen and (max-width:700px) {

    .price,
    .amount,
    .fee {
        display: none;
    }
}
</style>
