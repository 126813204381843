<template>
    <div class="homepage" id="markets">
        <Navbar v-on:childToSub="SubShow" v-on:childToLogin="LoginShow"  />
        <Baner v-if="$route.name=='Home' || $route.path=='/markets'" />
        <Body v-if="$route.name=='Home' || $route.path=='/markets'" />
        <OnlinePrice v-if="$route.path=='/online-prices' "/>
        <CryptoInfo v-if="$route.path=='/crypto-information'"/>
        <Footer/>
        <Login v-if="state.login" v-on:closeLoginModal="state.login=false" />
        <Subscribe v-if="state.subscribe" v-on:closeSubModal="state.subscribe=false"/>
        <ConfirmEmail v-if="state.confirmEmail"/>
        <ForgetPass v-if="state.forgetPass"/>
        <SetPass v-if="state.setPass"/>
    </div>
</template>

<script>
// import Vue2ClickOutside from 'vue2-click-outside'
import Navbar from "./Navbar.vue"
import Baner from "./Baner.vue"
import Body from './Body.vue'
import Footer from "./Footer.vue"
import Login from '../Authentication/Login.vue'
import Subscribe from '../Authentication/Subscribe.vue'
import OnlinePrice from './OnlinePrice.vue'
import CryptoInfo from '../Trade/CryptoInfo.vue'
import ConfirmEmail from '../Authentication/ConfirmEmail.vue'
import ForgetPass from '../Authentication/ForgetPass.vue'
import SetPass from '../Authentication/SetPass.vue'
export default {
  name: 'HomePage',
  components :{
    Baner,
    Body,
    Navbar,
    Footer,
    Login,
    Subscribe,
    OnlinePrice,
    CryptoInfo,
    ConfirmEmail,
    ForgetPass,
    SetPass
  },
  data() {
      return {
          login:false,
          subscribe:false,
      }
  },
//   directives: {
//       clickOutside: Vue2ClickOutside.directive
//     },
  methods : {
      LoginShow (value) {
          this.state.login=value
      },
      SubShow(value) {
          this.state.subscribe=value
      },
      closeLogin(value) {
          this.state.login=value

      },
      closeSub(value) {
          this.state.subscribe=value
      },
    //   onClickOutside () {
    //     this.$emit('closeLoginModal' , this.close);
    //     this.close=true
    //     console.log("hjhgjhgg")
    //     if(this.login==true) {
    //         this.login=false
    //     }
    //     else 
    //     return true
    //   },
  }
  }
</script>

<style scoped>
    .homepage {
        width: 100%;
        height: 100%;
        background: #3D49CC;
    }
</style>
