<template>
<div class="twoStep">
    <!-- <TwoFAsms :mobile="mobile" v-if="modal=='SMS'" @close="modal='';getTwoStepAuth()" @submit="submitted($event)" />
    <TwoFAgoogle :qr-code="qrCode" :qr-link="qrLink" v-if="modal=='GOOGLE_AUTHENTICATORE'" @close="modal='';getTwoStepAuth()" @submit="submitted($event)" /> -->
    <TwoFA v-if="modal" @close="modal='';getTwoStepAuth();" @submit="submitted($event)" :qr-code="qrCode" :qr-link="qrLink" :mobile="mobile" :two-step="modal" />
    <!-- v-if="emailModal" -->
    <p class="title">
        تأیید هویت دو مرحله‌ای
    </p>
    <p class="describe">
        برای امنیت بیشتر حساب کاربری، فعالسازی یکی از روش های شناسایی دو عاملی الزامی میباید.
    </p>
    <div class="btns-container">
        <button class="towStep-btn" v-for="ways , index in ways" :key="index" @click='ways.click' :class="{'active_step' : state.userInfo.towStepAuthenticationType == ways.value}">
            {{ways.title}}
        </button>
    </div>
</div>
</template>

<script>
// import TwoFAsms from '../../Authentication/TwoFAsms.vue'
// import TwoFAgoogle from '../../Authentication/TwoFAgoogle.vue'
import TwoFA from '../../Authentication/TwoFA.vue'
export default {
    name: 'TwoStep',
    components: {
        // TwoFAsms,
        // TwoFAgoogle,
        TwoFA
    },
    data() {
        return {
            ways: [{
                    title: "ایمیل",
                    value: "EMAIL",
                    click: () => {
                        this.twoStep = 'EMAIL'
                        this.showModal()
                    }
                },
                {
                    title: "Google Authentication",
                    value: "GOOGLE_AUTHENTICATOR",
                    click: () => {
                        this.twoStep = 'GOOGLE_AUTHENTICATOR'
                        this.showModal()
                    }
                },
                {
                    title: "پیامک",
                    value: "SMS",
                    click: () => {
                        this.twoStep = 'SMS'
                        this.showModal()
                    }
                }
            ],
            twoStep: this.state.towStepAuthenticationType,
            googleModal: false,
            emailModal: false,
            smsModal: false,
            qrCode: '',
            qrLink: '',
            mobile: '',
            completedModal: false,
            completedText: '',
            modal: ''
        }
    },
    methods: {
        async getTwoStepAuth() {
            this.twoStep = this.state.userInfo.towStepAuthenticationType

        },
        async showModal() {
            this.state.loading = true
            const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=${this.twoStep}`).catch(() => {
                this.getTwoStepAuth()
            })
            if (res) {
                this.mobile = res.baseDTO.starTwoStepAuthentication || ''
                this.qrCode = res.baseDTO.file || ''
                this.qrLink = res.baseDTO.qrCodeLink || ''
                this.modal = this.twoStep
            }
        },
        async submitted(e) {
            this.completedText = e;
            await this.$error('', e, 'success')
            await this.$getuserInfo()
            this.getTwoStepAuth()
        }
    },
    mounted() {
        this.getTwoStepAuth()
    }
}
</script>

<style scoped>
.btns-container {
    display: flex;
    flex-grow: 1;
    column-gap: 20px;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    row-gap: 20px;
}

.twoStep {
    background: #FFFFFF;
    border-radius: 3px;
    min-height: 183px;
    padding: 15px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.title {
    font-size: 22px;
    margin: 0;
}

.towStep-btn {
    flex-grow: 1;
    height: 42px;
    background: transparent;
    border: 1px solid rgba(128, 204, 82, 0.2);
    box-sizing: border-box;
    border-radius: 3px;
    min-width: 220px;
    width: 20vw;
    max-width: 300px;
    color: #404040;
    font-size: 14px;
}

.active_step {
    border: 2px solid #80CC52;
    box-sizing: border-box;
    border-radius: 3px;
}

/* .towStep-btn:hover {
    border: 1px solid rgba(128, 204, 82, 1);
} */
</style>
