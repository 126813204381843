<template>
<div class="open-orders">
    <CancelOrder v-if="cancel" @close="cancel=false" @remove="removeorder" :amount='amount' :sourceCoin="sourceCoin" :type="type" />
    <p class="title">
        سفارشات باز
    </p>
    <div class="table-container">
        <perfect-scrollbar @ps-y-reach-end="getActiveOrders" ref="scroll">
            <table>
                <tr class="tr-head">
                    <td :class="{'priceTd':tableTitle.title==='قیمت' ,
                            'totalTd' : tableTitle.title==='مجموع',
                            'doneTd' : tableTitle.title==='انجام شده'
                }" v-for="tableTitle , index in tableTitle" :key="index">
                        {{tableTitle.title}}
                    </td>
                </tr>
                <tr class="tr-body" :class="{'sell' : tableContent.orderType.includes('SELL') , 'buy': tableContent.orderType.includes('BUY')}" v-for="tableContent , index in tableContents" :key="index">
                    <td>
                        {{$marketType[tableContent.marketType]}}
                        <!-- {{$coinLabel[tradeFrom]}} - {{$coinLabel[tradeTo]}} -->
                    </td>
                    <td>
                        {{$toLocal(tableContent.amount)}}
                    </td>
                    <td class="priceTd">
                        {{$toLocal(tableContent.unitPrice,precision)}}
                    </td>
                    <td class="totalTd">
                        {{$toLocal(tableContent.overallValue,precision)}}
                    </td>
                    <td>
                        {{tableContent.executedPercent.toFixed(1)}}%
                    </td>
                    <td>
                        <span class="observe" @click="showModal(tableContent)">
                            حذف
                        </span>
                    </td>
                </tr>
            </table>
        </perfect-scrollbar>
    </div>
</div>
</template>

<script>
import CancelOrder from '../../Trade/Orders/CancelOrder.vue'
import {
    tradeFrom,
    tradeTo,
    precision,
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'OpenOrders',
    components: {
        CancelOrder
    },
    data() {
        return {
            cancel: false,
            removeid: 0,
            sourceCoin: '',
            amount: '',
            type: "",
            tableTitle: [{
                    title: "بازار"
                },
                {
                    title: "مقدار"
                },
                {
                    title: "قیمت"
                },
                {
                    title: "مجموع"
                },
                {
                    title: "انجام شده"
                },
                {
                    title: "عملیات"
                }
            ],
            modalshow: false,
            tableContents: [],
            loading: false,
            completedModal: false,
            loop: '',
            page: 0,
            lastPage: 1,
        }
    },
    computed: {
        precision,
        tradeFrom,
        tradeTo
    },
    methods: {
        closemodal() {
            this.modalshow = false
        },
        showModal(e) {
            this.loop.stop()
            this.sourceCoin = e.sourceCoin
            this.amount = e.amount
            this.removeid = e.id
            this.type = e.orderType
            this.cancel = true
        },
        async removeorder(e) {
            this.state.loading = true
            const [res, ] = await this.$http.delete(`orders/${e}`)
            if (res) {
                ++this.state.orderChanged
                this.modalshow = false
                this.completedModal = true

                if (this.state.loop) {
                    this.loop.start()
                } else {
                    this.getActiveOrders()
                }
            }
        },
        async getActiveOrders() {
            if (this.page <= this.lastPage && !this.loading) {
                this.page++
                const res = await this.$axios.get('/orders', {
                    params: {
                        orderStatus: 'IS_OPEN',
                        size: 10,
                        page: this.page
                        // market: 'ALL',
                    }
                })
                if (res) {
                    this.lastPage = res.totalPages
                    this.tableContents = [...this.tableContents, ...res.content] || [] //for scroll loading
                    this.$emit('update:activeLength', this.tableContents.length)
                }
            }
            this.loading = false
        },
    },
    mounted() {
        this.getActiveOrders()
        this.loading = true
        if (this.state.loop) {
            this.loop = new Loop(this.getActiveOrders, 5000, 'activeOrder')
            this.loop.start()
        } else {
            this.getActiveOrders()
        }
    },
    beforeDestroy() {
        this.loop.stop()
    }
}
</script>

<style lang="scss" scoped>
.open-orders {
    background: #FFFFFF;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 20px;
    flex-grow: 1;
    max-height: 310px;
    // overflow: scroll;
}

.title {
    font-size: 20px;
    margin: 0;
}

.table-container {
    overflow: scroll;
    height: 230px;
}

table {
    width: 100%;
    text-align: center;
}

tr {
    height: 30px;
}

tr td:first-of-type {
    text-align: right;
}

.tr-head {
    color: #999999;
    font-size: 12px;
    position: sticky;
    top: 0;
    background: #FFFFFF;
}

.tr-body {
    font-size: 12px;
}

.sell {
    color: #D72F70;
}

.buy {
    color: #80CC52;
}

.observe {
    color: rgba(61, 73, 204, 1);
    border-bottom: 1px solid rgba(61, 73, 204, 1);
    cursor: pointer;
}

@media only screen and (max-width:900px) {

    .priceTd,
    .totalTd {
        display: none;
    }
}
</style>
