<template>
<div class="table-container">
    <table>
        <tr class="tr-head">
            <td>
                نوع
            </td>
            <td class="price">
                قیمت
            </td>
            <td class="amount">
                مقدار
            </td>
            <td>
                مجموع
            </td>
            <td class="fee">
                کارمزد
            </td>
            <td>
                زمان
            </td>
        </tr>
        <tr v-if="!tableContents.length">
            <td colspan="6">
                <p style="color:var(--main-red);text-align:center;width:100%">سفارشی ثبت نشده است</p>
            </td>
        </tr>
        <tr class="tr-body" v-for="(tableContent , index) in tableContents" :key="index">
            <td :class="{'buy-td':tableContent.orderType.includes('BUY'), 'sell-td':tableContent.orderType.includes('SELL')}">
                <span v-if="tableContent.orderType.includes('BUY')">
                    خرید
                </span>
                <span v-else>
                    فروش
                </span>
            </td>
            <td class="price">
                {{$toLocal(tableContent.unitPrice,precision)}}
            </td>
            <td class="amount">
                {{$toLocal(tableContent.amount)}}
            </td>
            <td>
                {{$toLocal(tableContent.wholePrice,precision)}}
            </td>
            <td class="fee">
                <!-- {{ordersHistory.fee}} -->
            </td>
            <td>
                {{$G2J(tableContent.createdAtDateTime,'faDateTime')}}
            </td>
        </tr>
    </table>
</div>
</template>

<script>
import {
    tradeTo,
    tradeFrom,
    precision,
    statusColor,
    statusLabel,
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'LastOrders',
    components: {

    },
    data() {
        return {
            modalshow: false,
            removeid: 0,
            tableContents: [],
            settings: {
                suppressScrollX: false
            },
            loading: false,
            loop: ''
        }
    },
    computed: {
        tradeTo,
        tradeFrom,
        precision,
        statusColor,
        statusLabel
    },
    methods: {
        async getLastOrders() {
            let res = await this.$axios.get('/orders', {
                params: {
                    market: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo,
                    page: 1,
                    size: 20
                }
            })
            if (res.content)
                res.content = res.content.filter(i => i.orderStatus !== 'IS_OPEN');
            this.tableContents = res.content || [];
            this.loading = false
            console.log('this.tableContents', this.tableContents);
        },
    },
    mounted() {
        this.loading = true
        if (this.state.loop) {
            this.loop = new Loop(this.getLastOrders, 5000, 'lastOrder')
            this.loop.start()
        } else {
            this.getLastOrders()
        }
    },
    beforeDestroy() {
        this.loop.stop()
    }
}
</script>

<style scoped>
.table-container {
    width: 100%;
    height: 250px;
    overflow: scroll;
}

table {
    width: 100%;
    text-align: center;
    padding: 0 10px;
}

tr {
    height: 30px;
}

.tr-head {
    font-weight: normal;
    font-size: 12px;
    color: #999999;
}

.tr-body {
    font-weight: normal;
    font-size: 12px;
    color: #404040;
}

.buy-td {
    color: #80CC52;
}

.sell-td {
    color: #D72F70;
}

tr td:first-of-type {
    text-align: right;
}

tr td:last-of-type {
    text-align: left;
}

@media only screen and (max-width:700px) {

    .price,
    .amount,
    .fee {
        display: none;
    }
}
</style>
