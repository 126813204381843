<template>
<div>
    <TwoFA v-if="this.state.twofa" />
    <TwoFAsms v-if="this.state.twofaSMS" />
    <TwoFAgoogle v-if="this.state.twofaGoogle" />
    <SuccessChangePass v-if="this.state.successChange" />
    <AuthenModal v-if="this.state.authenModal" />
    <WalletTD v-if="this.state.walletToomanDe" />
    <WalletTW v-if="this.state.walletToomanWi" />
    <!-- <WalletBD v-if="this.state.walletBtcDe" /> -->
    <!-- <WalletBW v-if="this.state.walletBtcWi" />
    <ContinueBW v-if="this.state.WalletBtcWiConti" /> -->
    <NavbarProfile />
    <div class="account-body">
        <div class="account-contaner">
            <AccountSideMenus />
            <Profile v-if="$route.path=='/profile'" />
            <Wallet v-if="$route.path=='/wallet'" />
            <OtcWallet v-if="$route.path=='/otc-wallet'" />
            <Setting v-if="$route.path=='/setting'" />
            <InviteFriend v-if="$route.path=='/invite-friends'" />
            <Notification v-if="$route.path=='/notifications'" />
            <Dashboard v-if="$route.path=='/dashboard'" />
            <History v-if="$route.path=='/history'" />
        </div>
    </div>
    <Footer />
</div>
</template>

<script>
import NavbarProfile from '../Trade/NavbarProfile.vue'
import Footer from '../HomePage/Footer.vue'
import AccountSideMenus from './AccountSideMenus.vue'
import Profile from '@/components/Profile/Authorization/Profile.vue'
import Wallet from '../Profile/Wallet/Wallet.vue'
import OtcWallet from '../Profile/Wallet/OtcWallet.vue'
import Setting from '../Profile/Settings/Setting.vue'
import InviteFriend from '../InviteFriend/InviteFriend.vue'
import Notification from '../Profile/Notifications/Notification.vue'
import TwoFA from '../Authentication/TwoFA.vue'
import SuccessChangePass from '../Modals/SuccessChangePass.vue'
import TwoFAsms from '../Authentication/TwoFAsms.vue'
import TwoFAgoogle from '../Authentication/TwoFAgoogle.vue'
import Dashboard from '../Profile/Dashboard/Dashboard.vue'
import History from '../Profile/History/History.vue'
import AuthenModal from '../Modals/AuthenModal.vue'
import WalletTD from '../Profile/Wallet/WalletTD.vue'
import WalletTW from '../Profile/Wallet/WalletTW.vue'
// import WalletBD from '../Modals/WalletBD.vue'
// import WalletBW from '../Modals/WalletBW.vue'
// import ContinueBW from '../Modals/ContinueBW.vue'
export default {
    name: 'Account',
    components: {
        NavbarProfile,
        Footer,
        AccountSideMenus,
        Profile,
        Wallet,
        OtcWallet,
        Setting,
        InviteFriend,
        Notification,
        TwoFA,
        SuccessChangePass,
        TwoFAsms,
        TwoFAgoogle,
        Dashboard,
        History,
        AuthenModal,
        WalletTD,
        WalletTW,
        // WalletBD,
        // WalletBW,
        // ContinueBW
    },
    data() {
        return {
            twofa: false
        }
    },
    methods: {

    }
}
</script>

<style scoped>
.account-body {
    background: #F2F4F5;
    display: flex;
    flex-grow: 1;
    padding: 50px 10px;
    box-sizing: border-box;
    justify-content: center;
    min-height: calc(100vh - 40px);

}

.account-contaner {
    /* max-width: 1250px; */
    display: flex;
    column-gap: 30px;
    flex-grow: 1;
}

@media only screen and (max-width:900px) {
    .account-contaner {
        column-gap: 5px !important;
    }
}
</style>
