<template>
<div class="market-range" :class="{'dark-child':this.state.dark , 'light-child':this.state.light , 'neon-child':this.state.neon}">
    <div class="sell-market">
        <table>
            <tr class="tr-head">
                <td>
                    قیمت
                </td>
                <td>
                    مقدار
                </td>
                <td>
                    مجموع
                </td>
            </tr>
            <tr v-if="tableContentsSell.length==0">
                <td colspan="4">
                    <p style="width:100%;text-align:center;color:#D72F70">صف فروش خالی است</p>
                </td>
            </tr>
            <tr v-else class="tr-body sell-body" v-for="(crypto , index) in tableContentsSell" :key="index" :style="{backgroundImage: `linear-gradient(90deg, ${state.trSell } ${crypto.executedPercent}% , transparent ${crypto.executedPercent}%)` }" @click="SellPrice(crypto)">
                <td class="td-sell">
                    {{$toLocal(crypto.unitPrice,tradeTo === 'TOMAN' ? 0 : $prcesion[tradeFrom])}}
                </td>
                <td>
                    {{$toLocal(crypto.volume,$decimal[tradeFrom])}}
                </td>
                <td>
                    {{$toLocal(crypto.wholePrice,$decimal[tradeFrom])}}
                </td>
            </tr>
        </table>
    </div>

    <div class="price" :class="{'positive-change':state.shopInfo.change24 > 0 , 'negative-change':state.shopInfo.change24 < 0}">
        {{$toLocal(state.shopInfo.lastPrice,precision) || 0}}
    </div>
    <div class="buy-market">
        <table>
            <tr v-if="tableContentsBuy.length==0">
                <td colspan="4" >
                    <p style="width:100%;text-align:center;color:#80CC52">صف خرید خالی است</p>
                </td>
            </tr>
            <tr v-else class="tr-body buy-body" v-for="(crypto , index) in tableContentsBuy" :key="index" :style="{backgroundImage: `linear-gradient(90deg, ${state.trBuy } ${crypto.executedPercent}% , transparent ${crypto.executedPercent}%)` }" @click="BuyPrice(crypto)">
                <td class="td-buy">
                    {{$toLocal(crypto.unitPrice,tradeTo === 'TOMAN' ? 0 : $prcesion[tradeFrom])}}
                </td>
                <td>
                    {{$toLocal(crypto.volume,$decimal[tradeFrom])}}
                </td>
                <td>
                    {{$toLocal(crypto.wholePrice,$decimal[tradeFrom])}}
                </td>
            </tr>
        </table>
    </div>
</div>
</template>

<script>
import {
    tradeTo,
    tradeFrom,
    precision,
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'MarketRange',
    components: {

    },
    data() {
        return {
            sellInfo:{
                unitPrice:0,
                amount:0,
            },
            buyInfo:{
                unitPrice:0,
                amount:0,
            },
            tableContentsSell: [],
            tableContentsBuy: [],
            loading: false,
            settings: {
                suppressScrollX: true
            },
            loop: ''
        }
    },
    methods: {
        async getMarketDepth() {
            const [res, ] = await this.$http.get('/orders/order-book', {
                params: {
                    marketType: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo
                }
            })
            if (res) {
                this.tableContentsSell = res.sellOrderBook.slice(0, 11)
                this.tableContentsBuy = res.buyOrderBook.slice(0, 11)
                this.state.shopInfo.minSell = res.sellOrderBook.length ? res.sellOrderBook[0].unitPrice : 0
                this.state.shopInfo.maxBuy = res.buyOrderBook.length ? res.buyOrderBook[0].unitPrice : 0
            }
            this.loading = false
        },
        SellPrice(e) {
            // this.sellInfo.unitPrice = e.unitPrice
            this.state.sellInfo.amount = e.volume
            // this.$emit('sellPrice',this.sellInfo)
        },
        BuyPrice(e) {
            this.state.buyInfo.amount = e.volume
        }
    },
    computed: {
        tradeFrom,
        tradeTo,
        precision,

        createBackgroundBuy() {
            return `linear-gradient(90deg, ${this.state.trBuy} ${this.angle1}%, transparent ${this.angle1}%)`;
        },
        createBackgroundSell(index) {
            return `linear-gradient(90deg, ${this.state.trSell }${this.tableContentsSell[index].executedPercent}% , transparent 100%)`;
        }
    },
    mounted() {
        this.loading = true
        if (this.state.loop) {
            this.loop = new Loop(this.getMarketDepth, this.state.time, 'marketDepth:' + this.tradeFrom + '-' + this.tradeTo)
            this.loop.start()
        } else {
            this.getMarketDepth()
        }
    },
    beforeDestroy() {
        this.loop.stop()
    },

}
</script>

<style scoped>
.market-range {
    /* max-width: 315px; */
    width: 22vw;
    display: flex;
    flex-direction: column;
    /* background: white; */
    border-radius: 3px;
    /* order: 2; */
    min-width: 200px;
    height: 100%;
}

table {
    width: 95%;
    text-align: center;
    border-collapse: collapse;
    box-sizing: border-box;
    margin: 0 auto;
}

tr {
    margin: 3px 0;
}

td {
    padding: 0;
}

tr td:first-of-type {
    text-align: right;
    padding-right: 7px;
}

tr td:last-of-type {
    text-align: left;
    padding-left: 7px;
}

.tr-head {
    font-weight: normal;
    font-size: 12px;
    color: #999999;
    height: 40px;
}

.tr-body {
    font-weight: normal;
    /* font-size: 12px; */
    font-size: clamp(10px, 1vw, 12px);
    height: 26px;
    cursor: pointer;
}

.price {
    font-weight: normal;
    font-size: 14px;
    border-bottom: 1px solid #EDEFF2;
    border-top: 1px solid #EDEFF2;
    box-sizing: border-box;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.td-buy {
    color: #80CC52;
}

.td-sell {
    color: #D72F70;
}
.buy-body:hover {
   background: rgba(231, 255, 217, 0.4);
}
.sell-body:hover {
     background: rgba(255, 217, 232, 0.2);
}
.buy-market,
.sell-market {
    flex-grow: 1;
    max-height: 365px;
    overflow: scroll;
}

@media only screen and (max-width:1000px) {
    .market-range {
        order: 1;
        flex-grow: 1;
        width: 100%;
    }
}
</style>
