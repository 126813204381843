<template>
<transition name="modal-fade">
    <div class="modal-backdrop" @click="$emit('close')">
        <div class="modal">
            <img @click="$emit('close')" src="@/assets/close.svg" class="btn-close" alt="">
            <span class="message-title">
                آیا از حذف سفارش مطمئن هستید؟
            </span>
            <div class="informations">
                <div>
                    <span class="informations-title">
                       نوع سفارش
                    </span>
                    <span :class="{'sell' : type.includes('SELL') , 'buy' : type.includes('BUY')}">
                        {{(type.includes('SELL') ? 'فروش' : 'خرید' )}}
                    </span>
                </div>
                <div>
                    <span class="informations-title">
                        رمز ارز
                    </span>
                    <span>
                        {{$coins[sourceCoin].persianName}}
                    </span>
                </div>
                <div>
                    <span class="informations-title">
                        مقدار
                    </span>
                    <span>
                        {{amount}}
                    </span>
                </div>
            </div>
            <button class="confirm-btn" @click="yes">
                تایید
            </button>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    name: 'CancelOrder',
    components: {

    },
    props:['amount','sourceCoin' , 'type'],
    data() {
        return {

        }
    },
    methods: {
        yes() {
            this.$emit('remove')
        }

    },
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 200px;
    // min-height: 470px;
    max-width: 95%;
    position: relative;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
}

.btn-close {
    position: absolute;
    left: 15px;
    top: 15px;
    height: 22px;
    width: 22px;
    transition: 0.5s;
    cursor: pointer;
    z-index: 5;
}
.informations {
    display: flex;
    row-gap: 20px;
    flex-direction: column;
    width: 100%;
        div {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
        }
}
.informations-title {
    opacity: 0.6;
}
.sell {
    color : #D72F70
}
.buy {
    color: #80CC52;
}
.confirm-btn {
    width: 100%;
    border-radius: 3px;
    border: none;
    color: #FFFFFF;
    width: 100%;
    font-size: 14px;
    height: 44px;
    background: #3D49CC;
}
</style>
