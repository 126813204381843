<template>
    <div class="setting">
        <TwoStep/>
        <Change/>
    </div>
</template>

<script>
import TwoStep from './TwoStep.vue'
import Change from './Change.vue'
export default {
  name: 'Setting',
  components :{
    TwoStep,
    Change
  },
  data() {
      return {
         
      }
  },
  methods : {
      
  }
  }
</script>

<style scoped>
    .setting {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        /* max-width: 995px; */
        width: 100%;
    }
</style>
