<template>
<table>
    <tr class="tr-head">
        <td>
            بازار
        </td>
        <td>
            قیمت
        </td>
        <td>
            تغییرات
        </td>
    </tr>
    <tr v-if="!loading && !tableContents.length" class="tr-body">
        <td>
            <p style="width:100%;text-align:center;color:red">در حال حاضر بازار فعالی وجود ندارد</p>
        </td>
    </tr>
    <tr class="tr-body" v-for="(tableContent , index) in tableContents" :key="tableContent.price +''+ index" @click="goto($coins[tableContent.destCoinSymbol].coin)">
        <td>
            {{$coins[tableContent.destCoinSymbol].persianName}}
            <!-- {{$coins}} -->
        </td>
        <td>
            <span>
                {{$toLocal(tableContent.price,toman ? 0 : 2)}}
            </span>
        </td>
        <td :class="{'positive-change':tableContent.price24hrChangePercent > 0 , 'negative-change':tableContent.price24hrChangePercent < 0}">
            <span v-if="tableContent.price24hrChangePercent < 0">-</span>
            {{$toLocal(Math.abs(tableContent.price24hrChangePercent),1)}}٪
            <span v-if="tableContent.price24hrChangePercent > 0">+</span>
        </td>
    </tr>
</table>
</template>

<script>
// import OverLoading from "@/components/Tools/over-loading";
import {
    tradeTo,
    tradeFrom,
    Loop
} from "@/library/reuseableFunction";
export default {
    name: "RealPriceTableToman",
    components: {
        // OverLoading
    },
    props: {
        toman: {
            default: true
        }
    },
    data() {
        return {
            tableContents: [],
            loading: false,
            loop: ''
        }
    },
    watch: {
        'toman'() {
            this.tab()
        }
    },
    computed: {
        tradeTo,
        tradeFrom
    },
    methods: {
        async getPrice() {
            const link = this.toman ? '/coins/price/usd' : '/coins/price/usdt'
            let [res, ] = await this.$http.get(link, {
                params: {
                    include24hrChange: true,
                    includeWeeklyChange: false
                }
            })
            this.loading = false
            if (res) {
                this.tableContents = res
                this.sort()
            }
        },
        goto(e) {
            this.$router.push('/advanced-trade/' + (this.toman ? 'TOMAN' : 'TETHER') + `/${e}`)
                ++this.state.orderChanged
        },
        sort() {
            this.tableContents.sort((a, b) => this.$sortCoins.indexOf(a.sourceCoinSymbol) - this.$sortCoins.indexOf(b.sourceCoinSymbol))

        },
        tab() {
            // this.loop.stop()
            this.tableContents = []
            if (this.state.loop) {
                this.beforeLoop()
            } else {
                this.getPrice()
            }
        },
        beforeLoop() {
            this.loading = true
            if (this.state.loop) {
                this.loop = new Loop(this.getPrice, 5000, 'realPrice' + (this.toman ? 'Toman' : 'Tether'))
                this.loop.start()
            } else {
                this.getPrice()
            }
        }
    },
    mounted() {
        this.beforeLoop()
    },
    beforeDestroy() {
        this.loop.stop()
    }
}
</script>

<style scoped>
.tr-head {
    font-weight: normal;
    /* font-size: 12px; */
    font-size: clamp(10px, 1vw, 12px);
    color: #999999;
    height: 28px;
    margin: 4px 0;
}

.tr-body {
    font-weight: normal;
    /* font-size: 12px; */
    font-size: clamp(10px, 1vw, 12px);
    color: #404040;
    height: 24px;
    margin: 4px 0;
    cursor: pointer;
}
.tr-body:hover {
    background: rgba(61, 73, 204, 0.05);
}

table {
    width: 100%;
    /* max-width: 315px; */
    padding: 0 10px;
    text-align: center;
    table-layout: fixed;
    border-collapse: collapse;
}

tr td:last-of-type {
    text-align: left;
     padding-left: 10px;
}

tr td:first-of-type {
    text-align: right;
    padding-right: 10px;
}

.positive-change {
    color: #80CC52;
}

.negative-change {
    color: #D72F70;
    direction: ltr;

}
</style>
