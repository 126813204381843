<template>
<div class="table-container">
    <CancelOrder v-if="cancel" @close="cancel=false" @remove="removeorder" :amount='amount' :sourceCoin="sourceCoin" :type="type"/>
<table>
    <tr class="tr-head">
        <td>
            نوع
        </td>
        <td class="price">
            قیمت
        </td>
        <td class="amount">
            مقدار
        </td>
        <td>
            مجموع
        </td>
        <td>
            انجام شده
        </td>
        <td>
            عملیات
        </td>
    </tr>
    <tr v-if="!tableContents.length">
        <td colspan="6">
            <p style="color:var(--main-red);text-align:center;width:100%"> سفارشی ثبت نشده است.</p>
        </td>
    </tr>
    <tr class="tr-body" v-for="(tableContent , index) in tableContents" :key="index">
        <td :class="{'buy-td':tableContent.orderType.includes('BUY'), 'sell-td':tableContent.orderType.includes('SELL')}">
            <span v-if="tableContent.orderType.includes('SELL')">
                فروش
            </span>
            <span v-if="tableContent.orderType.includes('BUY')">
                خرید
            </span>
        </td>
        <td class="price">
            {{$toLocal(tableContent.unitPrice,precision)}}
        </td>
        <td class="amount">
            {{$toLocal(tableContent.amount)}}
        </td>
        <td>
            {{$toLocal(tableContent.overallValue,precision)}}
        </td>
        <td>
            {{tableContent.executedPercent.toFixed(1)}}%
        </td>
        <td>
            <span @click="showModal(tableContent)" class="cancel-td">لغو سفارش</span>
        </td>
    </tr>
</table>

</div>
</template>

<script>
import CancelOrder from './CancelOrder.vue'
import {
    tradeFrom,
    tradeTo,
    precision,
    Loop
} from "@/library/reuseableFunction";

export default {
    name: 'ActiveOrders',
    components: {
        CancelOrder
    },
    props: ['base'],
    data() {
        return {
            cancel: false,
            removeid: 0,
            sourceCoin:'',
            amount : '',
            type : "",
            tableContents: [],
            loading: false,
            completedModal: false,
            loop: '',
        }
    },
    computed: {
        precision,
        tradeFrom,
        tradeTo
    },
    methods: {
        closemodal() {
            this.modalshow = false
        },
        showModal(e) {
            this.loop.stop()
            this.sourceCoin = e.sourceCoin
            this.amount = e.amount
            this.removeid = e.id
            this.type = e.orderType
            this.cancel = true
        },
        async removeorder() {
            //   this.state.loading = true
            this.cancel = false
            const [res, ] = await this.$http.delete(`orders/${this.removeid}`)
            if (res) {
                ++this.state.orderChange
                this.modalshow = false
                this.completedModal = true
                this.$error('سفارش با موفقیت حذف شد', '', 'success')
                if (this.state.loop) {
                    this.loop.start()
                } else {
                    this.getActiveOrders()
                }
            }
        },
        async getActiveOrders() {
            const res = await this.$axios.get('/orders?type=P2P', {
                params: {
                    orderStatus: 'IS_OPEN',
                    market: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo,
                }
            })
            this.tableContents = res.content || [];
            this.$emit('update:activeLength', this.tableContents.length)
            this.loading = false
        },
    },
    mounted() {
        this.loading = true
        if (this.state.loop) {
            this.loop = new Loop(this.getActiveOrders, 5000, 'activeOrder')
            this.loop.start()
        } else {
            this.getActiveOrders()
        }
    },
    beforeDestroy() {
        this.loop.stop()
    }
}
</script>

<style scoped>
.table-container {
    width: 100%;
    height: 250px;
    overflow: scroll;
}
table {
    width: 100%;
    text-align: center;
    padding: 0 10px;
}

tr {
    height: 30px;
}

.tr-head {
    font-weight: normal;
    font-size: 12px;
    color: #999999;
}

.tr-body {
    font-weight: normal;
    font-size: 12px;
    color: #404040;
}

.buy-td {
    color: #80CC52;
}

.sell-td {
    color: #D72F70;
}

tr td:first-of-type {
    text-align: right;
}

tr td:last-of-type {
    text-align: left;
}
.cancel-td {
    color: #3D49CC;
    border-bottom: 1px solid #3D49CC;
    cursor: pointer;
}

@media only screen and (max-width:700px) {

    .price,
    .amount,
    .fee {
        display: none;
    }
}
</style>
