<template>
<transition name="modal-fade">
    <div class="modal-backdrop" @click.self="$emit('closeSubModal')">
        <!-- <img class="close-img" src="@/assets/close.png" alt="" @click="closeSub"> -->
        <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
            <svg class="close-svg" width="23" height="22" viewBox="0 0 23 22" fill="black" xmlns="http://www.w3.org/2000/svg" @click="$emit('closeSubModal')">
                <path d="M20.1625 0.393398L11.2132 9.34272L2.26388 0.393398C1.80622 -0.0642598 1.06426 -0.0642597 0.606602 0.393398C0.148943 0.851057 0.148943 1.59302 0.606602 2.05068L9.55592 11L0.606602 19.9493C0.148943 20.407 0.148943 21.1489 0.606602 21.6066C1.06426 22.0643 1.80622 22.0643 2.26388 21.6066L11.2132 12.6573L20.1625 21.6066C20.6202 22.0643 21.3621 22.0643 21.8198 21.6066C22.2775 21.1489 22.2775 20.407 21.8198 19.9493L12.8705 11L21.8198 2.05068C22.2775 1.59302 22.2775 0.851057 21.8198 0.393398C21.3621 -0.0642597 20.6202 -0.0642597 20.1625 0.393398Z" fill="black" />
            </svg>
            <header class="modal-header" id="modalTitle">
                <slot name="header">
                    <p class="header">ثبت نام</p>
                </slot>
            </header>

            <section class="modal-body" id="modalDescription">
                <slot name="body">
                    <form class="form" @submit.prevent="send">
                        <label for="" class="personal-label">
                            <input class="personal-input" type="text" placeholder="آدرس ایمیل" v-model="data.email">
                            <span class="input-title">آدرس ایمیل</span>
                        </label>
                        <label for="" class="personal-label">
                            <input class="personal-input pass-sub" :type="type" placeholder="رمز عبور" v-model="data.password">
                            <span class="input-title">رمز عبور</span>
                            <img class="show-pass" src="@/assets/pass.png" alt="" @click="showPassword">
                        </label>
                        <label for="" class="personal-label">
                            <input class="personal-input" type="text" placeholder="کد دعوت (اختیاری)" v-model="data.referralCode">
                            <span class="input-title">
                                کد دعوت (اختیاری)
                            </span>
                        </label>
                        <p class="policy">
                            با ثبت نام در بامبو شما <router-link to="/policy"> شرایط و قوانین</router-link> استفاده از سرویس‌های سایت را می‌پذیرید.
                        </p>
                        <button class="sub-btn" type="submit">
                            ثبت نام
                        </button>
                    </form>
                    <div class="or-div">
                        <span>
                            یا
                        </span>
                    </div>
                </slot>

            </section>

            <footer class="modal-footer">
                <div class="google-btn" @click="googleSso">
                    <img class="google-img" src="@/assets/google.png" alt="">
                    <span>
                        ادامه با گوگل
                    </span>
                </div>
                <p class="question">حساب کاربری دارید؟<span class="sub_link" @click="state.subscribe=false;state.login=true">وارد شوید</span></p>
            </footer>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    name: 'Subscribe',
    components: {

    },
    data() {
        return {
            type: 'password',
            sub: false,
            data: {
                email: (this.state.email ? this.state.email : ''),
                password: '',
                osType: '',
                deviceType: '',
                browser: '',
                referralCode: ''
            },
            emailError: '',
            passError: '',
        }

    },
    computed: {
        disable() {
            return !this.data.email || !this.data.password || this.emailError.length > 0 || this.passError.length > 0 || !this.agree
        },
        readOnly() {
            return localStorage.referralCode || this.$route.query.ref
        }
    },

    methods: {
        closeSub() {
            this.$emit('closeSubModal', this.sub)
        },
        showPassword(e) {
            if (this.type === 'password') {
                this.type = 'text'
                this.btnText = 'Hide Password'
            } else {
                this.type = 'password'
                this.btnText = 'Show Password'
            }
            e.preventDefault();
        },
        // subBTN() {
        //   this.state.confirmEmail=true
        //   this.$emit('closeSubModal',this.sub)
        // },
        googleSso() {
            window.location.href = this.$google_sso
        },
        async send() {
            await this.checkEmail()
            await this.check2Pass()
            this.state.loading = true
            const res = await this.$axios.post('/users/register', this.data)
            if (res.message === 'user created successfully , verification email sent') {
                if (res.baseDTO.towStepAuthenticationType === 'EMAIL') {
                    this.$cookies.set('username', this.data.email)
                    this.state.userInfo.email = this.data.email
                    this.$emit('closeSubModal')
                    this.state.confirmEmail = true
                    // await this.$router.push('/two-step-email')
                }
            } else if (res.token) {
                this.$setToken(res.token, this.state.userInfo.remember)
                await this.$router.push('/profile')
            }
        },
        async check2Pass() {
            if (!this.$validPass(this.data.password) || this.data.password.length < 8) {
                this.passError = 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد'
                this.$error('اطلاعات وارد شده اشتباه است', 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد')
                return Promise.reject('wrong pass : ' + this.data.password)
            } else {
                this.passError = ''
            }
        },
        async checkEmail() {
            if (!this.$validEmail(this.data.email) || !this.data.email) {
                this.emailError = 'ایمیل صحیح وارد نمایید'
                this.$error('اطلاعات وارد شده اشتباه است', 'ایمیل صحیح وارد نمایید')
                return Promise.reject('repeat failed : ' + this.data.email)
            } else {
                this.emailError = ''
            }
        },

        getData() {
            this.data.osType = this.$detectOs()
            this.data.browser = this.$detectBrowser() || 'CHROME'
            this.data.deviceType = this.$detectDevice()
        },

    },
    mounted() {
        this.getData()
        if (this.$route.query.ref || localStorage.referralCode) {
            localStorage.referralCode = this.$route.query.ref || localStorage.referralCode
            this.data.referralCode = this.$route.query.ref || localStorage.referralCode
        }
    },
}
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width: 400px;
    min-height: 586px;
    max-width: 95%;
    position: relative;
    max-height: 85%;
}

.close-svg {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 111;
}

.modal-header {
    padding: 15px;
    display: flex;
}

.modal-header {
    position: relative;
    /* border-bottom: 1px solid #eeeeee; */
    /* color: #4AAE9B; */
    display: flex;
    justify-content: center;
}

.modal-body,
.modal-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    direction: rtl;
}

input {
    border: 1px solid rgba(61, 73, 204, 0.5);
    box-sizing: border-box;
    border-radius: 3px;
    min-height: 44px;
    margin: 10px 0;
    padding: 0 10px;
    width: 100%;
    outline: none;
    color: #3D49CC;
}

input::placeholder {
    color: rgba(61, 73, 204, 0.5);
}

label {
    position: relative;
    width: 100%;
    height: 60px;
    margin: 10px 0;
}

/* label span {
    position: absolute;
    top: 3px;
    right: 10px;
    background-color: white;
    padding: 0 5px;
    font-weight: normal;
    font-size: 10px;
    color: #3D49CC;

  } */
.pass-sub {
    box-sizing: border-box;
    padding-right: 40px
}

.personal-label span {
    top: 0 !important;
}

.policy {
    margin-top: 30px;
    font-weight: normal;
    font-size: 12px;
}

.question {
    font-weight: normal;
    font-size: 12px;
}

.sub-btn {
    background: #3D49CC;
    border-radius: 3px;
    height: 44px;
    color: white;
    border: none;
}

.or-div {
    border-top: 1px solid rgba(61, 73, 204, 0.1);
    position: relative;
    width: 100%;
    height: 20px;
    margin: 20px 0 0 0;
}

.or-div span {
    position: absolute;
    top: -10px;
    right: 50%;
    background-color: white;
    padding: 0 5px;
    color: #3D49CC;
    font-weight: normal;
    font-size: 12px;
}

.google-btn {
    border: 1px solid #3D49CC;
    box-sizing: border-box;
    border-radius: 3px;
    height: 44px;
    width: 100%;
    direction: rtl;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.google-img {
    position: absolute;
    right: 10px;
}

.show-pass {
    width: 20px;
    height: 15px;
    position: absolute;
    top: 23px;
    right: 10px;
}

.header {
    font-weight: normal;
    font-size: 30px;
    color: #3D49CC;
}

.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity .5s ease;
}

.sub_link {
    color: #3D49CC;
    border-bottom: 1px solid #3D49CC;
    cursor: pointer;
}

input:focus {
    border: 1px solid rgba(61, 73, 204, 1);
}

.pass-input {
    text-align: left;
    padding-right: 50px;
}

a {
    text-decoration: underline !important;
}

.close-img {
    cursor: pointer;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 20px;
}
</style>
