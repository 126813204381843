<template>
<transition name="modal-fade">
    <div class="modal-backdrop" @click.self="onClickOutside">
        <div class="notices">
            <div class="add-notices">
                <p class="add-notice-title">
                    افزودن اطلاع‌رسانی
                </p>
                <hr>
                <div class="currency-container">
                    <div class="currency">
                        <img class="currency-img" :src="'/coins/'+ tradeFrom + '.png'" alt="">
                        <div class="currency-name">
                            <p>
                                {{$coins[tradeFrom].persianName}}
                            </p>
                            <span>
                                -
                            </span>
                            <p>
                                {{$coins[tradeTo].persianName}}
                            </p>
                        </div>
                    </div>
                    <div class="statistics" :class="{'positive-change':change > 0 , 'negative-change' : change < 0}">
                        <p class="price">
                            {{$toLocal(price)}}
                        </p>
                        <p class="change" :class="{'positive-change':change > 0 , 'negative-change' : change < 0}">
                            % {{$toLocal(Math.abs(change),1)}}
                            <span v-if="change > 0"> + </span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <input type="text" placeholder="قیمت (تومان)" v-model="info.amount" @input="info.amount=$toLocal(info.amount,$decimal[tradeTo])">
                <div class="set_range">
                    <div class="asc_container" @click="info.sorting='ASC'" :class="{'asc_container_disable': info.sorting!=='ASC'}">
                        بالاتر از این قیمت
                    </div>
                    <div class="dsc_container" @click="info.sorting='DESC'" :class="{'dsc_container_disable': info.sorting!=='DESC'}">
                        پایین تر از این قیمت
                    </div>
                </div>
                <button class="notice-btn" @click="alarmRequest" :disabled="!info.amount">
                    به من طلاع بده
                </button>
            </div>
            <div class="notices-list">
                <p class="add-notice-title">
                    اطلاع رسانی ها
                </p>
                <hr>
                <div class="table-container">
                    <table>
                        <tr class="tr-heade">
                            <td>
                                بازار
                            </td>
                            <td>
                                قیمت
                            </td>
                            <td>
                                عملیات
                            </td>
                        </tr>
                        <tr class="tr-body" v-for="notices , index in tableContents" :key="index">
                            <td class="coin_alarm">
                                <!-- {{notices.currency}} - {{notices.unit}} -->
                                {{$coins[notices.marketType.split('_')[0]].persianName}}
                                _
                                {{$coins[notices.marketType.split('_')[1]].persianName}}
                            </td>
                            <td :class="{'asc_tr' : notices.sorting=='ASC' , 'dsc_tr' : notices.sorting=='DESC'}">
                                {{$toLocal(notices.amount)}}&nbsp;

                                <img src="@/assets/positive-c.png" alt="" v-if="notices.sorting=='ASC'">
                                <img src="@/assets/negative-c.png" alt="" v-if="notices.sorting=='DESC'">
                            </td>
                            <td class="delete_alarm" @click="deleteAlarm(notices.id)">
                                حذف
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</transition>
</template>

<script>
import {
    tradeTo,
    tradeFrom,
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'Notices',
    components: {

    },
    data() {
        return {
            image: require('../../assets/Coins/btc.png'),
            name: "",
            unit: "",
            price: 5465464654,
            change: 1.5,
            notice: false,
            tableContents: [],
            loop: undefined,
            info: {
                amount: '',
                // createdAtDateTime: '',
                customer: '',
                marketType: this.tradeFrom + '_' + this.tradeTo,
                sorting: 'ASC',
                triggered: ''
            },
        }
    },
    computed: {
        tradeTo,
        tradeFrom,
    },
    methods: {
        async getPrice() {
            const link = this.tradeTo == 'TOMAN' ? '/coins/price/usd' : '/coins/price/usdt'
            let [res, ] = await this.$http.get(link, {
                params: {
                    include24hrChange: true,
                    includeWeeklyChange: false
                }
            })
            this.loading = false
            if (res) {
                this.price = res.find(a => a.destCoinSymbol == this.tradeFrom).price
                this.change = res.find(a => a.destCoinSymbol == this.tradeFrom).price24hrChangePercent
            }
        },
        async getAlarms() {
            this.state.loading = true
            const res = await this.$axios('/alarms')
            if (res) {
                this.tableContents = res.content
            }
        },
        async alarmRequest() {
            this.state.loading = true
            this.info.amount = this.$S2N(this.info.amount)
            this.info.marketType = this.tradeFrom + '_' + this.tradeTo
            await this.$axios.post('/alarms', this.info)
            this.$error('', 'اطلاع رسانی با موفقیت ثبت شد', 'success')
            this.onClickOutside()
            this.state.loading = false
        },
        async deleteAlarm(e) {
            this.state.loading = true
            await this.$axios.delete('/alarms/' + e)
            // if(res) {
            //     this.$error('','هشدار یا موفقیت حذف شد')
            // }
        },
        onClickOutside() {
            this.$emit('onChildNotice', this.notice)
        },
        beforeLoop() {
            this.loading = true
            if (this.state.loop) {
                this.loop = new Loop(this.getPrice, 5000, 'realPrice' + (this.tradeTo == 'TOMAN' ? 'Toman' : 'Tether'))
                this.loop.start()
            } else {
                this.getPrice()
            }
        }
    },
    mounted() {
        this.beforeLoop()
        this.getAlarms()
        this.name = this.tradeFrom
    },
    beforeDestroy() {
        this.loop.stop()
    }
}
</script>

<style lang="scss" scoped>
.notices {
    max-width: 400px;
    position: absolute;
    top: 10px;
    z-index: 10;
    left: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    z-index: 200;
    min-width: 250px;
}

.add-notices {
    min-height: 278px;
}

.add-notices,
.notices-list {
    background: var(--background-notif);
    box-shadow: 10px 0px 15px 5px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(40px);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;

    box-sizing: border-box;
    padding: 20px 20px;
}

.add-notices {
    justify-content: space-between;
}

.notices-list {
    justify-content: flex-start;
    min-height: 400px;
}

.add-notice-title {
    font-weight: normal;
    font-size: 18px;
    color: #3D49CC;
}

hr {
    background: #3D49CC;
    opacity: 0.1;
    width: 100%;
}

.currency-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.currency,
.currency-name {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.currency-name {
    color: var(--font-color);
}

.currency-img {
    width: 40px;
    height: 40px;
}

input {
    width: 100%;
    border: 1px solid rgba(61, 73, 204, 0.5);
    box-sizing: border-box;
    border-radius: 3px;
    outline: none;
    height: 44px;
    color: rgba(61, 73, 204, 1);
    background: transparent;
}

input::placeholder {
    color: rgba(61, 73, 204, 0.5);
}

input:focus {
    border: 1px solid rgba(61, 73, 204, 1);
}

.statistics {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.statistics p {
    margin: 2px;
}

.notice-btn {
    background: #3D49CC;
    border-radius: 3px;
    color: #FFFFFF;
    width: 100%;
    height: 44px;
    border: none;
}

p {
    margin: 0;
}

.price {
    font-size: 16px;
}

.change {
    font-size: 12px;
}

.positive-change {
    color: #80CC52;
}

.negative-change {
    color: #D72F70;
}

table {
    width: 100%;
    text-align: center;
    font-size: 14px;
}

tr {
    height: 30px;
}

.tr-heade {
    color: #999999;
    position: sticky;
    top: 0;
    background: var(--background-child);
}

.tr-body {
    font-size: 12px;

}

.coin_alarm {
    color: var(--font-color);
}

.asc_tr {
    color: #80CC52;
}

.dsc_tr {
    color: #D72F70;
}

.delete_alarm {
    color: rgba(61, 73, 204, 0.6);
    cursor: pointer;
}

.set_range {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    font-size: 14px;
    padding: 2px;
    border-radius: 3px;
    column-gap: 2px;
    margin: 10px 0;

    div {
        display: flex;
        justify-content: center;
        flex-grow: 1;
        min-width: 150px;
        border-radius: 3px;
        height: 100%;
        align-items: center;
        cursor: pointer;
    }

    .asc_container {
        background: #80CC52;
        color: white;
    }

    .asc_container_disable {
        opacity: 0.5;
    }

    .dsc_container {
        background: #D72F70;
        color: white;
    }

    .dsc_container_disable {
        opacity: 0.5;
    }
}

.table-container {
    height: 300px;
    overflow: scroll;
    width: 100%;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.01);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}
</style>
