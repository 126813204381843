<template>
<transition name="modal-fade">
    <div class="modal-backdrop" @click.self="state.walletBtcDe = false">
        <!-- <img class="close-img" src="@/assets/close.png" alt="" @click="closeLogin"> -->
        <div class="modal">
            <svg class="close-svg" width="23" height="22" viewBox="0 0 23 22" fill="black" xmlns="http://www.w3.org/2000/svg" @click="closeLogin">
                <path d="M20.1625 0.393398L11.2132 9.34272L2.26388 0.393398C1.80622 -0.0642598 1.06426 -0.0642597 0.606602 0.393398C0.148943 0.851057 0.148943 1.59302 0.606602 2.05068L9.55592 11L0.606602 19.9493C0.148943 20.407 0.148943 21.1489 0.606602 21.6066C1.06426 22.0643 1.80622 22.0643 2.26388 21.6066L11.2132 12.6573L20.1625 21.6066C20.6202 22.0643 21.3621 22.0643 21.8198 21.6066C22.2775 21.1489 22.2775 20.407 21.8198 19.9493L12.8705 11L21.8198 2.05068C22.2775 1.59302 22.2775 0.851057 21.8198 0.393398C21.3621 -0.0642597 20.6202 -0.0642597 20.1625 0.393398Z" fill="black" />
            </svg>
            <p class="title">
                واریز به کیف پول
                {{$coins[relatedCoin].persianName}}
            </p>
            <p class="describe">
                آدرس کیف پول شما در زیر قابل مشاهده است. برای واریز {{$coins[relatedCoin].persianName}} به این کیف، می‌توانید از این آدرس استفاده کنید.
            </p>
            <template v-if="selected_net && enable">
                <img class="qrcode" :src="'data:image/png;base64,'+qrCode" alt="">
                <p v-if="['STELLAR','RIPPLE'].includes(relatedCoin)"> تگ یا ممو : {{tag}}</p>
                <input v-if="$etcChannelCoin.includes(relatedCoin)" v-model="txid" class="hash" placeholder="TxID : شناسه تراکنش">
                <label for="" class="alertLink-conyainer">
                    <input class="personal-input" type="text" :value=address disabled>
                    <span class="alertLink" v-if="alertLink">
                        کپی شد
                    </span>
                </label>
                <!-- <div class="warning">
                    <img src="@/assets/warning.svg" alt="">
                    <p>
                        درخواست‌های تسویه بالای ۰۰۱ میلیون تومان به دلیل محدودیت‌های بانکی حداکثر تا یک روز آینده کاری از زمان ثبت درخواست انجام خواهد شد .
                    </p>
                </div> -->
                <button class="toPay" @click="Copy">
                    کپی آدرس
                </button>
            </template>
            <template>
                <label for="" class="selectd-net-lable">
                    <div class="selected-net" @click="drop = !drop">
                        <span v-if="selected_net" class="selected-net" :disabled="selected_net">شبکه : {{selected_net}}</span>
                        <span v-else>انتخاب شبکه</span>
                    </div>
                    <div class="dropdown" v-if="drop && !selected_net">
                        <div v-for="(net , index) in nets" :key="index" @click="selectFun(index)">
                            {{net.tokenType}}
                        </div>
                    </div>
                </label>
            </template>
            <p class="error-p" v-if="!enable && !!selected_net">
                در حال حاضر واریز به شبکه مورد نظر مقدور نیست
            </p>
            <!-- <input type="text" class="address-input" v-if="txIdIsNeeded" v-model="txid" placeholder="شناسه تراکنش"> -->
            <label for="" class="personal-label">
                <input type="text" class="personal-input" v-if="txIdIsNeeded" v-model="txid" placeholder="شناسه تراکنش">
                <span class="input-title">شناسه تراکنش</span>
            </label>
            <button class="confirm" @click.prevent="checkWallet" :disabled="(txIdIsNeeded && !txid && !enable) || selected_net==''">
                <!-- :disabled="(txIdIsNeeded && !txid && !enable) || selected_net==''"" -->
                بررسی واریز
            </button>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    name: 'WalletBD',
    components: {

    },
    props: {
        relatedCoin: {
            default: ''
        },
        exchange: {
            default: false
        },
        nets: {
            default: {}
        }
    },
    data() {
        return {
            amount: '',
            address: '',
            qrCode: '',
            tag: '',
            txid: '',
            token: false,
            alertLink: false,
            drop: false,
            selected_net: "",
            txIdIsNeeded: false,
            enable: false
        }
    },

    methods: {
        closeLogin() {
            this.state.walletBtcDe = false
        },
        changeNetwork(e) {
            this.token = e
            this.getQR()
        },
        Copy() {
            navigator.clipboard.writeText(this.address);
            this.alertLink = true
            setTimeout(() => {
                this.alertLink = false;
            }, 1500);
        },
        async checkWallet() {
            this.state.loading = true
            // const token = this.token ? 'ERC20' : 'TRC20'
            let query = {
                relatedCoin: this.relatedCoin,
                tokenType: this.selected_net,
                walletType: (this.exchange ? 'EXCHANGE' : 'P2P')
            }
            if (this.txIdIsNeeded) {
                query.txid = this.txid
            }
            const res = await this.$axios.get('/wallets/check-confirmed-transaction', {
                params: query
            })
            let text
            if (res.baseDTO.amount) {
                text = `مقدار ${this.$toLocal(res.baseDTO.amount) || 0} ${this.$coinLabel[this.relatedCoin]} واریز شده و تایید ${res.baseDTO.transactionIsDone ? 'شده' : 'نشده'}`
            } else {
                text = 'مقدار واریزی شناسایی نشد'
            }
            this.$error('', text, res.baseDTO.transactionIsDone ? 'success' : 'info')
        },
        selectFun(index) {
            this.selected_net = this.nets[index].tokenType
            this.txIdIsNeeded = this.nets[index].txIdIsNeeded
            this.enable = this.nets[index].isDepositEnable || true
            this.drop = false
            if (this.enable) {
                this.getQR()
            }

        },
        async getQR() {
            this.state.loading = true
            const token = this.selected_net
            console.log(token)
            let params = {
                relatedCoin: this.relatedCoin
            }
            if (this.relatedCoin === 'TETHER') {
                params.tokenType = token
            }
            const res = await this.$axios('/wallets/customer/wallet-address?walletType=' + (this.exchange ? 'EXCHANGE' : 'P2P') + '&tokenType=' + token, {
                params
            }).catch(() => {
                this.state.walletBtcDe = false
            })
            if (res) {
                this.address = res.baseDTO.address
                this.qrCode = res.baseDTO.qrCode
                this.tag = res.baseDTO.tag
            }
        }
    },
    mounted() {
        // alert(this.relatedCoin)
        // this.getQR()
    },
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(93, 109, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    background: #FFFFFF;
    border-radius: 3px;
    max-width: 400px;
    width: 90vw;
    min-height: 332px;
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    position: relative;
    row-gap: 15px;
}

.close-svg {
    position: absolute;
    right: 10px;
    z-index: 111;
}

.title {
    font-size: 22px;
    color: #3D49CC;
}

.describe {
    font-size: 14px;
    margin: 0;
}

.toPay {
    background: #3D49CC;
    border-radius: 3px;
    border: none;
    color: #FFFFFF;
    font-size: 14px;
    width: 100%;
    height: 44px;
}

.qrcode {
    width: 150px;
    height: 150px;
    align-self: center;
}

.address-input {
    border: 1px solid #3D49CC;
    box-sizing: border-box;
    border-radius: 3px;
    height: 44px;
}

.warning {
    font-size: 12px;
    color: #EB5757;
    text-align: right;
    display: flex;
    align-items: flex-start;
    column-gap: 5px;

    p {
        margin: 0;
    }
}

.selectd-net-lable {
    position: relative;
    border: 1px solid #525CCC;
    box-sizing: border-box;
    border-radius: 3px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    font-size: 18px;
    cursor: pointer;
    color: #3D49CC;
        .selected-net {
            font-size: 14px;
        }
}

.dropdown {
    background: #FFFFFF;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    font-size: 14px;
    color: #404040;
    text-align: right;
    padding: 10px;
    position: absolute;
    top: 44px;
    right: 0;
    left: 0;
    z-index: 2;

    div {
        height: 30px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}

.confirm {
    background: #80CC52;
    border-radius: 3px;
    width: 100%;
    height: 44px;
}

.selected-net {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.alertLink-conyainer {
    position: relative;
}
.alertLink {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 12px;
    background: #70B347;
    border-radius: 3px;
    color: white;
    padding: 0 10px;
}
</style>
