<template>
<div class='advanced'>
    <table>
        <tr class="tr-head">
            <td :class="{
                    'priceTd':title.heade==='قیمت',
                    'amountTd':title.heade==='مقدار',
                    'totalTd':title.heade==='مجموع',
                    'netTd':title.heade==='شبکه ارسال',
                    'statuseTd':title.heade==='وضعیت',
                    'dateTd':title.heade==='زمان',
                }" v-for="title , index in title" :key="index">
                {{title.heade}}
            </td>
        </tr>
        <tr class="tr-body" v-for="tableContent , index in tableContents " :key="index">
            <td>
                {{$coins[tableContent.sourceCoin].persianName}}
            </td>
            <td :class="{'buy':tableContent.orderType.includes('BUY'), 'sell':tableContent.orderType.includes('SELL')}">
                <span v-if="tableContent.orderType==('EXCHANGE_SELL' || 'EXCHANGE_SELL')">
                    فروش
                </span>
                <span v-if="tableContent.orderType==('EXCHANGE_BUY' || 'EXCHANGE_BUY')">
                    خرید
                </span>
            </td>
            <td class="priceTd">
                {{$toLocal(tableContent.unitPrice,precision)}}
            </td>
            <td class="amountTd">
                {{$toLocal(tableContent.amount)}}
            </td>
            <td class="totalTd">
                {{$toLocal(tableContent.wholePrice,0)}}
            </td>
            <!-- <td class="netTd">
                {{body.net}}
            </td> -->
            <!-- <td class="statuseTd" :class="{'buy':body.statuse==='انجام شده' , 'sell':body.statuse==='لغو شده'}">
                {{body.statuse}}
            </td> -->
            <td>
                {{tableContent.id}}
            </td>
            <td class="dateTd">
                {{$G2J(tableContent.createdAtDateTime) }}
            </td>
        </tr>
    </table>
</div>
</template>

<script>
export default {
    name: 'Exchange',
    components: {

    },
    props: ['type'],
    data() {
        return {
            tableContents: [],
            lastPage: 1,
            page: 0,
            title: [{
                    heade: "ارز"
                },
                {
                    heade: "عملیات"
                },
                {
                    heade: "قیمت"
                },
                {
                    heade: "مقدار"
                },
                {
                    heade: "مجموع"
                },
                // {
                //     heade: "شبکه ارسال"
                // },
                // {
                //     heade: "وضعیت"
                // },
                {
                    heade: "شناسه تراکنش"
                },
                {
                    heade: "زمان"
                }
            ],
        }
    },
    methods: {
        async getActiveOrders() {
            this.state.loading = true
            const res = await this.$axios.get('/trades?type=EXCHANGE', {
                params: {
                    //   orderStatus: 'IS_OPEN',
                }
            })
            this.tableContents = res.content;
            // this.$emit('update:activeLength', this.tableContents.length)
            this.state.loading = false
        },
    },
    mounted() {
        this.getActiveOrders()
    }
}
</script>

<style lang="scss" scoped>
table {
    width: 100%;
    text-align: center;
}

.tr-head {
    font-size: 12px;
    color: #999999;
    height: 40px;
}

.tr-body {
    font-size: 12px;
    color: #404040;
    height: 30px;
}

.buy {
    color: #80CC52;
}

.sell {
    color: #D72F70;
}

.observe {
    color: #3D49CC;
    border-bottom: 1px solid #3D49CC;
}

@media only screen and (max-width:900px) {

    .priceTd,
    .amountTd,
    .totalTd,
    .netTd,
    .statuseTd {
        display: none;
    }
}
</style>
