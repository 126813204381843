<template>
<div id="slider">
    <span class="slider-back" />
    <span v-for="n in [0,0.25,0.5,0.75,1]" :key="n" @click.prevent="value = n;putcash(value);" class="dot" :style="{
                     left: n*100+'%',
                     transform:'translate('+-1*n*100+'%, -50%)',
                }" />
    <input @change="putcash(value);" type="range" class="slider" :id="'one'+id" min="0" max="1" step="0.01" v-model="value">
    <label class="rangenumber" :style="{
            left: 'min(100%,'+value*100 + '%)',
            transform:'translate(max(-100%,'+-1*value*100+'%), -50%)',
        }" :for="'one'+id">
        <a>{{(value*100).toFixed(0)}}%</a>
    </label>
</div>
</template>

<script>
export default {
    name: "TheSlider",
    props: ['id', 'percent'],
    emits: ['change'],
    data() {
        return {
            decimal: 8,
            value: this.percent || 0
        }
    },
    watch: {
        percent(val) {
            this.value = val
        }
    },
    mounted() {

    },
    methods: {
        putcash() {
            this.$emit('change', this.value)
        },
    }

}
</script>

<style lang="scss" scoped>
#slider {
    margin: 20px 0;
    // margin-bottom: 6px;
    display: flex;
    position: relative;
}

.slider-back {
    height: 2px;
    width: 99%;
    background-color: #d8d8d8;
    border-radius: 5px;
    display: inline-block;
    position: absolute;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);

    top: 50%;
}

.dot {
    height: 7px;
    width: 7px;
    background-color: #d8d8d8;
    border-radius: 50%;
    // border: solid 1px white;
    display: inline-block;
    position: absolute;
    cursor: pointer;
    top: 1px;
}

.dot:hover {
    background-color: #a7a7a7;
}

.dot1 {
    left: 0;
}

.dot2 {
    left: 25%;
    transform: translateX(-24%);
}

.dot3 {
    left: 50%;
    transform: translateX(-50%);
}

.dot4 {
    left: 75%;
    transform: translateX(-74%);
}

.dot5 {
    left: 100%;
    transform: translateX(-100%);
}

.percent {
    font-size: 10px;
    position: absolute;
    top: 9px;
    cursor: pointer;
}

.percent:hover+.dot {
    background-color: #a7a7a7;
}

.percent:first-of-type {
    left: 0;
}

.percent:nth-of-type(2) {
    left: 25%;
    transform: translateX(-30%);
}

.percent:nth-of-type(3) {
    left: 50%;
    transform: translateX(-50%);
}

.percent:nth-of-type(4) {
    left: 75%;
    transform: translateX(-65%);
}

.percent:nth-of-type(5) {
    left: 100%;
    transform: translateX(-90%);
}

.slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 0;
    // background: none !important;
     background: #EDEFF2 !important;
    outline: none;
    border: none !important;
    -webkit-transition: .2s;
    transition: 0.2s;
    padding: 0 !important;
    margin: 0 !important;
    z-index: 1;
    cursor: pointer;
    direction: ltr;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    /*background: var(--main-orange);*/
   background: #3D49CC;
    /*border: solid 1px var(--main-orange);*/
    border: none;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
}

.slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    /*background: var(--main-orange);*/
    background: #3D49CC;
    /*border: solid 1px var(--main-orange);*/
    border: none;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
}

/*.slider::-moz-range-thumb:active,*/
/*.slider::-moz-range-thumb:hover {*/
/*    transform: scale(2);*/
/*    !*background-color: #fff;*!*/
/*}*/

.rangenumber {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    transform: translateY(-50%);
    background: transparent;
    // border: solid 1px rgba(244, 132, 0, 1);
    font-size: 10px;
    /*display: block;*/
    position: absolute;
    color: white;
    top: -17px;
    // padding: 3px;
    /*transition: 0.2s;*/
    /*z-index: 1;*/
    transition: height 0.2s;

    & a {
        display: none;
        border: solid 1px #3D49CC;
        border-radius: 50%;
        color: #404040;
    }
}

#slider:hover .rangenumber {
    width: 22px;
    height: 22px;

    & a {
        display: block;

    }
}

.slider::-webkit-slider-thumb:hover,
.slider::-webkit-slider-thumb:active {
    width: 15px;
    height: 15px;
}

.slider::-moz-range-thumb:hover,
.slider::-moz-range-thumb:hover {
    width: 15px;
    height: 15px;
}

/*.slider::-moz-range-thumb:active #percent{*/
/*    display: flex !important;*/
/*}*/
</style>
