<template>
    <transition name="modal-fade" >
        <div class="modal-backdrop" >
            <div class="authen">
                <img class="success-img" src="@/assets/success.png" alt="">
                <p class="title">
                    اطلاعات شما با موفقیت ثبت شد.
                </p>
                <p class="describe">
                    حساب کاربری شما پس از بررسی اطلاعات توسط تیم بامبو فعال خواهد شد
                </p>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'AuthenModal',
  components :{

  },
  data() {
      return {
          
      }
  },
    
  methods : {
      
  },
  mounted: {
      close() {
          
      }
  }
  }
</script>

<style scoped>
    .authen {
        max-width: 400px;
        height: 300px;
        background: #FFFFFF;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 10px;
        max-width: 95%;
    }
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(93, 109, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
    .title {
        font-size: 22px;
        color: #3D49CC;
        text-align: center;
    }
    .describe {
        font-size: 14px;
        text-align: center;
    }
    .success-img {
        display: flex;
        align-self: center;
        margin: auto;
    }
</style>
