<template>
    <transition name="modal-fade" >
        <div class="modal-backdrop" @click.self="$emit('close')">
            <div class="modal" >
                <svg class="close-svg" width="23" height="22" viewBox="0 0 23 22" fill="black" xmlns="http://www.w3.org/2000/svg" @click="$emit('close')" >
                    <path d="M20.1625 0.393398L11.2132 9.34272L2.26388 0.393398C1.80622 -0.0642598 1.06426 -0.0642597 0.606602 0.393398C0.148943 0.851057 0.148943 1.59302 0.606602 2.05068L9.55592 11L0.606602 19.9493C0.148943 20.407 0.148943 21.1489 0.606602 21.6066C1.06426 22.0643 1.80622 22.0643 2.26388 21.6066L11.2132 12.6573L20.1625 21.6066C20.6202 22.0643 21.3621 22.0643 21.8198 21.6066C22.2775 21.1489 22.2775 20.407 21.8198 19.9493L12.8705 11L21.8198 2.05068C22.2775 1.59302 22.2775 0.851057 21.8198 0.393398C21.3621 -0.0642597 20.6202 -0.0642597 20.1625 0.393398Z" fill="black"/>
                </svg>
                <p class="title">
                    درخواست شما با موفقیت انجام شد 
                </p>
                <hr>
                <div class="list-container">
                    <div class="list">
                        <span>
                            نوع درخواست
                        </span>
                        <span class="buy-status" v-if="data.orderType.includes('BUY')">
                            خرید
                        </span>
                        <span class="sell-status" v-else>
                            فروش
                        </span>
                    </div>
                    <div class="list">
                        <span>
                            مقدار  
                        </span>
                        <span>
                            {{data.amount}} {{$coins[data.destinationCoin].code}}
                        </span>
                    </div>
                    <div class="list">
                        <span>
                            قیمت واحد
                        </span>
                        <span>
                            {{$toLocal(data.unitPrice,0)}} تومان
                        </span>
                    </div>
                    <div class="list">
                        <span>
                            مجموع
                        </span>
                        <span>
                            {{$toLocal(data.totalPrice,0)}} تومان
                        </span>
                    </div>
                </div>
                <button class="confirm" @click="$emit('close')">
                    تایید
                </button>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'SuccessBuy',
  components :{

  },
  props:['data'],
  data() {
      return {
          
      }
  },
    
  methods : {
      
  },

  }
</script>

<style scoped>

    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(93, 109, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
  .modal {
    background: #FFFFFF;
    border-radius: 3px;
    max-width: 400px;
    width: 90vw;
    min-height: 332px;
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .confirm {
      width: 100%;
      height: 44px;
      color: white;
      background-color: rgba(61, 73, 204, 1);
      border: none;
      border-radius: 3px;
  }
  .list-container {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
  }
  .list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
  }
  hr {
      width: 100%;
      border: none;
      height: 1px;
      background: rgba(61, 73, 204, 0.1);
  }
  .title {
      font-size: 18px;
      margin: 0;
  }
  .buy-status {
      color: rgba(128, 204, 82, 1);
  }
  .sell-status {
      color: #D72F70;
  }
</style>
