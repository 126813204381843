<template>
<div class="information">
    <Login v-if="login" v-on:closeLoginModal="closeLogin" />
    <Subscribe v-if="subscribe" v-on:closeSubModal="closeSub" />
    <Navbar v-on:childToLogin="LoginShow" v-on:childToSub="SubShow" />
    <div class="direction">
        <span class="direction-home">
            خانه
        </span>
        <span>
            <img class="directionimg" src="@/assets/guid-v.svg" alt="">
        </span>
        <span class="direction-current">
            <span v-if="$route.path=='/faq'">
                سوالات متداول
            </span>
            <span v-if="$route.path=='/how-to-use'">
                راهنمای استفاده
            </span>
            <span v-if="$route.path=='/about'">
                درباره ما
            </span>
            <span v-if="$route.path=='/fees'">
                کارمزد ها
            </span>
            <span v-if="$route.path=='/policy'">
                قوانین و شرایط
            </span>
        </span>
    </div>
    <div class="information-container">
        <FAQ v-if="$route.path=='/faq'" />
        <How v-if="$route.path=='/how-to-use'" />
        <About v-if="$route.path=='/about'" />
        <Fees v-if="$route.path=='/fees'" />
        <Policy v-if="$route.path=='/policy'" />
    </div>
    <Footer />
</div>
</template>

<script>
import Navbar from '../HomePage/Navbar.vue'
import FAQ from "./FAQ.vue"
import Footer from '../HomePage/Footer.vue'
import How from './How.vue'
import About from "./About.vue"
import Fees from './Fees.vue'
import Login from '../Authentication/Login.vue'
import Subscribe from '../Authentication/Subscribe.vue'
import Policy from '../Informations/Policy.vue'
export default {
    name: 'Information',
    components: {
        Navbar,
        FAQ,
        Footer,
        How,
        About,
        Fees,
        Login,
        Subscribe,
        Policy
    },
    data() {
        return {
            login: false,
            subscribe: false,
        }
    },
    methods: {
        LoginShow(value) {
            this.login = value
            console.log(this.login)
        },
        closeLogin(value) {
            this.login = value

        },
        SubShow(value) {
            this.subscribe = value
            console.log(this.subscribe)
        },
        closeSub(value) {
            this.subscribe = value
        }
    }
}
</script>

<style scoped>
.information {
    background-color: #3D49CC;
    width: 100%;
    height: 100%;
}

.information-container {
    width: 100%;
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-sizing: border-box;
    padding-bottom: 50px;
    min-height: 80vh;
}

.direction {
    height: 60px;
    width: 80%;
    margin: 50px auto 0 auto;
    display: flex;
    align-items: center;
    column-gap: 30px;
}

.directionimg {
    transform: rotate(90deg);
}

.direction-home {
    font-weight: normal;
    font-size: 12px;
    color: rgba(242, 242, 242, 0.2);
}

.direction-current {
    font-weight: normal;
    font-size: 12px;
    color: #F2F2F2;
}
</style>
