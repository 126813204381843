<template>
<div class='advanced'>
    <table>
        <tr class="tr-head">
            <td v-for="title , index in title" :key="index">
                {{title.heade}}
            </td>
        </tr>
        <tr class="tr-body" v-for="(deposit , index) in depositList" :key="index">
            <td class="">
               {{$coins[deposit.relatedCoin].persianName}}
            </td>
            <td class="">
                <span v-if="deposit.amount">{{$toLocal(deposit.amount)}}</span>
                <span v-else> - </span>
            </td>
            <td class="">
                <span class="" v-if="deposit.bankAccount">{{deposit.bankAccount}}</span>
                <span class="" v-if="deposit.tokenType">{{deposit.tokenType}}</span>
            </td>
            <td>
                {{statusLabel(deposit.depositRequestStatus)}}
            </td>
            <td class="">
                {{deposit.hashCode}}
            </td>
            <td class="">
                 {{$G2J(deposit.createdAtDateTime)}}
            </td>
        </tr>
    </table>
</div>
</template>

<script>
import {
    statusLabel,

} from "@/library/reuseableFunction";
export default {
    name: 'Deposits',
    components: {
    },
    data() {
        return {
            withdraList: [],
            depositList: [],
            loading: false,
            setting: {
                suppressScrollX: false,
                swipeEasing: false,
                wheelPropagation: false,
            },
            label: {
                with: 'برداشت',
                dep: 'واریز'
            },
            lastPage: 1,
            page: 0,
            page2: 0,
            lastPage2: 1,
            modalshow: false,
            completedModal: false,
            removeid: '',
            title: [{
                    heade: "ارز"
                },
                {
                    heade: "مقدار"
                },
                {
                    heade: "شبکه ارسال"
                },
                {
                    heade: "وضعیت"
                },
                {
                    heade: "شناسه تراکنش"
                },
                {
                    heade: "زمان"
                }
            ]
        }
    },
    computed: {

        statusLabel
    },
    methods: {
        async getDepositHistory() {
            this.state.loading = true
            const address = 'wallets/deposit'
            if (this.page2 <= this.lastPage2) { //for scroll loading
                this.page2++ //for scroll loading
                this.loading = true
                const [res, ] = await this.$http.get(address, {
                    params: {
                        size: 10,
                        page2: this.page2
                    }
                })
                if (res) {
                    this.depositList = [...this.depositList, ...res.content]
                    this.lastPage = res.totalPages
                }

            }
             this.state.loading = false
        },

    },
    mounted() {
        this.getDepositHistory()
    }
}
</script>

<style lang="scss" scoped>
table {
    width: 100%;
}

.tr-head {
    font-size: 12px;
    color: #999999;
}

.tr-body {
    font-size: 12px;
    color: #404040;
}

.buy {
    color: #80CC52;
}

.sell {
    color: #D72F70;
}
</style>
