<template>
<div class="title" :class="{'dark-child':state.dark , 'light-child':state.light , 'neon-child':state.neon}">
    <div class="name">
        <img class="coin-img" :src="'/coins/'+ tradeFrom + '.png'" :alt="tradeFrom">
        <p>
            {{$coinLabel[tradeFrom]}}
        </p>
        <span>
            -
        </span>
        <p>
            {{$coinLabel[tradeTo]}}
        </p>
    </div>
    <p class="price" :class="{'positive-change':data.last24HourChange > 0 , 'negative-change':data.last24HourChange< 0}">
       {{$toLocal(data.lastPrice,precision)}}
    </p>
    <div>
        <p class="subTitle">
            درصد تغییرات
        </p>
        <p class="subTitle-number" :class="{'positive-change':data.last24HourChange > 0 , 'negative-change':data.last24HourChange< 0}">
           {{Math.abs(data.last24HourChange).toFixed(1)}}%
                <span v-if="data.last24HourChange > 0">+</span>
                <span v-if="data.last24HourChange < 0">-</span>
        </p>
    </div>
    <div>
        <p class="subTitle">
            بیشترین
        </p>
        <p class="subTitle-number">
             {{$toLocal(data.maxPrice,precision)}}
        </p>
    </div>
    <div>
        <p class="subTitle">
            کمترین
        </p>
        <p class="subTitle-number">
            {{$toLocal(data.minPrice,precision)}}
        </p>
    </div>
    <div>
        <p class="subTitle">
            حجم
        </p>
        <p class="subTitle-number">
             {{(data.last24HourVolume ? $toLocal(data.last24HourVolume.precision) : 0)}}
        </p>
    </div>
</div>
</template>

<script>
import {
    Loop,
    tradeTo,
    tradeFrom,
    precision
} from "@/library/reuseableFunction";
export default {
    name: 'TradeTitle',
    components: {

    },
    data() {
        return {
            name: "بیت کوین",
            unit: "تومان",
            price: 535454654654,
            change: 0.55,
            max: 21321321,
            min: 3153545454,
            amount: 54897987987,
            theme: this.state.tradingTheme,
            loading: false,
            loop: '',
            data: {
                maxPrice: 0,
                minPrice: 0,
                lastPrice: 0,
                last24HourVolume: 0,
                last24HourChange: 0,
            },

        }
    },
    computed: {
        tradeFrom,
        tradeTo,
        precision
    },
    methods: {
        async getTradeHeader() {
            const [res, ] = await this.$http.get('/trades/market-info', {
                params: {
                    weeklyDataIncluded: false,
                    market_type: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo
                }
            })
            this.loading = false
            if (res) {
                this.data = res.content[0] || this.data
                this.state.shopInfo.lastPrice = this.data.lastPrice
                this.state.shopInfo.change24 = this.data.last24HourChange
                document.title = `بازار ${this.$coinLabel[this.tradeFrom]} - ${this.$coinLabel[this.tradeTo]}` + ' ｜ ' + this.$toLocal(this.data.lastPrice, this.precision)
            }
        },
    },
    mounted() {
        this.loading = true
        document.title = `بازار ${this.$coinLabel[this.tradeFrom]} - ${this.$coinLabel[this.tradeTo]}`
        if (this.state.loop) {
            this.loop = new Loop(this.getTradeHeader, this.state.time, 'tradeHeader:' + this.tradeFrom + '-' + this.tradeTo)
            this.loop.start()
        } else {
            this.getTradeHeader()
        }
    },
    beforeDestroy() {
        this.loop.stop()
    },
}
</script>

<style scoped>
.title,
.chart {
    /* background: #FFFFFF; */
    border-radius: 3px;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    box-sizing: border-box;
    padding: 0 15px;
    row-gap: 20px;
    column-gap: 20px;
    flex-wrap: wrap;
}

.subTitle {
    font-weight: normal;
    font-size: 10px;
    color: #999999;
}

.name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 5px;
}

.name p,
.price {
    font-size: clamp(12px, 1vw, 16px);
}

.title p {
    margin: 0;
}
.coin-img {
    width: 20px;

}
.subTitle-number {
    font-weight: normal;
    font-size: 12px;
}

@media only screen and (max-width:1000px) {
    .title {
        flex-wrap: wrap;
    }
}
</style>
