<template>
<div class="buy">
    <div class="heade">
        <div class="name-container">
            <img class="btc-img" :src="'/coins/'+ destCoinSymbol + '.png'" alt="">
            <span class="name">
                {{$coins[destCoinSymbol].persianName}}
            </span>
        </div>
        <span class="price-buy" v-if="state.BuyModal">
            تومان {{$toLocal(data.unitPrice,0)}}
        </span>
    </div>
    <div class="balance" v-if="state.token">
        <span class="balance-number">
            موجودی: <span> {{$toLocal(balance.credit,0)}} </span> تومان
        </span>
        <span class="balance-increase">
            افزایش موجودی
        </span>
    </div>
    <div class="buy-inputs">
        <label for="" class="personal-label">
            <input class="personal-input" type="text" :placeholder=" 'مقدار ' +  $coins[destCoinSymbol].persianName" @input="inputsFun('amount')" v-model="data.amount">
            <!-- "مقدار بیت کوین" -->
            <span class="input-title">
                مقدار بیت کوین
            </span>
        </label>
        <div class="border-bottom">
            <span class="or">
                یا
            </span>
        </div>
        <label for="" class="personal-label">
            <input class="personal-input" type="text" placeholder="مبلغ به تومان" @input="inputsFun('totalPrice')" v-model="data.totalPrice">
            <span class="input-title">
                مبلغ به تومان
            </span>
        </label>
    </div>
    <hr>
    <div class="buttons">
        <button :class="{'active':state.BuyBamboo}" @click="buyBamboo">
            انتقال به کیف پول بامبو
        </button>
        <button :class="{'active':state.BuyOther}" @click="buyOther">
            انتقال به کیف پول دیگر
        </button>
    </div>
    <div v-if="state.BuyOther">
        <label for="" class="personal-label">
            <input class="personal-input" type="text" placeholder="آدرس کیف پول" v-model="data.destinationWallet">
            <span class="input-title">
                آدرس کیف پول
            </span>
        </label>
    </div>
    <div  class="selectd-net-lable" v-if="state.BuyOther">
        <div class="selectd-net" @click="netDrop = !netDrop">
            <span v-if="selected_net">{{selected_net}}</span>
            <span v-else>انتخاب شبکه</span>
        </div>
        <div class="netDropdown" v-if="netDrop">
            <div v-for="(net , index) in nets" :key="index" @click="selectFun(index);buyReadOnly()">
                {{net.tokenType}}
            </div>
        </div>
    </div>
    <hr>
    <button class="buyButton" v-if="state.BuyModal && state.token" :disabled="!!buyReadOnly" @click="buy">
        <span v-if="waiting">
            لطفا منتظر بمانید ...
        </span>
        <span v-else-if="!!buyReadOnly">
            {{buyReadOnly}}
        </span>
        <span v-else>
            خرید
        </span>
    </button>
    <button v-else class="login-btn" @click="state.BuyModal=false;state.login = true">
        ورود
    </button>
    <!-- <hr> -->
</div>
</template>

<script>
import {
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'BuyModal',
    components: {

    },
    props: ['destCoinSymbol', 'price'],
    data() {
        return {
            coin: [],
            nets: [],
            waiting: false,
            loop: undefined,
            netDrop: false,
            selected_net: '',
            minAmountExchange: null,
            maxAmountExchange: null,
            balance: {},
            temptData: {
                amount: null,
                totalPrice: null,
                destinationCoin: null,
                unitPrice: null,
                destinationWallet: '',
                tokenType: null,
            },
            data: {
                marketType: this.destCoinSymbol + '_TOMAN',
                amount: null,
                sourceCoin: "TOMAN",
                destinationCoin: this.destCoinSymbol,
                orderType: "EXCHANGE_BUY",
                unitPrice: this.price,
                totalPrice: null,
                destinationWallet: '',
                tokenType: null,
            },
        }
    },
    computed: {
        buyReadOnly() {
            let error = ''
            if (!this.$A2N(this.data.amount) || !this.$A2N(this.data.totalPrice)) {
                error = 'مقدار یا قیمت ارز اجباری است'
            } else if (this.$A2N(this.data.amount) < this.$S2N(this.minAmountExchange)) {
                error = 'حداقل مقدار رعایت نشده'
            } else if (this.$S2N(this.data.totalPrice) > this.$A2N(this.balance.credit)) {
                error = 'مقدار بیش از موجودی شماست'
            } else if (this.$A2N(this.data.amount) > this.$S2N(this.maxAmountExchange)) {
                error = 'حداکثر مقدار رعایت نشده'
            }
            return error

        },
    },
    methods: {
        async buy() {
            this.state.loading = true
            this.temptData = JSON.parse(JSON.stringify(this.data))
            this.temptData.amount = this.$S2N(this.data.amount)
            this.temptData.totalPrice = this.$S2N(this.data.totalPrice)
            this.temptData.destinationCoin = this.data.destinationCoin
            this.temptData.unitPrice = this.$S2N(this.data.unitPrice)
            this.temptData.destinationWallet = this.data.destinationWallet
            this.temptData.tokenType = this.selected_net
            if (!this.state.BuyOther) {
                delete this.temptData.destinationWallet
                delete this.temptData.tokenType

                this.state.loading = true
                await this.$axios.post(
                    '/exchange/buy', this.temptData
                )
                // this.success = true
                this.$emit('openSuccess', this.temptData)

            } else {

                this.state.loading = true
                await this.$axios.post(
                    '/exchange/buy', this.temptData
                )
                // this.success = true

                this.$emit('openSuccess', this.temptData)

            }
            this.state.BuyModal = false
            this.state.loading = false

        },
        buyBamboo() {
            this.state.BuyBamboo = true
            this.state.BuyOther = false
            this.state.BuyFirst = false
        },

        buyOther() {
            this.state.BuyBamboo = false
            this.state.BuyOther = true
            this.state.BuyFirst = false
        },
        async tomanTetherWallet() {
            if (this.state.token) {
                this.loading = true
                const [res2, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE&coins=' + 'TOMAN')
                if (res2) {
                    this.balance = res2['TOMAN']
                }
                this.loading = false
            }
        },

        async getPrice() {

            const res = await this.$axios.get('coins/exchange-price/' + 'usd' + '?includeWeeklyChange=false&destCoinSymbol=' + this.destCoinSymbol)
            this.data.unitPrice = res.find(a => a.destCoinSymbol == this.destCoinSymbol).buyPrice
            // this.data.totalPrice = this.$toLocal(this.$S2N(this.data.amount) * this.$S2N(this.data.unitPrice), this.$decimal['TOMAN'])
        },

        async inputsFun(e) {
            this.waiting = true
            await this.getPrice()
            if (e === 'amount') {
                this.data.amount = this.$toLocal(this.data.amount, this.$decimal[this.destCoinSymbol])
                this.data.totalPrice = this.$toLocal(this.$S2N(this.data.amount) * this.$S2N(this.data.unitPrice), this.$decimal['TOMAN'])

            } else {
                console.log(this.data.totalPrice);
                this.data.totalPrice = this.$toLocal(this.data.totalPrice, this.$decimal['TOMAN'])
                this.data.amount = this.$toLocal(this.$S2N(this.data.totalPrice) / this.$S2N(this.data.unitPrice), this.$decimal[this.destCoinSymbol])
            }
            this.waiting = false
        },
        async getCoin() {
            this.state.loading = true
            const res = await this.$axios.get('/coins/details')
            this.coin = res.content
            this.nets = this.coin.find((a) => a.coin === this.destCoinSymbol).tokenTypeDetails
            // this.getNets()
        },
        selectFun(index) {
            this.selected_net = this.nets[index].tokenType

            this.txIdIsNeeded = this.nets[index].txIdIsNeeded
            this.data.tokenType = this.nets[index].tokenType
            this.netDrop = false

        },
        MinMaxAmount() {
            this.minAmountExchange = this.coin.find(a => a.coin == this.destCoinSymbol).minAmountExchange
            console.log('ekrvhjdfvh', this.minAmountExchange);
            this.maxAmountExchange = this.coin.find(a => a.coin == this.destCoinSymbol).maxAmountExchange
        },
        beforeLoop() {
            if (this.state.loop) {
                this.loop = new Loop(this.getPrice, 5000, 'realPriceBuy' + 'Toman')
                this.loop.start()
            } else {
                this.getPrice()
            }
        }
    },
    beforeDestroy() {
        if (this.loop) {
            this.loop.stop()
        }

    },
    async mounted() {
        this.state.loading = true
        await this.beforeLoop()
        this.tomanTetherWallet()
        await this.getCoin()
        this.MinMaxAmount()
        this.state.loading = false
    }

}
</script>

<style lang="scss" scoped>
hr {
    border: none;
    background-color: rgba(61, 73, 204, 0.1);
    height: 1px;
    width: 100%;
}

.buttons {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 20px 0;
}

button {
    height: 44px;
    flex-grow: 1;
    border: 1px solid rgba(61, 73, 204, 0.5);
    color: rgba(61, 73, 204, 0.5);
    background: transparent;
    border-radius: 3px;
}

.active {
    border: 1px solid rgba(61, 73, 204, 1);
    color: rgba(61, 73, 204, 1);
}

.balance {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.balance-increase {
    color: rgba(61, 73, 204, 1);
    border-bottom: 1px solid rgba(61, 73, 204, 1);
}

.balance-number {
    color: rgba(153, 153, 153, 1);

    span {
        border-bottom: 1px solid rgba(153, 153, 153, 1);
    }
}

hr {
    border: none;
    height: 1px;
    background-color: rgba(61, 73, 204, 0.1);
    width: 100%;
}

.border-bottom {
    border-bottom: 1px solid rgba(61, 73, 204, 0.1);
    margin: 20px 0;
    position: relative;
}

.or {
    position: absolute;
    top: -12px;
    right: 48%;
    color: #3D49CC;
    font-weight: normal;
    font-size: 12px;
    padding: 0 5px;
    background: white;
}

.total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

.buyButton {
    width: 100%;
    height: 44px;
    border-radius: 3px;
    color: white;
    border: none;
}

.buyButton {
    background-color: rgba(128, 204, 82, 1);
}

.btc-img {
    width: 35px;
    height: 35px;
}

.heade {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.name {
    color: #525CCC;
}

.name-container {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.login-btn {
    background: #3D49CC;
    border-radius: 3px;
    color: white;
    width: 100%;
    height: 44px;
    border: none;
}

.buy-inputs {
    margin-top: 10px;
}

.price-buy {
    color: #80CC52;
}

.selectd-net-lable {
    position: relative;
        width: 100%;
    .selectd-net {
        position: relative;
        border: none;
        border: 1px solid #3D49CC;
        width: 100%;
        height: 44px;
        color: #3D49CC;
        font-size: 14px;
        display: flex;
        border-radius: 3px;
        margin-top: 15px;
        cursor: pointer;
        padding: 0 10px;
        align-items: center;
        justify-content: space-between;
    }

    .netDropdown {
        position: absolute;
        background: white;
        top: 44px;
        box-shadow: 1px 1px 3px 0px black;
        // padding
        // left: 0;
        // right: 0;
        width: 100%;

        div {
            background: white;
            width: 100%;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
        }
    }
}
</style>
