<template>
<div class="faq">
    <p class="title">
        سوالات متداول
    </p>
    <div class="question-container">
        <div class="question" v-for="(message,index) in messages" :key="index">
            <div class="question-box" @click.prevent="toggle(index)">
                <p>
                    {{message.question}}
                </p>
                <svg class="deActive-svg" :class="{'active-svg' : showmessage[index]}" width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.6" d="M5.00012 5C4.69998 5 4.39988 4.88709 4.17135 4.66122L0.114688 0.659464C-0.0380076 0.508831 -0.0382615 0.264347 0.114121 0.113385C0.266543 -0.0375759 0.513848 -0.0378271 0.666563 0.112825L4.7235 4.11485C4.87609 4.26568 5.12389 4.2657 5.27621 4.11514L9.33344 0.112825C9.48617 -0.0378078 9.73348 -0.0375759 9.88588 0.113385C10.0383 0.264347 10.038 0.508831 9.88531 0.659464L5.82834 4.66149C5.6001 4.88713 5.30008 5 5.00012 5Z" />
                </svg>
            </div>
            <div class="answer-box" :style="{maxHeight: showmessage[index]? (messageheight[index] + 'px') : ''}">
                <p class="">
                    {{message.answer}}
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'FAQ',
    components: {

    },
    data() {
        return {
            showmessage: [],
            messageheight: [],
            messages: [],
        }
    },
    methods: {
        toggle(e) {
            this.messageheight[e] = document.getElementsByClassName('answer-box')[e].firstChild.clientHeight + 20
            this.showmessage[e] = !this.showmessage[e];
            this.$forceUpdate()
        },
        async getFaq() {
            this.state.loading = true
            this.messages = await this.$axios.get('/faq')
            for (let i = 0; i < this.messages.length; i++) {
                this.showmessage[i] = false
                // this.questions[0].svgClass='deActive'
            }
            this.state.loading = false

        }
    },
    mounted() {
        this.getFaq()
    }
}
</script>

<style scoped>
.faq {
    /* width: 80%; */
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
}

.title {
    font-weight: bold;
    font-size: 36px;
    margin-top: 0;
}

.question {
    width: 100%;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 0 20px;
    /* border: 1px solid #B1B6EB; */
    background: #F2F3FF;
}

.question:hover>.question-box>svg {
    opacity: 1;
}

.question:hover {
    /* border: 2px solid #3D49CC; */
    background: #e8e9ff;
}

.active-question {
    border: 2px solid #3D49CC;
}

.question-box {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    cursor: pointer;
}

.question-box>p {
    font-weight: normal;
    /* font-size: 18px; */
    font-size: clamp(14px, 1.5vw, 18px);
}

svg {
    width: 20px;
    height: 10px;
}

.deActive-svg {
    fill: #3D49CC;
    opacity: 0.6;
    /* transform: rotate(-180deg);
    transition: ease-in 0.3s; */
    
}

.active-svg {
    opacity: 1;
}

.deActive-answer {
    display: none;
    /* transition: height 2s ease-in-out  */
}

.active-answer {
    display: block;
    height: auto;
    border-top: 1px solid rgba(61, 73, 204, 0.2);
}

.answer-box {
    max-height: 0;
    box-sizing: border-box;
    overflow: hidden;
}

.answer-box>p {

    font-weight: normal;
    font-size: 14px;
}

.question-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
 /* .active-svg{
     transition: width 2s, height 4s;
 } */
.active-svg {
    transform: rotate(-180deg);
    transition: ease-in 0.3s;
}
 /* .active-svg{
     transition: width 2s, height 4s;
 } */
</style>
