<template>
<transition name="modal-fade">
    <div class="modal-backdrop" @click.self="state.BuyModal = false;state.SellModal = false">
        <!-- <SuccessSell v-if="success" @close='success = false'/> -->
        <div class="modal" v-if="!success">
            <svg class="close-svg" width="23" height="22" viewBox="0 0 23 22" fill="black" xmlns="http://www.w3.org/2000/svg" @click="closeLogin">
                <path d="M20.1625 0.393398L11.2132 9.34272L2.26388 0.393398C1.80622 -0.0642598 1.06426 -0.0642597 0.606602 0.393398C0.148943 0.851057 0.148943 1.59302 0.606602 2.05068L9.55592 11L0.606602 19.9493C0.148943 20.407 0.148943 21.1489 0.606602 21.6066C1.06426 22.0643 1.80622 22.0643 2.26388 21.6066L11.2132 12.6573L20.1625 21.6066C20.6202 22.0643 21.3621 22.0643 21.8198 21.6066C22.2775 21.1489 22.2775 20.407 21.8198 19.9493L12.8705 11L21.8198 2.05068C22.2775 1.59302 22.2775 0.851057 21.8198 0.393398C21.3621 -0.0642597 20.6202 -0.0642597 20.1625 0.393398Z" fill="black" />
            </svg>
            <div class="title">
                <p class="header" :class="{'active-header':state.BuyModal}" @click="Buy">خرید</p>
                <p class="header" :class="{'active-header':state.SellModal} " @click="Sell">فروش</p>
            </div>
            <div class="body">
                <BuyModal :destCoinSymbol='destCoinSymbol'  v-if="state.BuyModal" @openSuccess="openParentSuccess"/>
                <SellModal :destCoinSymbol='destCoinSymbol'   v-if="state.SellModal" @openSuccess="openParentSuccess"/>
            </div>
        </div>
    </div>
</transition>
</template>

<script>
import BuyModal from './BuyModal.vue'
import SellModal from './SellModal.vue'
// import SuccessSell from '../../components/Modals/SuccessSell'
export default {
    name: 'BuySellModals',
    components: {
        BuyModal,
        SellModal,
        // SuccessSell
    },
    props:['destCoinSymbol' , 'sellPrice' , 'buyPrice'],
    data() {
        return {
            active: false,
            disablebtn: true,
            total: 54654654654,
            img: require('../../assets/Coins/btc.png'),
            name: 'بیت کوین',
            balance: 123132132,
            walletBalance:'',
            BuywalletBalance:'',
            success:false
        }
    },

    methods: {
        openParentSuccess(value) {
            this.$emit('openSuccess',value)
        },
        closeLogin() {
            this.state.BuyModal = false
            this.state.SellModal = false
        },
        Buy() {
            this.state.BuyModal = true
            this.state.SellModal = false
        },
        Sell() {
            this.state.BuyModal = false
            this.state.SellModal = true
        },
        buyAction() {
            this.state.BuyModal = false
            this.state.SellModal = false
            this.state.successBuy = true
            //  setTimeout(() => { this.$store.state.successBuy=false; }, 1000);
        },
        sellAction() {
            this.state.BuyModal = false
            this.state.SellModal = false
            this.state.successSell = true
        },
        async getWallets() {
            this.loading = true
            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE&coins=' + this.destCoinSymbol)
            if (res) {
                this.walletBalance = res[this.coin]
            }
            this.loading = false
        },
    },
    mounted() {
        if(this.state.token) {
            this.getWallets()
        }
    },
}
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(93, 109, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    background: #FFFFFF;
    border-radius: 3px;
    max-width: 400px;
    width: 90vw;
    min-height: 332px;
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    position: relative;
}

.close-svg {
    position: absolute;
    right: 10px;
    z-index: 111;
}

.close-img {
    cursor: pointer;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 30px;
}

.header {
    font-weight: normal;
    font-size: 18px;
    color: #999999;
    width: 75px;
    text-align: center;
    cursor: pointer;
}

.active-header {
    font-weight: bold;
    font-size: 18px;
    color: #404040;
    border-bottom: 3px solid #3D49CC;
}

.title {
    display: flex;
    justify-content: center;
    column-gap: 20px;
}

hr {
    border: none;
    height: 1px;
    background-color: rgba(61, 73, 204, 0.1);
    width: 100%;
}

.total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

.buyButton,
.sellButton {
    width: 100%;
    height: 44px;
    border-radius: 3px;
    color: white;
    border: none;
}

.buyButton {
    background-color: rgba(128, 204, 82, 1);
}

.sellButton {
    background-color: rgba(215, 47, 112, 1);
}

.btc-img {
    width: 35px;
    height: 35px;
}

.heade {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.name-container {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.price-buy {
    color: #80CC52;
}

.price-sell {
    color: #D72F70;
}
</style>
