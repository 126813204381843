<template>
<div class="wallet" :key="state.requestChanged">
    <div class="wallet-title">
        <p class="wallet-title-p">
            ارزش کل دارایی ها
        </p>
        <p class="wallet-title-number">
            <span> تومان {{totalBalance}}</span>
        </p>
    </div>
    <div class="wallet-info" v-for="(wallet , index) in wallets.filter(e => e[0]=='TOMAN')" :key="index">
        <div class="first-info">
            <div>
                <img class="info-img" src="@/assets/Iranflag.png" alt="">
            </div>
            <div class="numbers-container">
                <p class="number-title">
                    کیف پول تومان
                </p>
                <p class="number" v-if="wallet[1].totalBalance < 0">
                    <!-- {{wallet[1].totalBalance}}  -->
                     {{wallet[1].totalBalance}} 
                </p>
                <p v-else class="number">
                    {{$toLocal(wallet[1].totalBalance,0)}} 
                </p>
            </div>
        </div>
        <div class="numbers-container">
            <p class="number-title">
                در معامله
            </p>
            <p class="number">
                <!-- {{deal.toLocaleString()}} -->
                {{$toLocal(wallet[1].blockedCredit,0)}} 
            </p>
        </div>
        <div class="numbers-container">
            <p class="number-title">
                قابل برداشت
            </p>
            <p class="number">
                <!-- {{withdrawal.toLocaleString()}} -->
                {{$toLocal(wallet[1].credit,0)}} 
            </p>
        </div>
        <div class="btns">
            <button class="info-btn" @click="depositModalToman(wallet)">
                واریز
            </button>
            <button class="info-btn" @click="withdrawModalToman(wallet)">
                برداشت
            </button>
        </div>
    </div>
    <div class="walletList">
        <table>
            <tr class="tr-head">
                <!-- :class="{'balance' : tableTitle.title ==='موجودی' , 
                        'value' : tableTitle.title ==='ارزش' , 
                        'withdrawal' :  tableTitle.title==='قابل برداشت' ,
                        'deal' : tableTitle.title === 'در معامله'}" -->
                <td v-for="(item , index) in heade" :key=index :class="item.class">
                    {{item.title}}
                </td>
            </tr>
            <tr class="tr-body" v-for="(wallet , index) in wallets.filter(e => e[0]!=='TOMAN')" :key="index">
                <td>
                    <img class="list-img" :src="'/coins/'+ wallet[0]+ '.png'" :alt="wallet[0]">
                    <span>
                        {{$coins[wallet[0]].persianName}}
                    </span>
                    <span class="ENname">
                        {{$coins[wallet[0]].code}}
                    </span>
                </td>
                <td class="balance">
                     {{$toLocal(wallet[1].totalBalance,$decimal[wallet[0]])}} {{$coins[wallet[0]].code}}
                </td>
                <td class="value">
                    <!-- {{walletList.value.toLocaleString()}} -->
                    {{$toLocal(wallet[1].wholePriceOfCredit,0)}}
                </td>
                <td class="deal">
                    <!-- {{walletList.deal.toLocaleString()}} -->
                    {{$toLocal(wallet[1].blockedCredit,$decimal[wallet[0]])}}
                </td>
                <td class="withdrawal">
                    <!-- {{walletList.withdrawal.toLocaleString()}} -->
                    {{$toLocal(wallet[1].credit,$decimal[wallet[0]])}}
                </td>
                <td>
                    <span class="opration" @click="depositModalCrypto(wallet)">
                        واریز
                    </span>
                </td>
                <td>
                    <span class="opration" @click="withdrawModalCrypto(wallet)">
                        برداشت
                    </span>
                </td>
                <td>
                    <a href="/crypto-information" @click.prevent="$router.push('/advanced-trade/TOMAN/'+wallet[0])">
                        <span class="opration">
                            معامله
                        </span>
                    </a>
                </td>
            </tr>
        </table>
    </div>
    <WalletBD :related-coin="relatedCoinVar" :cash="cash" @two-fa="openTwoStepModal" :wage="wage" :nets="nets" :exchange='true' v-if="state.walletBtcDe" />
    <WalletBW :related-coin="relatedCoinVar" :cash="cash" @two-fa="openTwoStepModal" :role="role" :nets="nets" :exchange='true' :wage="wage" v-if="state.walletBtcWi" @gotoTwoStep="openTwoStepWith"/>
    <ContinueBW v-if="twoStep" :two-step="twoStep" @submit="submitWithdrawRequest" @close="twoStep = ''" :data="twoStepData"/>
    <WalletTD v-if="showRDepModal" @close="showRDepModal = false" />
    <WalletTW v-if="showRWithModal" @close="showRWithModal = false" :cash="cash" @two-fa="openTwoStepModal" :role="role" />
    <!-- <WalletInforamation/> -->
    <!-- <WalletList/> -->
</div>
</template>

<script>
// import WalletInforamation from './WalletInforamation.vue'
// import WalletList from './WalletList.vue'
import WalletBD from './WalletBD.vue'
import WalletBW from './WalletBW.vue'
import ContinueBW from './ContinueBW.vue'
import WalletTD from './WalletTD.vue'
import WalletTW from './WalletTW.vue'
export default {
    name: 'OtcWallet',
    components: {
        // WalletList
        WalletBD,
        WalletBW,
        ContinueBW,
        WalletTD,
        WalletTW
    },
    data() {
        return {
            totalBalance : 0,
            showWithModal: false,
            showDepModal: false,
            showRWithModal: false,
            showRDepModal: false,
            wallets: [],
            relatedCoinVar: '',
            cash: 0,
            twoStep: '',
            transaction: {},
            coins: [],
            wage: '',
            role: {},
            loading: false,
            nets : "",
            twoStepData:{},
            heade: [{
                    title: "ارز",
                    class: ""
                },
                {
                    title: "موجودی",
                    class: "balance"
                },
                {
                    title: "ارزش",
                    class: "value"
                },
                {
                    title: "در معامله",
                    class: "deal"
                },
                {
                    title: "قابل برداشت",
                    class: "withdrawal"
                },
                {
                    title: "عملیات",
                    class: ""
                }
            ]
        }
    },
    computed: {
        amount() {
            return e => this.$toLocal(e.credit) || '0'
        },
        price() {
            return e => this.$toLocal(e[0] === 'TOMAN' ? e[1].totalBalance : e[1].wholePriceOfCredit, 0) || '0'
        },
        blocked() {
            return e => this.$toLocal(e[1].blockedCredit) || '0'
        },
        credit() {
            return e => this.$toLocal(e.credit) || '0'
        },
    },
    methods: {
        depositModal(e) {
            this.relatedCoinVar = e
            if (e[0] !== 'TOMAN') {
                this.showDepModal = true
            } else {
                this.showRDepModal = true
            }
        },
        depositModalCrypto(e) {
            this.relatedCoinVar = e[0]
            this.nets = this.coins.find((a) => a.coin === e[0]).tokenTypeDetails
             this.state.walletBtcDe = true
        },
        depositModalToman(e) {
            this.relatedCoinVar = e[0]
            this.showRDepModal = true
        },
        withdrawModal(e) {
            this.relatedCoinVar = e[0]
            this.cash = e[1]
            this.role = this.coins.find((a) => a.coin === e[0])
            // this.wage = this.role?.transferFee
            if( this.coins.find((a) => a.coin === e[0]).tokenTypeDetails) {
                this.wage = this.coins.find((a) => a.coin === e[0]).tokenTypeDetails[0].withdrawalFee
            }
            else {
                this.wage = 0
            }
            
            if (e[0] !== 'TOMAN') {
                this.showWithModal = true
            } else {
                this.showRWithModal = true
            }
        },
        withdrawModalToman(e) {
             this.relatedCoinVar = e[0]
             this.cash = e[1].credit
             console.log(this.cash)
             this.showRWithModal = true
        },
        withdrawModalCrypto(e) {
            this.nets = this.coins.find((a) => a.coin === e[0]).tokenTypeDetails
            this.relatedCoinVar = e[0]
            this.cash = e[1].credit
            this.role = this.coins.find((a) => a.coin === e[0])
            // if( this.coins.find((a) => a.coin === e[0]).tokenTypeDetails) {
            //     this.wage = this.coins.find((a) => a.coin === e[0]).tokenTypeDetails[0].withdrawalFee
            // }
            // else {
            //     this.wage = 0
            // }
            if (this.relatedCoinVar == 'TOMAN') {
                this.showRWithModal = true
            } else {
                 this.wage = this.coins.find((a) => a.coin === e[0]).tokenTypeDetails[0].withdrawalFee
                this.state.walletBtcWi = true
            }

        },
        openTwoStepModal(e) {
            console.log('eeeeeeeeeeeeee',e);
            this.twoStepData = e[0]
            this.twoStep = e[1]
        },
        openTwoStepWith(value) {
            this.twoStepData = value
            this.twoStep = true
        },
        closemodal() {
            this.showWithModal = false
            this.showDepModal = false
            this.showRWithModal = false
            this.showRDepModal = false
            this.twoStep = ''
        },
        async getWallets() {
            this.state.loading = true
            // const coinsList="ETHEREUM,BITCOIN_CASH,TRON,RIPPLE,TETHER,TOMAN,BITCOIN"
            this.loading = true
            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE')
            if (res) {
                let entireRes = Object.entries(res)
                const sortCoins = this.$walletSortCoins
                entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
                this.wallets = entireRes
                let total = 0
                for(var i=0 ; i <= this.wallets.length-1 ;i++) {
                    const lengthItem = this.wallets[i]
                    total = lengthItem[1].wholePriceOfCredit + total
                }
                this.totalBalance = this.$toLocal(total,0)
            }
            this.loading = false
        },
        async submitWithdrawRequest(code) {
            this.state.loading = true
            this.transaction.code = code
            const [res, ] = await this.$http.post(`/wallets/withdrawal-request`, this.transaction)
            if (res) {
                this.$error('درخواست شما با موفقیت ثبت شد', '', 'success')
                    ++this.state.requestChanged
                this.closemodal()
            }
        },
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/coins/details')
            this.coins = res.content
        },
    },
    mounted() {
        this.state.loading = true
        this.getWallets()
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.wallet {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    /* max-width: 995px; */
    width: 100%;
}

.wallet-title {
    height: 68px;
    display: flex;
    box-sizing: border-box;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    background: white;
    width: 100%;
    flex-wrap: wrap;
    column-gap: 20px;
}

.wallet-title-p {
    /* font-size: 22px; */
    font-size: clamp(16px, 1vw, 22px);
    margin: 0;
}

.wallet-title-number {
    /* font-size: 22px; */
    font-size: clamp(16px, 1vw, 22px);
    color: #3D49CC;
    margin: 0;
}

.wallet-info {
    background: #FFFFFF;
    border-radius: 3px;
    min-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 10px 20px;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
}

.info-img {
    width: 50px;
    height: 50px;
}

.numbers-container {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;
    flex-grow: 1;
}

.numbers-container p {
    margin: 0;
}

.first-info {
    display: flex;
    align-items: center;
    column-gap: 10px;
    flex-grow: 1;
}

.number {
    font-size: 18px;
    color: #3D49CC;
}

.number-title {
    font-size: 14px;
    color: rgba(64, 64, 64, 0.5)
}

.info-btn {
    color: rgba(61, 73, 204, 0.5);
    border: none;
    width: 98px;
    height: 44px;
    background: transparent;
    box-sizing: border-box;
    border-radius: 3px;
}

.info-btn:hover {
    border: 1px solid #3D49CC;
    color: rgba(61, 73, 204, 1);
}

.btns {
    display: flex;
    column-gap: 10px;
    flex-grow: 1;
    justify-content: flex-end;
}

.walletList {
    background: #FFFFFF;
    border-radius: 3px;
    // height: 471px;
    box-sizing: border-box;
    padding: 10px 20px;
    /* overflow: scroll; */
}

table {
    width: 100%;
    text-align: center;
}

.tr-head {
    height: 50px;
}

.tr-head td {
    color: #999999;
    font-size: 12px;
}

.tr-head td:first-of-type {
    text-align: right;
    padding-right: 10px;
}

.tr-body {
    height: 42px;
    font-size: 14px;
}

.list-img {
    width: 30px;
    height: 30px;
}

.tr-body td:first-of-type {
    display: flex;
    align-items: center;
    column-gap: 10px;
    height: 42px;
}

.opration {
    font-size: 12px;
    color: rgba(61, 73, 204, 0.5);
    cursor: pointer;
    width: auto;
}

.opration:hover {
    color: rgba(61, 73, 204, 1);
    border-bottom: 1px solid rgba(61, 73, 204, 1);
}

@media only screen and (max-width:700px) {

    .ENname,
    .list-img,
    .balance,
    .value,
    .withdrawal,
    .deal {
        display: none;
    }
}
</style>
