<template>
<div class="notifications">
    <span class="notif-title">
        اعلانات
    </span>
    <div class="toggle_container">
        <div @click="unRead = true;changeToggle()" :class="{'deActive' : !unRead}">
            خوانده نشده
        </div>
        <div @click="unRead = false;changeToggle()" :class="{'deActive' : unRead}">
            خوانده شده
        </div>
    </div>
    <div class="notif-container">
        <p v-if="unRead && readmessages.length==0" class="no-content">
            پیامی وجود ندارد
        </p>
        <p v-if="!unRead && readmessages.length==0" class="no-content">
            پیامی وجود ندارد
        </p>
        <perfect-scrollbar @ps-y-reach-end="getReadMessages" ref="scroll">
            <div class="notification" :class="{'unread_message' : readmessage.read===false , 'read_message' : readmessage.read===true}" v-for="(readmessage,index) in readmessages" :key="index" @click="toggle(index)">
                <div class="box-title">
                    <div class="box-title-first-part">
                        <div class="dote"></div>
                        <span class="">
                            {{readmessage.title}}
                        </span>
                    </div>
                    <span>
                        {{$G2J(readmessage.createdAtDateTime)}}
                    </span>
                    <inline-svg :src="require('../../../assets/guid-v.svg')" class="read_title_icon" :style="{transform: [showmessage[index] ?  'rotate(180deg)':'' ]}"></inline-svg>
                </div>
                <div class="box-text" :style="{maxHeight: [showmessage[index]?  readmessageheight[index] + 'px' : '']}">
                    <p>{{readmessage.content}}</p>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
export default {
    name: 'Notification',
    components: {
        InlineSvg
    },
    data() {
        return {
            read: false,
            unRead: true,
            showmessage: [],
            showmessage0: [],
            unreadmessageheight: [],
            readmessageheight: [],
            messages: [],
            readmessages: [],
            unreadmessages: [],
            readTotalElements: 0,
            unreadTotalElements: 0,
            pageUnread: 1,
            perPageUnread: 6,
            lastPage: 1,
            page: 0,
            perPage: 10,
            setting: {
                suppressScrollX: true
            }
        }
    },
    methods: {
        toggle(e) {
            this.readmessageheight[e] = document.getElementsByClassName('box-text')[e].firstChild.clientHeight + 20
            this.showmessage[e] = !this.showmessage[e];
            this.$forceUpdate()
            let id = this.readmessages[e].id
            if (this.unRead) {
                this.readMessage(id, e)
            }
        },
        changeToggle() {
            this.readmessages = []
            this.readTotalElements = 0
            this.lastPage = 1,
                this.page = 0,
                this.getReadMessages()
        },
        async getReadMessages() {
            if (this.page <= this.lastPage && !this.loading) {
                this.page++
                this.state.loading = true
                const res = await this.$axios.get('/users/notifications', {
                    params: {
                        read: !this.unRead,
                        page: this.page,
                        size: 10,
                    }
                })
                if (res) {
                    this.lastPage = res.totalPages
                    this.readmessages.push(...res.content)
                    this.readTotalElements = res.totalElements
                }
            }

        },
        async readMessage(e, index) {
            if (!this.readmessages[index].read) {
                await this.$axios.put(`/users/notifications/read/${e}`)
                this.readmessages[index].read = true
                    --this.readTotalElements
                    --this.state.messages
            }
            this.$forceUpdate()
        },
    },
    mounted() {
        this.getReadMessages()
    }
}
</script>

<style lang="scss" scoped>
.notifications {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 3px;
    height: fit-content;
}

.notif-title {
    font-weight: bold;
    font-size: 22px;
    color: #404040;
    margin: 24px 0;
}

.notif-container {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    height: 400px;
    overflow: scroll;
}

.notification {
    width: 100%;
    padding: 18px 24px;
    transition: transform 0.5s;
    cursor: pointer;
    border-bottom: 1px solid rgba(61, 73, 204, 0.1);
}

.notification:hover {
    background: #e8e9ff;
    // transform: translateY(10px);
}

.toggle_container {
    display: flex;
    width: 250px;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 50px;

    div {
        border-bottom: 2px solid #3D49CC;
        color: #3D49CC;
        cursor: pointer;
    }

    .deActive {
        opacity: 0.5;
    }
}

.box-title {
    font-weight: bold;
    font-size: clamp(14px, 1vw, 16px);
    // color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.no-content {
    text-align: center;
    color: red;
}

.box-text-unread {
    // padding: 15px;
    font-weight: normal;
    font-size: 12px;
    // color: #FFFFFF;
    max-height: 0;
    overflow: hidden;
    transition: 0.4s ease-out;
    -webkit-transition: 0.4s ease-out;
    -moz-transition: 0.4s ease-out;
}

.box-text-read {
    // padding: 15px;
    font-weight: normal;
    font-size: 12px;
    // color: #FFFFFF;
    max-height: 0;
    overflow: hidden;
    transition: 0.4s ease-out;
    -webkit-transition: 0.4s ease-out;
    -moz-transition: 0.4s ease-out;
}

.unread_message {
    color: #3D49CC;

    .dote {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    }

    .box-text {
        // padding: 15px;
        font-weight: normal;
        font-size: 12px;
        // color: #FFFFFF;
        max-height: 0;
        overflow: hidden;
        transition: 0.4s ease-out;
        -webkit-transition: 0.4s ease-out;
        -moz-transition: 0.4s ease-out;
        color: #3D49CC;
    }

    .read_title_icon {
        fill: #3D49CC;
    }
}

.read_message {
    color: #404040;

    .dote {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #E0E0E0;
    }

    .box-text {
        // padding: 15px;
        font-weight: normal;
        font-size: 12px;
        // color: #FFFFFF;
        max-height: 0;
        overflow: hidden;
        transition: 0.4s ease-out;
        -webkit-transition: 0.4s ease-out;
        -moz-transition: 0.4s ease-out;
    }
}

.box-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rotate {
    transform: rotate(180deg);
}

.dote {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
}

.reade-dote {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #E0E0E0;
}

.box-title-first-part {
    display: flex;
    align-items: center;
    column-gap: 16px;
}

.read_title_icon {
    // fill: #3D49CC;
    fill: #404040;
    width: 25px;
}

.unread_title_icon {
    fill: #3D49CC;
    // width: 25px;
}
</style>
