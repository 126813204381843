<template>
    <div class="personal">
        <div>
            <p class="title">اطلاعات شخصی</p>
        </div>
        <div class="inputs">
            <label class="personal-label">
                <input class="personal-input" type="text" placeholder="نام"
                @input="data.firstName = $S2Fa(data.firstName)"
                v-model="data.firstName"
                :disabled="readonly">
                <span class="input-title">
                    نام
                </span>
            </label>
            <label class="personal-label">
                <input class="personal-input" type="text" placeholder="نام خانوادگی"
                @input="data.lastName = $S2Fa(data.lastName)"
                v-model="data.lastName"
                :disabled="readonly">
                <span class="input-title">
                    نام خانوادگی
                </span>
            </label>
            <label class="personal-label">
                <input class="personal-input" type="nationalid" placeholder="کد ملی"
                @input="data.nationalCode = $p2e(data.nationalCode)"
                v-model="data.nationalCode"
                maxlength="10"
                :disabled="readonly">
                <span class="input-title">
                    کد ملی
                </span>
            </label>
            <!-- <label class="personal-label"> -->
                <date-picker
                    id="birthday"
                    v-model="data.birthDay"
                    class="personal-input"
                    placeholder="تاریخ تولد"
                    :format="$dateFormat['en']"
                    :display-format="$dateFormat['fa']"
                    :max="$jmoment().format($dateFormat['en'])"
                    :clearable="!state.userInfo.authorized"
                    :disabled="readonly"/>
            <!-- </label> -->
            <label class="personal-label">
                <input class="personal-input" type="text" placeholder="شماره موبایل"
                @input="data.mobileNumber = $p2e(data.mobileNumber)"
                 v-model="data.mobileNumber"
                  minlength="11"
                   maxlength="11">
                <span class="input-title">
                    شماره موبایل
                </span>
            </label>
            <label class="personal-label">
                <input class="personal-input" type="text" placeholder="شماره ثابت"
                @input="data.telephone = $p2e(data.telephone)"
                v-model="data.telephone"
                minlength="11"
                maxlength="11">
                <span class="input-title">
                    شماره ثابت
                </span>
            </label>
        </div>
        <div>
            <label for="" class="personal-label">
                <textarea class="personal-input"  name="" id=""  placeholder="آدرس منزل"
                v-model="data.address"
                style="resize:none"></textarea>
                <span class="input-title">
                    آدرس منزل
                </span>
            </label>
        </div>
    </div>
</template>

<script>
import '@/style/birthdayPicker.scss'
export default {
  name: 'Profile',
  components :{
    
  },
   props: ['data'],
  data() {
      return {
         
      }
  },
  computed: {
            readonly() {
                return ['AUTHORIZED', "INFORMATION_CHANGED", 'INFORMATION_CHANGED_PENDING'].includes(this.state.userInfo.authenticationType)
            }
        },
  methods : {
      
  }
  }
</script>

<style lang="scss" scoped>
    .personal {
        // max-width:995px;
        background: #FFFFFF;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        box-sizing: border-box;
        padding: 20px;
        flex-grow: 1;
            .title {
                font-size: 22px;
                margin: 0;
            }
    }
    .inputs {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
        justify-content: center;
    }
    label {
        display: flex;
        flex-basis: 305px;
        // max-width: 305px;
    }
    input {
        border: 1px solid rgba(61, 73, 204, 0.3);
        box-sizing: border-box;
        border-radius: 3px;
        height: 44px;
        // max-width: 305px;
        width: 100%;
        justify-content: center;
    }
    input::placeholder ,textarea::placeholder {
        color:rgba(61, 73, 204, 0.3);
    }
    textarea {
        height: 88px;
        width: 100%;
        border: 1px solid rgba(61, 73, 204, 0.3);
        box-sizing: border-box;
        border-radius: 3px;
        padding: 10px;
        resize: none;
    }
</style>
<style lang="scss">
    .vpd-input-group {
        height: 44px;
    }
</style>
