var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"crypto-container"},[_c('div',{staticClass:"crypto"},[_c('div',{staticClass:"statistics-container"},[_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"statistics-list"},[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":"","alt":""}}),_c('img',{staticClass:"crypto-img",attrs:{"src":_vm.cryptoImg,"alt":""}}),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.cryptoName)+" ")])]),_c('div',{staticClass:"title-number"},[_c('span',{class:{'positive-change':this.info[0].change>0 , 'negative-change':this.info[0].change<0}},[_vm._v(" "+_vm._s(_vm.cryptoPrice.toLocaleString())+" ")])])]),_vm._l((_vm.info),function(info,index){return _c('div',{key:index,staticClass:"statistics-list"},[_c('span',{staticClass:"statistics-list-title"},[_vm._v(" "+_vm._s(info.title)+" ")]),_c('span',{staticClass:"statistics-list-number",class:{'positive-change':info.change>0 , 'negative-change':info.change<0}},[_vm._v(" "+_vm._s(info.number.toLocaleString())),(info.change)?_c('span',[_vm._v("%")]):_vm._e(),_vm._v(" "),(info.change>0)?_c('span',[_vm._v("+")]):_vm._e()])])}),_vm._m(0)],2),_c('div',{staticClass:"traiding-container"},[_c('vue-trading-view',{key:this.$store.state.tradingTheme,staticStyle:{"height":"400px","width":"100%"},attrs:{"options":{
                            height:'600',
                            autosize:true,  
                            theme:'light',  
                                    timezone: 'Asia/Tehran',
                                    style: '1',
                                    locale: 'fa_IR',
                                    enable_publishing: true,
                                    withdateranges: true,
                                    range: 'ytd',
                                    hide_side_toolbar: true,
                                    allow_symbol_change: true,
                                    enabled_features: [
                                        'header_fullscreen_button'
                                        ],
                                    disabled_features: [
                                        'border_around_the_chart',
                                        'remove_library_container_border' ],
                                    loading_screen:{
                                        backgroundColor:'#FFFFFF',
                                        foregroundColor: '#FFFFFF',
                                        },
                                    overrides: {
                                        background: '#FFFFFF'
                                    }
                        }}})],1)]),_c('hr'),_c('div',{staticClass:"describe-container"},[_c('DescribeCrypto'),_vm._m(1),_c('AddComment'),_c('div',{staticClass:"line"},[_c('span',{staticClass:"line-title"},[_vm._v(" نظرات کاربران ("+_vm._s(_vm.commentsN)+") ")])]),_c('CommentsList')],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"btns-container"},[_c('button',{staticClass:"buy-btn"},[_vm._v(" خرید بیتکوین ")]),_c('button',{staticClass:"sell-btn"},[_vm._v(" فروش بیتکوین ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"line"},[_c('span',{staticClass:"line-title"},[_vm._v(" افزودن نظر ")])])}]

export { render, staticRenderFns }