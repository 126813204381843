<template>
<div class="online-container">
    <!-- <BuySell v-if="buySell" v-on:closeLoginModal="closeModal" /> -->
    <BuySellModals :buyPrice="buyPrice" :sellPrice="sellPrice" :destCoinSymbol="destCoinSymbol" v-if="(state.BuyModal || state.SellModal)" @openSuccess='openSuccessModal' />
    <SuccessSell v-if="success" :data="successData" @close="success=false" />
    <!-- <SuccessSell v-if="state.successSell" /> -->
    <div class="online">
        <div class="title-container">
            <p class="title">
                قیمت لحظه ای ارزها
            </p>
            <label for="">
                <input class="search" type="text" placeholder="جستوجو در ارزها" v-model="findCoin">
                <img class="search-img" src="@/assets/search.svg" alt="">
            </label>
        </div>
        <div class="table-container">
            <table>
                <tr class="tr-head">
                    <td v-for="tableTitle , index in tableTitle" :key="index" :class="{'buy-td':tableTitle.title==='قیمت خرید از ما' ,
                                    'sell-td':tableTitle.title==='قیمت فروش به ما' ,
                                    'chart-td':tableTitle.title==='نمودار',
                                    'change':tableTitle.title==='تغییرات' ,
                                    'detail':tableTitle.title===''}">
                        {{tableTitle.title}}
                    </td>
                </tr>
                <!-- <a href="/crypto-information" @click.prevent="$router.push('/crypto-information')"> -->
                <tr class="tr-body" v-for="(coin , index) in filterPrices" :key="index" @click="selectedCrypto(index)">
                    <td>
                        <img class="crypto-img" :src="'/coins/'+ coin.destCoinSymbol + '.png'" alt="">
                        <span>
                            {{$coins[coin.destCoinSymbol].persianName}}
                        </span>
                        <span>
                            ({{$coins[coin.destCoinSymbol].code}})
                        </span>
                    </td>
                    <td class="buy-td">
                        {{$toLocal(coin.buyPrice,$decimal['TOMAN'])}}
                    </td>
                    <td class="sell-td">
                        {{$toLocal(coin.sellPrice,$decimal['TOMAN'])}}
                    </td>
                    <td class="change" :class="{'positive-change':coin.price24hrChangePercent > 0 , 'negetive-change':coin.price24hrChangePercent < 0}">
                        <span v-if=" coin.price24hrChangePercent < 0"> - </span>
                        <span v-if="coin.price24hrChangePercent > 0"> + </span>
                        %
                        {{Math.abs(+coin.price24hrChangePercent).toFixed(2)}}

                    </td>
                    <td class="chart-td">
                        <!-- <img class="chart-img" src="@/assets/chart.png" alt=""> -->
                        <TrendChart :trend='coin.weeklyPrices' />
                    </td>
                    <td class="sell-buy">
                        <span class="buy-btn" @click="Buybtn(index)">
                            خرید
                        </span>
                        <span class="sell-btn" @click="Sellbtn(index)">
                            فروش
                        </span>
                    </td>
                    <!-- <td class="detail">
                        <a href="/crypto-information" @click.prevent="$router.push('/crypto-information')">
                            <img src="@/assets/Vector.png" alt="">
                        </a>
                    </td> -->
                </tr>
                <!-- </a> -->
            </table>
        </div>
    </div>
</div>
</template>

<script>
// import BuySell from '../Modals/BuySell.vue'
import BuySellModals from '../Modals/BuySellModals.vue'
import SuccessSell from '../Modals/SuccessBuy.vue'
// import SuccessSell from '../Modals/SuccessSell.vue'
import TrendChart from './TrendChart.vue'
import {
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'OnlinePrice',
    components: {
        // BuySell,
        BuySellModals,
        SuccessSell,
        // SuccessSell,
        TrendChart
    },
    data() {
        return {
            successData: {},
            prices: [],
            coins: [],
            destCoinSymbol: "BITCOIN",
            buyPrice: 0,
            sellPrice: 0,
            buySell: false,
            success: false,
            findCoin: '',
            tableTitle: [{
                    title: "بازارها"
                },
                {
                    title: "قیمت خرید از ما"
                },
                {
                    title: "قیمت فروش به ما"
                },
                {
                    title: "تغییرات"
                },
                {
                    title: "نمودار"
                },
                {
                    title: "عملیات"
                },
                // {
                //     title: ""
                // }

            ],
            loop: undefined
        }
    },
    computed: {
        filterPrices() {
            let filterPrice = this.prices
            if (this.findCoin) {
                // var persianName = this.$coins[this.destCoinSymbol].persianName
                filterPrice = this.prices.filter(a => a.destCoinSymbol.includes(this.findCoin.toUpperCase()) || this.$coins[a.destCoinSymbol].persianName.includes(this.findCoin.toUpperCase()))
            }
            return filterPrice
        }
    },
    methods: {
        openSuccessModal(e) {
            this.successData = e
            this.success = true
        },
        selectedCrypto(index) {
            console.log(index)
        },
        Buybtn(index) {
            //   this.buySell=true
            //   this.$store.state.activeBuyModal=true
            //   console.log(this.buySell)
            
            // if (this.state.token) {
                this.destCoinSymbol = this.prices[index].destCoinSymbol
                this.buyPrice = this.prices.find(a => a.destCoinSymbol == this.destCoinSymbol).buyPrice
                this.destCoinSymbol = this.prices[index].destCoinSymbol
                this.state.BuyModal = true
            // }
            // else {
            //     this.state.login = true
            // }

        },
        Sellbtn(index) {
            //   this.buySell=true
            //   this.$store.state.activeSellModal=true
            this.destCoinSymbol = this.prices[index].destCoinSymbol
            this.sellPrice = this.prices.find(a => a.destCoinSymbol == this.destCoinSymbol).sellPrice
            this.state.SellModal = true
        },
        closeModal(index) {
            this.buySell = index
        },
        async getPrice() {

            // const res = await this.$axios.get('coins/exchange-price/' + (this.toman ? 'usd' : 'usdt') + '?includeWeeklyChange=false')
            const res = await this.$axios.get('coins/exchange-price/' + 'usd' + '?includeWeeklyChange=true')
            this.prices = res
            this.buyPrice = this.prices.find(a => a.destCoinSymbol == this.destCoinSymbol).buyPrice
            // const from = this.$route.query.from || 'BITCOIN'
            // this.state.exchangeBuyPrice = this.prices.find(a => a.destCoinSymbol === from).buyPrice
            // this.state.exchangeSellPrice = this.prices.find(a => a.destCoinSymbol === from).sellPrice
        },

        beforeLoop() {
            this.loading = true
            if (this.state.loop) {
                this.loop = new Loop(this.getPrice, 5000, 'realPrice' + 'Toman')
                this.loop.start()
            } else {
                this.getPrice()
            }
        }
    },
    beforeDestroy() {
        if (this.loop) {
            this.loop.stop()
        }

    },
    mounted() {
        this.state.loading = true
        //   this.getPrice()
        this.coins = this.$coins
        this.beforeLoop()
        this.state.loading = false

    }
}
</script>

<style lang="scss" scoped>
.online-container {
    background: #F2F4F5;
    padding: 50px 0 50px;
    width: 100%;
       min-height: calc(100vh - 40px);
    display: flex;
    justify-content: center;
    box-sizing: border-box;

    .online {
        background: white;
        // max-width: 1250px;
        width: 100%;
        margin-top: 50px;
        max-width: 1440px;
        box-sizing: border-box;
        padding: 10px;
        // margin-top: 50px;
        padding: 10px 10px;

        .title-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 0 20px;
            column-gap: 40px;
            flex-wrap: wrap;

            .title {
                font-weight: normal;
                font-size: clamp(20px, 1.5vw, 24px);
                color: #404040;
            }

            .search {
                height: 44px;
                width: 255px;
                border: 1px solid rgba(82, 92, 204, 0.3);
                box-sizing: border-box;
                border-radius: 3px;
                padding: 0 40px 0 10px;
                outline: none;
            }

            .search::placeholder {
                color: rgba(82, 92, 204, 0.3);
            }

            .search:focus {
                border: 1px solid rgba(82, 92, 204, 1);
            }

            .search-img {
                position: absolute;
                right: 10px;
                top: 10px;
            }
        }

        .table-container {
            margin-top: 20px;

            table {
                width: 100%;
                border-collapse: collapse;
                text-align: center;

                tr {
                    height: 70px;
                }

                .tr-head {
                    font-weight: normal;
                    /* font-size: 13px; */
                    font-size: clamp(12px, 1vw, 13px);
                    color: #999999;
                    border-bottom: 1px solid rgba(61, 73, 204, 0.1);
                }

                .tr-head td:first-of-type {
                    // text-align: right;
                    // padding-right: 20px;
                }

                .tr-body {
                    font-weight: normal;
                    /* font-size: 14px; */
                    font-size: clamp(12px, 1vw, 14px);
                    color: #404040;
                    border-bottom: 1px solid rgba(61, 73, 204, 0.1);

                    &:hover {
                        background: rgba(61, 73, 204, 0.05);
                    }
                }

                .tr-body td:last-of-type {
                    padding-left: 20px;
                }

                .tr-body td:first-of-type {
                    display: inline-flex;
                    column-gap: 10px;
                    align-items: center;
                    padding-right: 20px;
                    height: 70px;
                    text-align: right;
                    inline-size: -webkit-fill-available;
                    // transform: translate3d(0px, 15px, 10px);
                }

                .crypto-img {
                    width: 40px;
                    height: 40px;
                }

                td.change {
                    direction: ltr;
                }

                .positive-change {
                    color: #80CC52;
                }

                .negetive-change {
                    color: rgb(247, 131, 131);
                }

                .sell-buy {
                    display: inline-flex;
                    align-items: center;
                    column-gap: 10px;
                    justify-content: center;

                    .buy-btn,
                    .sell-btn {
                        width: 80px;
                        height: 36px;
                        box-sizing: border-box;
                        border-radius: 3px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                    }

                    .buy-btn:hover {
                        border: 1px solid #80CC52;
                        color: #80CC52;
                    }

                    .sell-btn:hover {
                        border: 1px solid #D72F70;
                        color: #D72F70;
                    }
                }

            }

            .chart-img {
                width: 9vw;
            }
        }
    }
}

label {
    position: relative;
}

@media only screen and (max-width:900px) {

    .chart-img,
    .chart-td {
        display: none;
    }
}

@media only screen and (max-width:768px) {

    .crypto-img,
    .buy-td,
    .sell-td,
    .change {
        display: none;
    }

    .sell-buy {
        // display: revert;
        display: table-cell !important;
    }

    .buy-btn,
    .sell-btn {
        width: unset;
    }
}
</style>
