<template>
    <div class="message-container">
        <div class="message" v-for="messageList , index in messageList" :key="index">
            <div class="heade">
                <div class="person">
                    <img class="avatar" :src=messageList.img alt="">
                    <span>
                        {{messageList.name}}
                    </span>
                </div>
                <span>
                    {{messageList.date}}
                </span>
            </div>
            <div class="body">
                {{messageList.body}}
            </div>
            <div class="footer">
                <div>
                    <img src="@/assets/answer.svg" alt="">
                    <span class="footer-p">
                        پاسخ
                    </span>
                </div>
                <div class="liks-container">
                    <div>
                        <span class="footer-p">
                            {{messageList.unlike}}
                        </span>
                        <img src="@/assets/disLike.png" alt="" @click="unlikeFun(index)">
                    </div>
                    <div>
                        <span class="footer-p">
                            {{messageList.like}}
                        </span>
                        <img src="@/assets/like.png" alt="" @click="likeFun(index)">
                    </div>
                </div>
            </div>
            <div class="answer"  v-for="answer , indexA in messageList.answer" :key="indexA">
                <div class="heade">
                    <div class="person">
                        <img class="avatar" :src=answer.img alt="">
                        <span>
                            {{answer.name}}
                        </span>
                    </div>
                    <span>
                        {{answer.date}}
                    </span>
                </div>
                <div class="body">
                    {{answer.body}}
                </div>
                <div class="footer">
                    <div>
                        <img src="@/assets/answer.svg" alt="">
                        <span class="footer-p">
                            پاسخ
                        </span>
                    </div>
                    <div class="liks-container">
                        <div>
                            <span class="footer-p">
                                {{answer.unlike}}
                            </span>
                            <img src="@/assets/disLike.png" alt="" @click="unlikeFunAns(index)">
                        </div>
                        <div>
                            <span class="footer-p">
                                {{answer.like}}
                            </span>
                            <img src="@/assets/like.png" alt="" @click="likeFunAns(index)">
                        </div>
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </div>
</template>

<script>
export default {
  name: 'CommetsList',
  components :{
      
  },
  data() {
      return {
          messageList: [
          {
              name:"زهرا الهی",
              body:"لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است",
              date:"xxxx/xx/xx",
              like:14,
              unlike:37,
              img:require('../../assets/avatar.jpg'),
                 answer: [
                    {
                        name:"زهرا الهی",
                        body:"لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است",
                        date:"xxxx/xx/xx",
                        like:14,
                        unlike:37,
                        img:require('../../assets/avatar.jpg'),
                    }
                ]
          },
          {
              name:"زهرا الهی",
              body:"لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است",
              date:"xxxx/xx/xx",
              like:32,
              unlike:12,
              img:require('../../assets/avatar.jpg'),
          },
          {
              name:"زهرا الهی",
              body:"لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است",
              date:"xxxx/xx/xx",
              like:8,
              unlike:5,
              img:require('../../assets/avatar.jpg'),
                answer: [
                    {
                        name:"زهرا الهی",
                        body:"لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است",
                        date:"xxxx/xx/xx",
                        like:3,
                        unlike:68,
                        img:require('../../assets/avatar.jpg'),
                    }
                ]
          }
      ],
     
      }
  },
  methods : {
      likeFun(index) {
          this.messageList[index].like ++ 
      },
      unlikeFun(index) {
          this.messageList[index].unlike ++ 
      },
      likeFunAns(index) {
          this.messageList[index].answer[0].like ++ 
        // console.log(this.messageList[index].answer[0].like)
      },
      unlikeFunAns(index) {
          this.messageList[index].answer[0].unlike ++ 
      }
  }
}
</script>

<style lang="scss" scoped>
    .message , .answer{
        display: flex;
        flex-direction: column;
        row-gap: 15px;
    }
    .heade {
        display: flex;
        align-items: center;
        justify-content: space-between;
            .person {
                display: flex;
                align-items: center;
                column-gap: 15px;
            }
    }
    .avatar {
        width: 50px;
        height: 50px;
    }
    .body {
        font-size: 14px;
    }
    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .liks-container {
        display: flex;
        align-items: center;
        column-gap: 30px;
    }
    .footer-p {
        color: #BDBDBD;
        font-size: 14px;
    }
    hr {
        border: none;
        background: rgba(64, 64, 64, 0.1);
        height: 1px;
        width: 100%;
    }
    .answer {
        box-sizing: border-box;
        padding: 0 50px;
    }
</style>