<template>
<transition name="modal-fade">
    <div class="modal-backdrop">
        <!-- <img class="close-img" src="@/assets/close.png" alt="" @click="closeLogin"> -->
        <div class="modal">
            <svg class="close-svg" width="23" height="22" viewBox="0 0 23 22" fill="black" xmlns="http://www.w3.org/2000/svg" @click="closeLogin">
                <path d="M20.1625 0.393398L11.2132 9.34272L2.26388 0.393398C1.80622 -0.0642598 1.06426 -0.0642597 0.606602 0.393398C0.148943 0.851057 0.148943 1.59302 0.606602 2.05068L9.55592 11L0.606602 19.9493C0.148943 20.407 0.148943 21.1489 0.606602 21.6066C1.06426 22.0643 1.80622 22.0643 2.26388 21.6066L11.2132 12.6573L20.1625 21.6066C20.6202 22.0643 21.3621 22.0643 21.8198 21.6066C22.2775 21.1489 22.2775 20.407 21.8198 19.9493L12.8705 11L21.8198 2.05068C22.2775 1.59302 22.2775 0.851057 21.8198 0.393398C21.3621 -0.0642597 20.6202 -0.0642597 20.1625 0.393398Z" fill="black" />
            </svg>
            <p class="title">
                واریز به کیف پول تومان
            </p>
            <p class="describe">
                جهت افزایش اعتبار کیف پول تومان خود با استفاده از کارت‌های بانکب عضو شبکه شتاب و از طریق درگاه پرداخت، حتما باید از کارت‌های بانکی به نام خودتان که در پروفایلتان ثبت و تأیید شده است استفاده نمایید.
            </p>
            <div class="inputs-container">
                <label for="" class="personal-label">
                    <input class="personal-input" type="text" @input="amount = $toLocal(amount)"  v-model="amount" placeholder="مبلغ به تومان">
                    <span class="input-title">
                        مبلغ به تومان
                    </span>
                </label>
                <label for="" class="personal-label">
                    <!-- <input class="personal-input" type="text" name="" id="" placeholder="شماره کارت"> -->
                    <select class="personal-input" v-model="walletAddress" id="walletaddress">
                        <option value="" disabled selected>انتخاب شماره کارت</option>
                        <option v-for="(Bank,index) in Banks" :key="index" :value="Bank.cardNumber">
                            {{Bank.cardNumber}}
                        </option>
                    </select>
                    <span class="input-title">
                        شماره کارت
                    </span>
                    <p class="add-bank" @click="$router.push('/profile')">
                        افزودن کارت بانکی
                    </p>
                </label>
            </div>
            <button class="toPay" @click="deposit" :disabled="!amount || !walletAddress || ($S2N(amount) < 1000)">
                انتقال به درگاه پرداخت
            </button>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    name: 'WalletTD',
    components: {

    },
    props: {
        relatedCoin: {
            default: ''
        },
    },
    data() {
        return {
            amount: '',
            Banks: [],
            walletAddress: '',
        }
    },

    methods: {
        closeLogin() {
            this.$emit('close')
        },
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/users/verified-bank-accounts')
            this.Banks = res.content
        },
        async deposit() {
            this.state.loading = true
            let walletInfo = {
                amount: this.$S2N(this.amount),
                paymentGateway: 'Vandar',
                cardNumber: this.walletAddress.replace(/-/ig,''),
                callBackURL: this.$callBackURL
            }

            const res = await this.$axios.post('/wallets/deposit-toman', walletInfo)

            if (res.message === "Deposit Request Created Successfully") {
                this.$emit('close');
                window.open(res.baseDTO.redirectSite, "_self")
            }
        },
    },
    created: function () {
        window.addEventListener("click", (event) => {
            if (!event.target.closest('.modal , .info-btn')) {
                this.$emit('close')
                // console.log("aaaaaa")
            } else if (event.target.closest('.modal , .login-p')) {
                // console.log("bbbbb");
            }

        })
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(93, 109, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    background: #FFFFFF;
    border-radius: 3px;
    max-width: 400px;
    width: 90vw;
    min-height: 332px;
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    position: relative;
    row-gap: 15px;
}

.close-svg {
    position: absolute;
    right: 10px;
    z-index: 111;
}

.title {
    font-size: 22px;
    color: #3D49CC;
}

.describe {
    font-size: 14px;
}

.toPay {
    background: #3D49CC;
    border-radius: 3px;
    border: none;
    color: #FFFFFF;
    font-size: 14px;
    width: 100%;
    height: 44px;
}

.inputs-container {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-bottom: 30px;
}

.add-bank {
    position: absolute;
    top: 35px;
    left: 0;
    font-size: 12px;
    color: rgba(61, 73, 204, 0.6);
    border-bottom: 1px solid rgba(61, 73, 204, 0.6);
    text-align: left;
    cursor: pointer;
}
</style>
