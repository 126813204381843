<template>
<div class="dashboard">
    <div class="title">
        <div class="email-warning">
            <span class="email">
                {{email}}
            </span>
            <div class="warning-container">
                <img src="@/assets/warning.svg" alt="">
                <span class="warning" @click="$router.push('/profile')">
                    {{warning}}
                </span>
            </div>
        </div>
        <button class="level">
            سطح کاربری : {{$levels[state.userInfo.userAccountLevel]}}
        </button>
    </div>
    <div class="orders-container">
        <OpenOrders />
        <!-- <Appexchart /> -->
        <div class="apex_div">
            <div class="apex_toggle">
                <div class="apex_market" :class="{'active_toggle' : walletType=='P2P'}" @click="walletType='P2P';getWallets()">
                    دارایی بازار
                </div>
                <div class="apex_exchange" :class="{'active_toggle' : walletType=='EXCHANGE'}" @click="walletType='EXCHANGE';getWallets()">
                    دارایی صرافی
                </div>
            </div>
            <apexchart type="donut" width="250" height="280" :options="chartOptions" :series="series" :key="series.length + '' + chartOptions.labels.length + change " />
        </div>
    </div>
    <div class="log-statistics">
        <div class="statistics">
            <div class="friends">
                <p class="statistics-title">
                    تعداد دوستان من
                </p>
                <p class="statistics-number">
                    {{data.countInvitees}}
                    <span class="statistics-unit">
                        نفر
                    </span>
                </p>
            </div>
            <div class="income">
                <p class="statistics-title">
                    مجموع سود من
                </p>
                <p class="statistics-number">
                    {{$toLocal(data.profit)}}
                    <span class="statistics-unit">
                        تومان
                    </span>
                </p>
            </div>
            <button class="invite-btn" @click="$router.push('/invite-friends')">
                دعوت دوستان
            </button>
        </div>
        <div class="logins-container">
            <p class="title-login">
                ورود ها
            </p>
            <Logins />
        </div>

    </div>
</div>
</template>

<script>
import OpenOrders from './OpenOrders.vue'
import Logins from '../History/Logins.vue'
export default {
    name: 'Dashboard',
    components: {
        OpenOrders,
        Logins,

    },
    data() {
        return {
            email: this.state.userInfo.email,
            warning: "تکمیل اطلاعات کاربری",
            series: [0],
            change: 0,
            walletType: 'P2P',
            wallets: [],
            data: {
                countInvitees: 0,
                profit: '',
                referralCode: '',
            },
            chartOptions: {
                chart: {
                    width: '50px',
                    fontFamily: 'shabnam',
                },
                colors: [
                    '#3f51b5', '#03a9f4', '#4caf50', '#f9ce1d', '#FF9800', '#00E396', '#FF4560', '#775DD0', '#5C4742', '#2b908f', '#A300D6', '#C5D86D'
                ],
                theme: {
                    mode: 'light',
                    palette: 'palette2'
                },
                stroke: {
                    width: 1,
                    colors: undefined
                },
                labels: [],
                dataLabels: {
                    style: {
                        fontFamily: 'shabnam'
                    }
                },
                legend: {
                    show: false,
                },
                plotOptions: {
                    pie: {
                        offsetX: 0,
                        offsetY: 0,
                        donut: {
                            size: '65%',
                            background: 'white',
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'shabnam',
                                    fontWeight: 600,
                                    color: 'var(--tooDark)',
                                    offsetY: -17,
                                    formatter: function (val) {
                                        return val.name || val
                                    }
                                },
                                value: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'shabnam',
                                    fontWeight: 400,
                                    color: '#3D49CC',
                                    offsetY: 10,
                                    formatter: function (val) {
                                        return parseFloat(val).toLocaleString()
                                    }
                                },
                                total: {
                                    show: true,
                                    showAlways: false,
                                    label: 'ارزش کل دارایی',
                                    fontSize: '12px',
                                    fontFamily: 'shabnam',
                                    fontWeight: 600,
                                    color: 'black',
                                    formatter: function (w) {
                                        w = w.globals.seriesTotals.reduce((a, b) => a + b)
                                        return w.toLocaleString() + '\n' + 'تومان'
                                    }
                                }
                            }
                        },
                    }
                },
                tooltip: {
                    enabled: true,
                    fillSeriesColor: true,
                    custom: function ({
                        seriesIndex,
                        w
                    }) {
                        return ` ${w.globals.seriesNames[seriesIndex].value.toLocaleString()} ${w.globals.seriesNames[seriesIndex].name} `
                    }
                }
            },
        }
    },
    methods: {
        async getWallets() {
            this.wallets = []
            this.series = []
            const res = await this.$axios.get('/wallets/customer-addresses?walletType=' + this.walletType)
            let entireRes = Object.entries(res)
            const sortCoins = ['TOMAN', 'TETHER', 'BITCOIN', 'ETHEREUM', 'BITCOIN_CASH', 'LITE_COIN', 'RIPPLE', 'CLASSIC_ETHEREUM', 'DASH', 'DOGE_COIN']
            entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
            this.wallets = entireRes
            this.chart()
        },

        chart() {
            console.log('wallet', this.wallets);
            let index = 0
            this.wallets.forEach((e) => {
                    this.chartOptions.labels[index] = {
                        'name': this.$coinLabel[e[0]],
                        'value': e[1].credit || 0
                    }
                    this.series[index] = e[1].totalBalance || e[1].wholePriceOfCredit || 0
                    console.log('ssseeeerrrrr', this.series);
                    index++
                })
                ++this.change
        },

    },
    async mounted() {
        this.state.loading = true
        await this.getWallets()
        const res = await this.$axios.get('/users/invites')
        this.data = res.baseDTO
    },

}
</script>

<style lang="scss" scoped>
.dashboard {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    // max-width: 995px;
    width: 100%;
}

.title {
    background: #FFFFFF;
    border-radius: 3px;
    min-height: 84px;
    box-sizing: border-box;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
}

.level {
    background: #3D49CC;
    border-radius: 3px;
    border: none;
    font-size: 14px;
    color: #FFFFFF;
    width: 150px;
    height: 44px;
}

.email-warning {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.warning-container {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.warning {
    font-size: 12px;
    color: #EB5757;
    border-bottom: 1px solid #EB5757;
    width: max-content;
    cursor: pointer;
}

.statistics {
    background: #FFFFFF;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 20px;
    // width: 65vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 320px;
    min-width: 250px;
    flex-grow: 1;

    p {
        margin: 0;
    }
}

.invite-btn {
    background: #80CC52;
    border-radius: 3px;
    height: 44px;
    width: 100%;
    border: none;
    color: #FFFFFF;
}

.logins-container {
    background: #FFFFFF;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    column-gap: 15px;
    box-sizing: border-box;
    padding: 20px;
    // width: 148vw;
    max-height: 310px;
    // overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    // max-width: 670px;
}

.logins-container::-webkit-scrollbar {
    display: none;
}

.title-login {
    font-size: 20px;
    margin: 0;
}

.log-statistics {
    display: flex;
    column-gap: 15px;
    row-gap: 15px;
    // flex-wrap: wrap;
}

.balance-img {
    max-width: 310px;
    height: 310px;
    width: 90%;
}

.orders-container {
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
    row-gap: 15px;
}

.statistics-title {
    font-size: 20px;
}

.statistics-number {
    font-size: 32px;
    color: #80CC52;
}

.statistics-unit {
    font-size: 18px;
}

.apex_div {
    display: flex;
    flex-direction: column;
    max-width: 310px;
    width: 100%;
    height: 310px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;

    .apex_toggle {
        display: flex;
        justify-content: space-between;
        width: 250px;
        font-size: 14px;
            .apex_market , .apex_exchange {
                color: #3D49CC;
                opacity: 0.5;
                cursor: pointer;
            }
            .active_toggle {
                border-bottom: 2px solid #3D49CC;
                opacity: 1;
                cursor: pointer;
            }
    }
}

@media only screen and (max-width:1000px) {
    .log-statistics {
        flex-wrap: wrap;
    }
}
</style>
