<template>
<div class="exchange" :class="{'dark-child':this.state.dark , 'light-child':this.state.light , 'neon-child':this.state.neon}">
    <div class="title" :class="{'dark-child':this.state.dark , 'light-child':this.state.light , 'neon-child':this.state.neon}">
        <div class="choices">
            <p class="title-p" :class="{'active-exchange':ShopType=='limit'}" @click="ShopType='limit'">
                حد
            </p>
            <p class="title-p" :class="{'active-exchange':ShopType=='market'}" @click="ShopType='market'">
                بازار
            </p>
            <!-- <p class="title-p" :class="{'active-exchange':ShopType=='online'}" @click="ShopType='online'">
                صرافی آنلاین
            </p> -->
        </div>
        <p class="fee">
            کارمزد فعلی : ٪ {{state.userInfo.fee}}
        </p>
    </div>
    <div class="forms_container">
        <SellCrypto :cash="sellCredit" :role="role" :wage="state.userInfo.fee" :decimal="precision" :limit="ShopType" :id="2" :key="'sell'+ShopType" />
        <BuyCrypto :cash="buyCredit" :role="role" :wage="state.userInfo.fee" :decimal="precision" :limit="ShopType" :id="1" :key="'buy'+ShopType" />
        
        <!-- <LimitExchange v-if="limit" />
            <MarketExchange v-if="market"/>
            <OnlineExchange v-if="online"/> -->
    </div>
</div>
</template>

<script>
const decimal = {
    'BITCOIN': 8,
    'BITCOIN_CASH': 3,
    'DOGE_COIN': 0,
    'LITE_COIN': 3,
    'DASH': 3,
    'ETHEREUM': 4,
    'CLASSIC_ETHEREUM': 2,
    'TETHER': 2,
}
// import LimitExchange from './LimitExchange.vue'
// import MarketExchange from './MarketExchange.vue'
// import OnlineExchange from './OnlineExchange.vue'
import BuyCrypto from './BuyCrypto.vue'
import SellCrypto from './SellCrypto.vue'
import {
    tradeTo,
    tradeFrom
} from "@/library/reuseableFunction";
export default {
    name: 'Exchange',
    components: {
        // LimitExchange,
        // MarketExchange,
        // OnlineExchange
        BuyCrypto,
        SellCrypto
    },
    props: [
        'coin',
    ],
    data() {
        return {
            ShopType: 'limit',
            limit: true,
            market: false,
            online: false,
            fee: 0.6,
            sellCredit: 0,
            buyCredit: 0,
            role: {},
            //   backGround: JSON.parse(JSON.stringify(this.background))
        }
    },
    computed: {
        tradeTo,
        tradeFrom,
        precision() {
            return decimal[this.tradeFrom]
        }
    },
    methods: {
        async getWalletCredit() {
            this.state.loading = true
            if (this.state.userInfo.authorized) {
                const res = await this.$axios.get('/wallets/customer-addresses?walletType=P2P', {
                    params: {
                        coins: `${this.tradeFrom},${this.tradeTo}`
                    }
                })
                this.sellCredit = res[this.tradeFrom].credit || 0
                this.buyCredit = res[this.tradeTo].credit || 0
            }
            const coins = await this.$axios.get('/coins')
            this.role = coins.content.find((a) => a.coin === this.tradeFrom)
             this.state.loading = false
        },
        closemodal() {
            this.showmodal = false
        },
        tuochHandler(e) {
            if (e === 'right') {
                this.limit = false
            } else {
                this.limit = true
            }
        }
    },
    created() {
        this.getWalletCredit()
    }
}
</script>

<style scoped>
.exchange {
    min-height: 390px;
    box-sizing: border-box;
}

.exchange {
    background: #FFFFFF;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
}

.title {
    /* border-bottom: 1px solid #EDEFF2; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 30px;
    height: 40px;
    box-sizing: border-box;
    padding: 0 10px;
}

.choices {
    display: flex;
    column-gap: 60px;
}

.title-p {
    font-weight: normal;
    font-size: 14px;
    color: #999999;
    cursor: pointer;
    padding: 5px;
    margin: 0;
}

.active-exchange {
    color: #404040;
    border-bottom: 3px solid #3D49CC;
}

.fee {
    font-weight: normal;
    font-size: 12px;
    color: #999999;
}
.forms_container {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;

}
@media only screen and (max-width:700px) {
    .title {
        flex-wrap: wrap;
        justify-content: center;
        height: auto;
    }

    .choices {
        border-bottom: 1px solid #EDEFF2;
    }
}
</style>
