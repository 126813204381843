<template>
<transition name="modal-fade">
    <div class="modal-backdrop" @click.self="state.forgetPass = false">
        <!-- <img class="close-img" src="@/assets/close.png" alt="" @click="closeLogin"> -->
        <div class="modal">
            <svg class="close-svg" width="23" height="22" viewBox="0 0 23 22" fill="black" xmlns="http://www.w3.org/2000/svg" @click="state.forgetPass = false">
                <path d="M20.1625 0.393398L11.2132 9.34272L2.26388 0.393398C1.80622 -0.0642598 1.06426 -0.0642597 0.606602 0.393398C0.148943 0.851057 0.148943 1.59302 0.606602 2.05068L9.55592 11L0.606602 19.9493C0.148943 20.407 0.148943 21.1489 0.606602 21.6066C1.06426 22.0643 1.80622 22.0643 2.26388 21.6066L11.2132 12.6573L20.1625 21.6066C20.6202 22.0643 21.3621 22.0643 21.8198 21.6066C22.2775 21.1489 22.2775 20.407 21.8198 19.9493L12.8705 11L21.8198 2.05068C22.2775 1.59302 22.2775 0.851057 21.8198 0.393398C21.3621 -0.0642597 20.6202 -0.0642597 20.1625 0.393398Z" fill="black" />
            </svg>
            <p class="title">
                فراموشی رمز عبور
            </p>
            <p class="describe">
                آدرس ایمیلی که با آن در بامبو ثبت نام کرده‌اید را وارد نمایید.
            </p>
            <label for="" class="personal-label">
                <input type="text" class="personal-input" placeholder="آدرس ایمیل" v-model="email">
                <span class="input-title">
                    آدرس ایمیل
                </span>
            </label>
            <button class="forget-btn" @click="send" :disabled="!email">
                ارسال ایمیل بازیابی رمز عبور
            </button>
        </div>
    </div>
</transition>
</template>

<script>
//  const goto = {
//         EMAIL: '/reset-pass-email',
//         SMS: '/reset-pass-sms',
//         GOOGLE_AUTHENTICATOR: '/reset-pass-google-auth',
//     }
export default {
    name: 'ForgetPass',
    components: {},
    data() {
        return {
            // data: {
            //     email: '',
            // },
            email: '',

            emailError: '',
        }
    },

    methods: {
        sendMail() {
            this.state.forgetPass = false
            this.state.setPass = true
        },
        checkEmail() {
            if (!this.email) {
                this.emailError = 'ایمیل اجباری';
            } else if (!this.validEmail(this.email)) {
                this.emailError = 'ایمیل صحیح وارد نمایید';
            } else {
                this.emailError = ''
            }
            if (!this.emailError.length) {
                return true;
            }
        },
        validEmail(email) {
            let re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            return re.test(email);
        },
        receive(e) {
            this.email = e
            this.checkEmail(e)
        },
        async send() {
            // if (this.checkEmail(this.email)) {
            await this.checkEmail()
            this.state.loading = true
            const res = await this.$axios.post('/users/forget-password?email=' + this.email)
            this.$cookies.set('username', this.email)
            this.state.userInfo.email = this.email
            if (res.baseDTO) {
                this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
                this.state.userInfo.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType;
                if (this.state.needPass) {
                    this.state.setPass = true
                }
                else 
                 this.state.confirmEmail = true
            } else {
                this.state.userInfo.towStepAuthenticationType = 'EMAIL'
            }
            // this.state.needPass = true
           
            this.state.forgetPass = false
            //     await this.$router.push(goto[res.baseDTO.towStepAuthenticationType])
            // }
        }
    },
}
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(93, 109, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    background: #FFFFFF;
    border-radius: 3px;
    max-width: 400px;
    width: 90vw;
    min-height: 311px;
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    position: relative;
}

.close-svg {
    position: absolute;
    right: 10px;
    z-index: 111;
}

.title {
    color: #3D49CC;
    font-size: 30px;
    margin: 0 10px;
}

.describe {
    font-size: 12px;
}

.forget-btn {
    background: rgba(61, 73, 204, 1);
    border-radius: 3px;
    width: 100%;
    height: 44px;
    border: none;
    color: white;
}

.close-img {
    cursor: pointer;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 30px;
}

.personal-label {
    flex-grow: unset;
}
</style>
