<template>
<div class="basic-trade" :class="{'dark':state.dark , 'light':state.light , 'neon':state.neon}">
    <div class="trade">
        <div class="chart-range" :class="{'dark':state.dark , 'light':state.light , 'neon':state.neon}">
            <div class="chart">
                <vue-trading-view :key="state.tradingTheme+ $coinUnit[$route.params.tradeFrom]" style="height: 237px" :options="{
                            height:'600',
                             symbol: tradeFrom !== 'TETHER' ? 'BINANCE:' + $coinUnit[tradeFrom] + 'USDT' : 'BINANCEUS:USDTUSD',
                            autosize:true,  
                            theme:`${state.tradingTheme}`,  
                                    timezone: 'Asia/Tehran',
                                    style: '2',
                                    hide_top_toolbar:true,
                                    hide_legend:true,
                                    hide_bottom_toolbar:true,
                                    hide_legend:false,
                                    locale: 'fa_IR',
                                    enable_publishing: true,
                                    withdateranges: true,
                                    range: 'ytd',
                                    hide_side_toolbar: true,
                                    allow_symbol_change: true,
                                    enabled_features: [
                                        'header_fullscreen_button'
                                        ],
                                    disabled_features: [
                                        'border_around_the_chart',
                                        'remove_library_container_border',
                                        ],
                                    loading_screen:{
                                        backgroundColor:'#FFFFFF',
                                        foregroundColor: '#FFFFFF',
                                        },
                                    overrides: {
                                        background: '#FFFFFF'
                                    }
                        }" />
            </div>
            <div class="range" :class="{'dark-child':state.dark , 'light-child':state.light , 'neon-child':state.neon}">
                <BasicRange :key="'basictRange'+state.orderChanged + $route.params.tradeTo + $route.params.tradeFrom" />
            </div>
        </div>
        <div class="exchange-container">
            <TradeTitle />
            <BasicMarkets />
            <Exchange :key="'ExchangeBasic' + state.orderChanged + $route.params.tradeTo + $route.params.tradeFrom "  />
        </div>
    </div>
    <History :key="$route.params.tradeTo + $route.params.tradeFrom" />
</div>
</template>

<script>
import {
    // Loop,
    tradeTo,
    tradeFrom,
    precision
} from "@/library/reuseableFunction";
import VueTradingView from 'vue-trading-view/src/vue-trading-view.vue'
import Exchange from './Exchange.vue'
import History from './History.vue'
import TradeTitle from './TradeTitle.vue'
import crypto from '../../library/crypto.js'
import BasicRange from './BasicRange.vue'
import BasicMarkets from './BasicMarkets.vue'
export default {
    name: 'BasicTrade',
    components: {
        History,
        TradeTitle,
        Exchange,
        VueTradingView,
        BasicRange,
        BasicMarkets
    },
    data() {
        return {
            tooman: true,
            teter: false,
            cryptoList: crypto,
        }
    },
    computed: {
        tradeFrom,
        tradeTo,
        precision
    },
    methods: {
        openTooman() {
            if (!this.tooman) {
                this.tooman = true,
                    this.teter = false
            }
        },
        openTeter() {
            if (!this.teter) {
                this.tooman = false,
                    this.teter = true
            }
        }
    }
}
</script>

<style scoped>
.basic-trade {
    width: 100%;
    min-height: calc(100vh - 40px);
    /* background-color: #F2F4F5; */
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.chart-range,
.exchange-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    flex-grow: 1;
    min-height: 645px;
}

.trade {
    display: flex;
    column-gap: 5px;
    row-gap: 10px;
}

.range {
    align-items: flex-start;
    /* background: #FFFFFF; */
    border-radius: 3px;
    min-height: 400px;
    flex-grow: 1;
}

.chart-range {
    width: 41vw;
}

.exchange-container {
    width: 51vw;
}

table {
    width: 45%;
    text-align: center;
}

tr td:first-of-type {
    text-align: right;
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 3px 0;
}

tr td:last-of-type {
    text-align: left;
}

.tr-head {
    font-weight: normal;
    font-size: 12px;
    color: #999999;
}

.table-container {
    display: flex;
    column-gap: 20px;
    align-items: flex-start;
    /* background: #FFFFFF; */
    border-radius: 3px;
    width: 100%;
    flex-direction: column;
}

.tables {
    display: flex;
    column-gap: 20px;
    align-items: flex-start;
    width: 100%;
    width: 95%;
    margin: 0 auto;
    /* column-gap: 70px; */
    justify-content: space-between;
}

img {
    width: 26px;
    height: 26px;
}

.tr-body {
    height: 24px;
    margin: 5px 0;
}

table p {
    margin: 0;
}

table {
    font-weight: normal;
    font-size: 12px;
}

.title-table {
    border-bottom: 1px solid #EDEFF2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 30px;
    padding-right: 25px;
    box-sizing: border-box;
    width: 100%;
}

.title-p {
    font-weight: normal;
    font-size: 14px;
    color: #999999;
    cursor: pointer;
    padding: 5px;
    margin: 0;
}

.active-open {
    color: #404040;
    border-bottom: 3px solid #3D49CC;
}

.negative-change {
    direction: ltr;
}

@media only screen and (max-width:1200px) {
    .tables {
        flex-wrap: wrap;
    }

    table {
        width: 100%;
        table-layout: fixed;
    }

    .head-second {
        display: none;
    }
}

@media only screen and (max-width:1000px) {
    .trade {
        flex-direction: column;
    }

    .chart-range,
    .exchange-container {
        width: 100%;
    }
}
</style>
