<template>
    <div class="crypto-container">
        <div class="crypto">
            <div class="statistics-container">
                <div class="statistics">
                    <div class="statistics-list">
                        <div class="title" >
                            <img src="" alt="">
                            <img class="crypto-img" :src=cryptoImg alt="">
                            <span class="name">
                                {{cryptoName}}
                            </span>
                        </div>
                        <div class="title-number" >
                            <span :class="{'positive-change':this.info[0].change>0 , 'negative-change':this.info[0].change<0}">
                                {{cryptoPrice.toLocaleString()}}
                            </span>
                        </div>
                    </div>
                    <div class="statistics-list" v-for="info , index in info" :key="index">
                        <span class="statistics-list-title">
                            {{info.title}}
                        </span>
                        <span class="statistics-list-number" :class="{'positive-change':info.change>0 , 'negative-change':info.change<0}">
                            {{info.number.toLocaleString()}}<span v-if="info.change">%</span> <span v-if="info.change>0">+</span>
                        </span>
                    </div>
                    <div class="btns-container">
                        <button class="buy-btn">
                            خرید بیتکوین
                        </button>
                        <button class="sell-btn">
                            فروش بیتکوین
                        </button>
                    </div>
                </div>
                <div class="traiding-container">
                    <vue-trading-view :key="this.$store.state.tradingTheme"
                            style="height: 400px;width:100%"
                            :options="{
                                height:'600',
                                autosize:true,  
                                theme:'light',  
                                        timezone: 'Asia/Tehran',
                                        style: '1',
                                        locale: 'fa_IR',
                                        enable_publishing: true,
                                        withdateranges: true,
                                        range: 'ytd',
                                        hide_side_toolbar: true,
                                        allow_symbol_change: true,
                                        enabled_features: [
                                            'header_fullscreen_button'
                                            ],
                                        disabled_features: [
                                            'border_around_the_chart',
                                            'remove_library_container_border',
                                            ],
                                        loading_screen:{
                                            backgroundColor:'#FFFFFF',
                                            foregroundColor: '#FFFFFF',
                                            },
                                        overrides: {
                                            background: '#FFFFFF'
                                        }
                            }"
                        />
                </div>
            </div>
            <hr>
            <div class="describe-container">
                <DescribeCrypto/>
                <div class="line">
                    <span class="line-title">
                        افزودن نظر
                    </span>
                </div>
                <AddComment/>
                <div class="line">
                    <span class="line-title">
                        نظرات کاربران ({{commentsN}})
                    </span>
                </div>
                <CommentsList/>
            </div>
        </div>
    </div>
</template>

<script>
import VueTradingView from 'vue-trading-view/src/vue-trading-view.vue'
import AddComment from './AddComment.vue'
import DescribeCrypto from './DescribeCrypto.vue'
import CommentsList from './CommentsList.vue'
// import crypto from '../../library/crypto.js'
export default {
  name: 'CryptoInfo',
  components :{
    VueTradingView,
    DescribeCrypto,
    AddComment,
    CommentsList
  },
  data() {
      return {
          commentsN:3,
        //   cryptoList:crypto,
        cryptoName:"بیتکوین",
        cryptoImg:require('../../assets/Coins/btc.png'),
        cryptoPrice:1231321321,
        select:2,
        info : [
            {
                title:"درصد تغییرات",
                number:1.25,
                change:1
            },
            {
                title:"بیشترین",
                number:21321313213
            },
            {
                title:"کمترین",
                number:2131313131
            },
            {
                title:"حجم",
                number:798798797987
            }
        ]
      }
  },
  methods : {
      
  }
  }
</script>

<style lang="scss" scoped>
    .crypto-container {
        background: #F2F4F5;
        padding: 50px 0 50px;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        margin-top: 50px;
        flex-wrap: wrap;
            .crypto {
                max-width: 1250px;
                background: #FFFFFF;
                flex-grow: 1;
                box-sizing: border-box;
                // padding: 20px;
                border-radius: 3px;
            }
    }
    .statistics-container {
        display: flex;
        column-gap: 20px;
        flex-grow: 1;
        box-sizing: border-box;
        padding: 20px 10px;
        min-height: 400px;
        flex-wrap: wrap;
        row-gap: 20px;
            .statistics {
                display: flex;
                flex-direction: column;
                row-gap: 20px;
                flex-grow: 1;
                justify-content: space-around;
                min-width: 300px;
            }
    }
    .btns-container {
        display: flex;
        align-self: center;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
    }
    .buy-btn {
        background: #80CC52;
        border-radius: 3px;
        border: none;
        color: #ffffff;
        font-size: 14px;
        width: 200px;
        height: 44px;
    }
    .sell-btn {
        background: #D72F70;
        border-radius: 3px;
        border: none;
        color: #ffffff;
        font-size: 14px;
        height: 44px;
        width: 200px;
    }
    .traiding-container {
        display: flex;
        flex-grow: 30;
        min-width: 300px;
    }
    .statistics-list , .title {
        display: flex;
        column-gap: 10px;
        align-items: center;
        justify-content: space-between;
    }
    hr {
        margin: 30px 0;
        background: rgba(61, 73, 204, 0.1);
        border: none;
        height: 1px;
    }
    .crypto-img {
        width: 50px;
        height: 50px;
    }
    .statistics-list-title {
        font-size: 14px;
        color: #999999;
    }
    .statistics-list-number {
        font-size: 18px;
    }
    .positive-change {

    }
    .name {
        font-size: 24px;
        color: #525CCC;
    }
    .title-number {
        font-size: 18px;
    }
    .line {
        position: relative;
        border-bottom: 1px solid rgba(61, 73, 204, 0.1);
        margin: 50px 0;
    }
    .line-title {
        position: absolute;
        bottom: -15px;
        background: white;
        padding: 0 10px;
        font-size: 22px;
    }
    .describe-container {
        box-sizing: border-box;
        padding: 20px;
    }
</style>
