<template>
<div class="BankInfo">
    <div class="title-container">
        <p class="title">
            اطلاعات بانکی
        </p>
        <p class="add-bank" @click.prevent="addaccount" v-if="bankAccount.cardNumber && bankAccount.shebaCode">
            افزودن حساب جدید
        </p>
    </div>
    <div class="banks">
        <label for="" class="personal-label">
            <input class="personal-input" type="text" placeholder="شماره کارت" @input="dash2" maxlength="19" v-model="bankAccount.cardNumber">
            <span class="input-title">
                شماره کارت
            </span>
        </label>
        <label for="" class="personal-label">
            <input class="personal-input" type="text" placeholder="شماره شبا" @input="bankAccount.shebaCode = bankAccount.shebaCode.replace(/[^\d.]|\.\./ig,'');error='';" v-model="bankAccount.shebaCode" maxlength="24">
            <span class="input-title">
                شماره شبا
            </span>
        </label>
        <label for="" class="personal-label">
            <input class="personal-input" type="text" placeholder="نام بانک" v-model="bankAccount.bankName" disabled>
            <span class="input-title">
                نام بانک
            </span>
        </label>
    </div>
    <p>
        حساب های فعلی
    </p>
    <template v-if="data.bankAccounts.cardNumber!=''">
        <div class="inputs" v-for="(bank , index) in data.bankAccounts" :key="index">
            <label class="personal-label bank-lable">
                <input type="text" class="personal-input" v-model="bank.cardNumber" disabled>
            </label>
            <label class="personal-label bank-lable">
                <input type="text" class="personal-input" v-model="bank.shebaCode" disabled>
            </label>
            <label class="personal-label bank-lable">
                <input type="text" class="personal-input" v-model="bank.bankName" disabled>
                <span class="add-delete" @click.prevent="removeaccount(index)"  v-if="data.bankAccounts.length >= 2">
                    حذف
                </span>
            </label>
        </div>
    </template>
</div>
</template>

<script>
const banks = {
    '603799': 'بانک ملی ایران',
    '589210': 'بانک سپه',
    '627648': 'بانک توسعه صادرات',
    '207177': 'بانک توسعه صادرات',
    '627353': 'بانک تجارت',
    '585983': 'بانک تجارت',
    '627961': 'بانک صنعت و معدن',
    '603770': 'بانک کشاورزی',
    '639217': 'بانک کشاورزی',
    '628023': 'بانک مسکن',
    '627760': 'پست بانک ایران',
    '502908': 'بانک توسعه تعاون',
    '627412': 'بانک اقتصاد نوین',
    '622106': 'بانک پارسیان',
    '639194': 'بانک پارسیان',
    '627884': 'بانک پارسیان',
    '502229': 'بانک پاسارگاد',
    '639347': 'بانک پاسارگاد',
    '639599': 'بانک قوامین',
    '504706': 'بانک شهر',
    '502806': 'بانک شهر',
    '603769': 'بانک صادرات',
    '610433': 'بانک ملت',
    '991975': 'بانک ملت',
    '589463': 'بانک رفاه',
    '502938': 'بانک دی',
    '639607': 'بانک سرمایه',
    '627381': 'بانک انصار',
    '505785': 'بانک ایران زمین',
    '636214': 'بانک آینده',
    '636949': 'بانک حکمت ایرانیان',
    '621986': 'بانک سامان',
    '639346': 'بانک سینا',
    '606373': 'بانک قرض الحسنه مهر',
    '627488': 'بانک کارآفرین',
    '502910': 'بانک کارآفرین',
    '505416': 'بانک گردشگری',
    '639370': 'بانک مهر اقتصاد',
    '628157': 'موسسه اعتباری توسعه',
    '505801': 'موسسه اعتباری کوثر',
}
export default {
    name: 'Profile',
    components: {},
    props: ['data', 'error', 'change'],
    data() {
        return {
            addBanks: [{
                    placeholder: "شماره کارت",
                    span: "شماره کارت",
                    model: "cartNumber"
                },
                {
                    placeholder: "شماره شبا",
                    span: "شماره شبا",
                    model: "shaba"
                },
                {
                    placeholder: "نام بانک",
                    span: "نام بانک",
                    model: "bank"
                }
            ],
            banks: [{
                bankcartNumber: "6037697422921137",
                bankShaba: "586876546654654",
                bankName: "سامان"
            }],
            bankAccount: {
                shebaCode: '',
                cardNumber: '',
                bankName: ''
            }
        }
    },
    methods: {
        addaccount() {
            if (this.bankAccount.shebaCode != '' && this.bankAccount.cardNumber != '') {
                this.data.bankAccounts.push(this.bankAccount)
                this.$emit('addBank', this.bankAccount)
                this.bankAccount = {
                    shebaCode: '',
                    cardNumber: '',
                    bankName: ''
                }
            }
        },
        removeaccount(e) {
            this.data.bankAccounts.splice(e, 1)
        },
        dash(e) {
            this.data.bankAccounts[e].bankName = banks[this.data.bankAccounts[e].cardNumber ? this.data.bankAccounts[e].cardNumber.replace(/-/ig, '').slice(0, 6) : ''] || 'کارت نامعتبر'
            this.data.bankAccounts[e].cardNumber = this.$separator(this.data.bankAccounts[e].cardNumber)
            this.error = ''
        },

        dash2() {
            this.bankAccount.bankName = banks[this.bankAccount.cardNumber ? this.bankAccount.cardNumber.replace(/-/ig, '').slice(0, 6) : ''] || 'کارت نامعتبر'
            this.bankAccount.cardNumber = this.$separator(this.bankAccount.cardNumber)
            this.error = ''
        }
    },
    mounted() {
        // eslint-disable-next-line no-unused-vars
        for (let [index, item] of this.data.bankAccounts.entries()) {
            this.dash(index)
        }
    }
}
</script>

<style lang="scss" scoped>
.BankInfo {
    // max-width:995px;
    background: #FFFFFF;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    box-sizing: border-box;
    padding: 20px;
    flex-grow: 1;

    .title {
        font-size: 22px;
        font-size: clamp(20px, 1vw, 22px);
        margin: 0;
    }

    .add-bank , .add-delete {
        // font-size: 14px;
        font-size: clamp(12px, 1vw, 14px);
        color: rgba(61, 73, 204, 0.6);
        border-bottom: 1px solid rgba(61, 73, 204, 0.6);
        margin: 0;
        cursor: pointer;
    }
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.banks {
    display: flex;
    column-gap: 20px;
    row-gap: 15px;
    flex-wrap: wrap;
}

.banks {
    display: flex;
    flex-wrap: wrap;
}

.banks-info {
    color: #3D49CC;
    border: 1px solid rgba(61, 73, 204, 0.3);
    box-sizing: border-box;
    border-radius: 3px;
    height: 44px;
    flex-grow: 1;
    width: 20vw;
    min-width: 200px;
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.inputs {
    display: flex;
    column-gap: 20px;
    flex-wrap: wrap;
    row-gap: 20px;
}

.bank-lable {
    // width: 100%;
    display: flex;
    align-items: center;
    column-gap: 10px;
}
</style>
