<template>
<transition name="modal-fade">
    <div class="modal-backdrop" @click.self="$emit('onChildNotif',false)">
        <div class="notices">
            <div class="add-notices">
                <p class="add-notice-title">
                    اعلانات
                </p>
                <hr>
                <div class="notification-container">
                    <div class="notification" v-for="(Notifications , index) in messages" :key="index" @click="$router.push('/notifications')">
                        <div class="notification-title">
                            <p class="title">
                                <img src="@/assets/green-dote.png" alt="">
                                {{Notifications.title}}
                            </p>
                            <p class="date">
                                {{$G2J(Notifications.createdAtDateTime)}}
                            </p>
                        </div>
                        <div class="notification-text">
                            {{Notifications.content}}
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </div>
</transition>
</template>

<script>
import Notifications from '../../library/NotificationList.js'
export default {
    name: 'Notices',
    components: {

    },
    data() {
        return {
            NotificationsList: Notifications,
            title: "لورم ایپسوم",
            date: "xxxx/xx/xx",
            text: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است",
            image: require('../../assets/Coins/btc.png'),
            name: "بیت کوین",
            unit: "تومان",
            price: 5465464654,
            change: 1.5,
            messages: []
        }
    },
    methods: {
        async getUnreadMessages(page = this.page, perPage = this.perPage) {
            const res = await this.$axios.get('/users/notifications', {
                params: {
                    read: false,
                    page: page,
                    size: perPage,
                }
            })
            if (res.message !== 'No Notifications') {
                this.messages.push(...res.content)
            } else {
                this.unreadmessages = []
                this.unreadTotalElements = [1]
            }
        },
        async getReadMessages(page = this.page, perPage = this.perPage) {
            const res = await this.$axios.get('/users/notifications', {
                params: {
                    read: true,
                    page: page,
                    size: perPage,
                }
            })
            if (res.message !== 'No Notifications') {
                if (res.totalElements !== this.readmessages.length) {
                    this.messages.push(...res.content)
                }
            }
        },
        async readMessage(e, index) {
            if (!this.unreadmessages[index].read) {
                await this.$axios.put(`/users/notifications/read/${e}`)
                this.unreadmessages[index].read = true
            }
            this.$forceUpdate()
        },
        async load() {
            if (!this.loading) {
                this.loading = true;
                document.body.style.cursor = 'wait';
                this.page++
                await this.getMessages()
                document.body.style.cursor = 'default';
                this.loading = false;
            }
        }
    },
    mounted() {
        this.getReadMessages()
        this.getUnreadMessages()

    }
}
</script>

<style scoped>
.notices {
    max-width: 400px;
    position: absolute;
    top: 10px;
    z-index: 10;
    left: 20px;
    width: 30vw;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    z-index: 200;
    min-width: 300px;
    height: 500px;
    background: var(--background-notif);
}

.add-notices {
    min-height: 278px;
}

.add-notices,
.notices-list {

    box-shadow: 10px 0px 15px 5px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(40px);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;

    box-sizing: border-box;
    padding: 20px 20px;
}

.add-notices {
    /* justify-content: space-between; */
    flex-grow: 1;
}

.notices-list {
    justify-content: flex-start;
    min-height: 400px;
}

.add-notice-title {
    font-weight: normal;
    font-size: 18px;
    color: #3D49CC;
}

hr {
    background: rgba(61, 73, 204, 1);
    opacity: 0.1;
    width: 100%;
}

.currency-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.currency,
.currency-name {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.currency-img {
    width: 40px;
    height: 40px;
}

input {
    width: 100%;
    border: 1px solid rgba(61, 73, 204, 0.5);
    box-sizing: border-box;
    border-radius: 3px;
    outline: none;
    height: 44px;
    color: rgba(61, 73, 204, 1);
}

input::placeholder {
    color: rgba(61, 73, 204, 0.5);
}

input:focus {
    border: 1px solid rgba(61, 73, 204, 1);
}

.statistics {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.statistics p {
    margin: 2px;
}

.notice-btn {
    background: #3D49CC;
    border-radius: 3px;
    color: #FFFFFF;
    width: 100%;
    height: 44px;
    border: none;
}

p {
    margin: 0;
}

.price {
    font-size: 16px;
}

.change {
    font-size: 12px;
}

.positive-change {
    color: #80CC52;
}

.negative-change {
    color: #D72F70;
}

table {
    width: 100%;
    /* text-align: center; */
}

tr {
    height: 30px;
}

.tr-heade {
    color: #999999;
}

tr-body {
    font-size: 12px;
}

.notification-container {
    height: 400px;
    overflow: scroll;
}

.notification {
    width: 100%;
    cursor: pointer;
}

.notification-title {
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: space-between;
    color: #3D49CC;
}

.notification-text {
    font-weight: normal;
    font-size: 12px;
    color: #3D49CC;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.01);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}
</style>
