<template>
    <div class="body">
        <div class="body-container">
            <HomeTable id="market"/>
            <Application/>
            <WhyBamboo/>
        </div>
        <div class="statistics-container">
            <Statistics/>
        </div>
        <div class="faq-container">
            <FAQ class="faq-body"/>
            <div class="faq-img-container">
                <img class="faq-img" src="@/assets/faq.png" alt="">
            </div>
        </div>
        
    </div>
</template>

<script>
import HomeTable from "./HomeTable.vue"
import Application from "./Application.vue"
import WhyBamboo from "./WhyBamboo.vue"
import Statistics from './Statistics.vue'
import FAQ from "./FAQ.vue"
export default {
  name: 'Body',
  components :{
    HomeTable,
    Application,
    WhyBamboo,
    Statistics,
    FAQ
    
  },
  data() {
      return {
          
      }
  },
  watch: {
      '$route.path'() {
          if(this.$route.path.includes('markets')) {
            document.getElementById('market').scrollIntoView({
              behavior: 'smooth'
            })
      }
      else {
          window.scrollTo({
              top:0,
              left:0,
              behavior: 'smooth'
          })
      }
      }
  },
  mounted() {
      if(this.$route.path.includes('markets')) {
            document.getElementById('market').scrollIntoView({
              behavior: 'smooth'
            })
      }
        
  }
  }
</script>

<style lang="scss" scoped>
    .body {
        width: 100%;
        background-color:#ffff;
        min-height: 2492px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        display: flex;
        flex-direction: column;
        row-gap: 50px;
            .body-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .statistics-container{
                width: 100%;
                background: #3D49CC;
            }
            .faq-container {
                display: flex;
                // align-items: flex-end;
                justify-content: space-between;
                flex-direction: row;
                width: 100%;
                // margin: 0 auto;
                // padding:0 20px;
                column-gap: 20px;
                align-self: center;
                row-gap: 40px;
                box-sizing: border-box;
                padding: 20px;
                
                max-width: 1250px;
                    .faq-img {
                        max-width: 100%;
                        width: 71vw;
                        max-width: 500px;
                    }
                    .faq-img-container {
                        // flex-grow: 1;
                        // display: flex;
                        justify-content: center;
                    }
            }
    }
    @media only screen and (max-width:800px) {
        .faq-container {
            flex-wrap: wrap-reverse;
        }
    }
</style>
