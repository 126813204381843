<template>
<div class="market">
    <div class="market-list" :class="{'dark-child':this.state.dark , 'light-child':this.state.light , 'neon-child':this.state.neon}">
        <div class="title">
            <p class="title-p" :class="{'active-open':tooman}" @click="tooman=true;changeUnit()">
                تومان
            </p>
            <p class="title-p" :class="{'active-open':!tooman}" @click="tooman=false;changeUnit()">
                تتر 
            </p>
        </div>
        <MarketsPrice :toman="tooman" />
    </div>
    <div class="crypto" :class="{'dark-child':this.state.dark , 'light-child':this.state.light , 'neon-child':this.state.neon}">
        <MarketsTrade />
    </div>
</div>
</template>

<script>
import MarketsPrice from './MarketsPrice.vue'
import MarketsTrade from './MarketsTrade.vue'
// import crypto from "../../library/crypto.js"
import {
    tradeTo,
    tradeFrom,
    // Loop,
    precision
} from "@/library/reuseableFunction";
export default {
    name: 'Market',
    components: {
        MarketsPrice,
        MarketsTrade
    },
    data() {
        return {
            tooman: true,
            // teter: false,
            tableContents: [],
            loading: false,
            loop: '',
            cryptoList: []
        }
    },
    computed: {
        tradeTo,
        tradeFrom,
        precision,
    },
    methods: {
        changeUnit() {
            this.$router.push('/advanced-trade/' + (this.tooman ? 'TOMAN' : 'TETHER') + '/' + this.tradeFrom)
        },
        openTooman() {
            if (!this.tooman) {
                this.tooman = true
                this.teter = false
            }
        },
        openTeter() {
            if (!this.teter) {
                this.tooman = false
                this.teter = true
            }
        },

    },
}
</script>

<style scoped>
.market {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    /* max-width: 315px; */
    width: 22vw;
        min-width: 300px;
    flex-grow: 1;
    /* order: 2; */
}

.market-list,
.crypto {
    /* background: #FFFFFF; */
    border-radius: 3px;
}

.crypto {
    min-height: 386px;
    height: 250px;
    overflow: scroll;
    flex-grow: 1;
}

.market-list {
    /* min-height: 334px; */
    /* height: 50%; */
}

.title {
    border-bottom: 1px solid #EDEFF2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 30px;
    padding-right: 25px;
    box-sizing: border-box;
}

.title-p {
    font-weight: normal;
    font-size: 14px;
    color: #999999;
    cursor: pointer;
    padding: 5px;
    margin: 0;
}

.active-open {
    /* color: #404040; */
    border-bottom: 3px solid #3D49CC;
}

.tr-head {
    font-weight: normal;
    /* font-size: 12px; */
    font-size: clamp(10px, 1vw, 12px);
    color: #999999;
    height: 28px;
    margin: 4px 0;
}

.tr-body {
    font-weight: normal;
    /* font-size: 12px; */
    font-size: clamp(10px, 1vw, 12px);
    color: #404040;
    height: 24px;
    margin: 4px 0;
}

table {
    width: 100%;
    /* max-width: 315px; */
    padding: 0 10px;
    text-align: center;
    table-layout: fixed;
}

tr td:last-of-type {
    text-align: left;
}

tr td:first-of-type {
    text-align: right;
}

.positive-change {
    color: #80CC52;
}

.negative-change {
    color: #D72F70;

}
</style>
