<template>
<div class="authentication">
    <div>
        <p class="title">
            احراز هویت
        </p>
    </div>
    <div>
        <p class="describe">
            لطفا تصویر کارت ملی را در کنار چهره خودتان همراه با یک دست نوشته به صورت کاملا خوانا و واضح ،دقیقا مانند تصویر نمونه ،ارسال نمایید. دقت شود متن دست نوشته نبایستی تایپ شود. هم چنین توجه داشته باشید هنگام تهیه تصویر ، متن دست نوشته و اطلاعات کارت ملی به صورت برعکس و آینه ای ثبت نشود.
        </p>
    </div>
    <div>
        <p class="authen-txt">
            متن دست نوشته :
        </p>
        <p class="authen-txt">
            اینجانب )نام و نام خانوادگی( به کد ملی )کد ملی( ضمن مطالعه و تایید قوانین استفاده از خدمات بامبو، متعهد می گردم که حساب کاربری ومدارک خود را به منظور خرید و فروش ارزهای دیجیتال در اختیار سایر افراد قرار ندهم.
        </p>
        <p class="authen-txt">
            جهت احراز هویت در سایت بامبو
        </p>
        <p class="authen-txt">
            نام و نام خانوادگی امضا و تاریخ
        </p>
    </div>
    <div class="upload">
        <div class="upload-inputs">
            <form class="upload-input" :class="{'disable-form' : readonly}" @submit.prevent="upload" @dragover.prevent @dragenter="hovered=!hovered" @dragleave="hovered=!hovered" @drop.stop.prevent="hovered=false;drop($event);" :style="{borderColor : hovered? 'blue' : ''}">
                <img v-if="path" class="cloud" :src="path" alt="">
                <img v-else class="cloud" src="@/assets/cloud.png" alt="">
                <input class="upload-file" name="picture" id="picture"  type="file"  @change="upload($event.target)" :disabled="readonly" v-if="!readonly" required>
                 <!-- <label for="picture" class="input-file-btn"> بارگذاری تصویر</label> -->
                <button class="upload-btn">
                    آپلود عکس کارت ملی
                </button>
            </form>
            <!-- <div class="upload-input">
                <img class="cloud" src="@/assets/cloud.png" alt="">
                <input id="upload-file" type="file">
                <button class="upload-btn">
                    آپلود عکس کارت ملی
                </button>
            </div> -->
        </div>
        <div class="upload-edu">
            <img src="@/assets/correctUpload.png" alt="">
            <img src="@/assets/incorrectUpload.png" alt="">
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'Profile',
    components: {

    },
    props: ['data'],
    data() {
        return {
            path:'',
            loadedpicture: '',
            hovered: '',
        }
    },
    watch: {
        'data.image'() {
            if (this.data.image)
                // if (typeof this.data.image === 'string')
                    this.path = this.data.image
        }
    },
    computed: {
        readonly() {
            return ['AUTHORIZED', "INFORMATION_CHANGED"].includes(this.state.userInfo.authenticationType)
        }
    },
    methods: {
        drop(e) {
            let dt = e.dataTransfer
            this.upload(dt)
        },
        upload(evt) {
            let file = evt.files[0]
            this.data.image = file
            let fileReader = new FileReader();
            fileReader.addEventListener("loadstart", (event) => {
                this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
            })
            fileReader.addEventListener("progress", (event) => {
                this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
            });
            fileReader.addEventListener('load', (event) => {
                this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                this.path = event.target.result
            });
            fileReader.readAsDataURL(file)
        },
        hover() {
            this.hovered = true
        }
    },
}
</script>

<style lang="scss" scoped>
.authentication {
    // max-width:995px;
    background: #FFFFFF;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    box-sizing: border-box;
    padding: 20px;
    flex-grow: 1;

    .title {
        font-size: 22px;
        margin: 0;
    }
}

.authen-txt {
    font-size: 14px;
    color: #80CC52;
    margin: 0;
}

.upload {
    display: flex;
    column-gap: 30px;
    row-gap: 30px;
    flex-wrap: wrap;
    cursor: pointer;
    justify-content: center;
}

.upload-edu {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    justify-content: center;
    flex-grow: 1;

    img {
        max-width: 177px;
        height: 232px;
    }
}

.upload-input {
    max-width: 267px;
    min-width: 200px;
    // width: 18vw;
    height: 232px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    border-radius: 5px;
    border: 1px dashed #80CC52;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
}

.upload-inputs {
    display: flex;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    flex-grow: 1;
}

.upload-file {
    opacity: 0;
    position: absolute;
    right: 0;
    height: 100%;
    width: 100%;
}

.cloud {
    width: 100px;
    height: 88px;
    position: absolute;
    top: 20%;
}

.upload-btn {
    background: #80CC52;
    border-radius: 3px;
    color: #FFFFFF;
    font-size: 14px;
    border: none;
    height: 44px;
    max-width: 170px;
    margin: 20px;
    width: 100%;
    // width: 15vw;
}
.disable-form {
    opacity: 0.5;
}
</style>
