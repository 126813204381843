<template>
<table>
    <tr class="tr-head">
        <td>
            قیمت
        </td>
        <td>
            مقدار
        </td>
        <td>
            زمان
        </td>
    </tr>
    <tr v-if="!tableContents.length" class="tr-body">
        <td colspan="4">
            <p style="width:100%;text-align:center">در حال حاضر اطلاعاتی وجود ندارد</p>
        </td>
    </tr>
    <tr class="tr-body" v-for="(tableContent , index) in tableContents" :key="index" :class="{'positive-change' : tableContent.orderType.includes('BUY') , 'negative-change':tableContent.orderType.includes('SELL')}">
        <td>
            {{$toLocal(tableContent.wholePrice)}}
        </td>
        <td>
            {{$toLocal(tableContent.amount)}}
        </td>
        <td>
            {{$G2J(tableContent.createdAtDateTime,'time')}}
        </td>
    </tr>
</table>
</template>

<script>

export default {
    name: "LastTrade",
    components: {
    },
    data() {
        return {
            tableContents: [],
            loading: false,
            alive: true
        }
    },
    methods: {
        async getLastTrades() {
            const res = await this.$axios.get('/trades/last-trades?type=P2P', {
                params: {
                    marketType: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo,
                    size: 20
                }
            })
            this.tableContents = res.content
            this.loading = false
        },
        async loop() {
            this.loading = true

            while (this.alive) {
                await this.getLastTrades()
                this.loading = false
                await this.$sleep(5000)
            }
        }
    },
    mounted() {
        this.alive = true
        if (this.state.loop) {
            this.loop()
        } else {
            this.getLastTrades()
        }
    },
    beforeDestroy() {
        this.alive = false
    }

}
</script>

<style scoped>
.tr-head {
    font-weight: normal;
    /* font-size: 12px; */
    font-size: clamp(10px, 1vw, 12px);
    color: #999999;
    height: 28px;
    margin: 4px 0;
    position: sticky;
    top: 0;
    background: var(--background-child);
}

.tr-body {
    font-weight: normal;
    /* font-size: 12px; */
    font-size: clamp(10px, 1vw, 12px);
    color: #404040;
    height: 24px;
    margin: 4px 0;
}

table {
    width: 100%;
    /* max-width: 315px; */
    /* padding: 0 10px; */
    text-align: center;
    table-layout: fixed;
}

tr td:last-of-type {
    text-align: left;
    padding-left: 10px;
}

tr td:first-of-type {
    text-align: right;
    padding-right: 10px;
}

.positive-change {
    color: #80CC52;
}

.negative-change {
    color: #D72F70;

}
</style>
