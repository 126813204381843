<template>
<div class="invite">
    <!-- <div class="invite-title"> -->
        <p class="title-p">
            دعوت از دوستان
        </p>
        <!-- <p class="describe">
            لطفا تصویر کارت ملی را در کنار چهره خودتان همراه با یک دست نوشته به صورت کاملا خوانا و واضح ، دقیقا مانند تصویر نمونه ، ارسال نمایید . دقت شود متن دست نوشته نبایستی تایپ شود. هم چنین توجه داشته باشید هنگام تهیه تصویر ، متن دست نوشته و اطلاعات کارت ملی بصورت برعکس و آینه ای ثبت نشود.
        </p> -->
    <!-- </div> -->
    <div class="invite-information">
        <div class="information-part">
            <div class="link">
                <div class="link-title">
                    <span>
                        کد دعوت:
                    </span>
                    <img class="copy-img" src="@/assets/copy.png" alt="" @click="copyToClipCode">
                    <span class="copied" v-if="alertCode">
                        کپی شد!
                    </span>
                </div>
                <span class="code-link">
                    {{data.referralCode}}
                </span>
            </div>
            <div class="statistics">
                <p>
                    تعداد دوستان من :
                </p>
                <div class="statistics-parts">
                    <div class="statistics-part">
                        <span class="number"> {{data.countInvitees}} </span> <span class="unit"> نفر </span>
                    </div>
                    <div class="statistics-part">
                        <img class="friends-img" src='@/assets/friends.png' alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="information-part">
            <div class="link">
                <div class="link-title">
                    <span>
                        لینک دعوت:
                    </span>
                    <img class="copy-img" src="@/assets/copy.png" alt="" @click="copyToClipLink">
                    <span class="copied" v-if="alertLink">
                        کپی شد!
                    </span>
                </div>
                <span class="code-link">
                    bamboex.com/register?refer_code={{data.referralCode}}
                </span>
            </div>
            <div class="statistics">
                <p>
                    مجموع سود من :
                </p>
                <div class="statistics-parts">
                    <div class="statistics-part">
                        <span class="number"> {{data.profit}} </span> <span class="unit"> تومان </span>
                    </div>
                    <div class="statistics-part">
                        <img class="friends-img" src='@/assets/income.png' alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'InviteFriend',
    components: {

    },
    data() {
        return {
            alertLink: false,
            alertCode : false,
            data: {
                countInvitees: 0,
                profit: '',
                referralCode: '',
            },
        }
    },
    methods: {
        copyToClipCode() {
            navigator.clipboard.writeText(this.data.referralCode);
            this.alertCode = true
            setTimeout(() => {
                this.alertCode = false;
            }, 1500);

        },
        copyToClipLink() {
            navigator.clipboard.writeText('bamboo.com/register?refer_code='+this.data.referralCode);
            this.alertLink = true
            setTimeout(() => {
                this.alertLink = false;
            }, 1500);

        },
        //  copyToClipid() {
        // navigator.clipboard.writeText(this.Links[0].invitId);
        // this.alertId=true
        // setTimeout(() => { this.alertId=false; }, 1500);

        // }
    },
    async mounted() {
        const res = await this.$axios.get('/users/invites')
        this.data = res.baseDTO
    }
}
</script>

<style lang="scss" scoped>
.invite {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    // max-width: 995px;
    width: 100%;
}

.invite-title {
    background: #FFFFFF;
    border-radius: 3px;
    padding: 15px 20px;
    min-height: 171px;

    .describe {
        font-size: clamp(14px, 1vw, 16px);
    }
}

.title-p {
    font-size: 22px;
    margin-top: 0;
}

.link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 20px;
    /* max-width: 490px; */
    min-height: 68px;
    background: #FFFFFF;
    border-radius: 3px;
    position: relative;
}

.statistics {
    background: #FFFFFF;
    border-radius: 3px;
    min-height: 230px;
    box-sizing: border-box;
    padding: 20px;
    height: 100%;
    /* max-width: 490px; */
}

.invite-information {
    display: flex;
    column-gap: 15px;
    row-gap: 15px;
    flex-wrap: wrap;
}

.information-part {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    row-gap: 15px;
    flex-basis: 400px;
}

.statistics {
    p {
        margin: 0;
    }
}

.statistics-parts {
    display: flex;
    flex-wrap: wrap;
}

.statistics-part {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    column-gap: 10px;
}

.unit {
    font-size: 18px;
    color: #80CC52;
}

.number {
    font-size: 32px;
    color: #80CC52;
}

.link-title {
    display: flex;
    align-items: center;
    column-gap: 10px;

    span {
        font-size: clamp(14px, 1vw, 16px);
    }
}

.copy-img {
    cursor: pointer;
}

.copied {
    padding: 3px;
    border: 2px solid #80CC52;
    background: #c6f8a6;
    border-radius: 5px;
    position: absolute;
    right: 150px;
}

.friends-img {
    width: 10vw;
    min-width: 120px;
}

.code-link {
    font-size: clamp(12px, 1vw, 16px);
    text-align: left;
}
</style>
