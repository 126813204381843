<template>
<div class="buy">
    <div class="heade">
        <div class="name-container">
            <img class="btc-img" :src="'/coins/'+ destCoinSymbol + '.png'" alt="">
            <span class="name">
                {{$coins[destCoinSymbol].persianName}}
            </span>
        </div>
        <span class="price-sell" v-if="data.unitPrice">
            تومان {{$toLocal(data.unitPrice,0)}}
        </span>
    </div>
    <hr>
    <div class="sellBamboo">
        <div class="balance-container" v-if="state.token">
            <span>
                موجودی
            </span>
            <span v-if="balance">
                {{$toLocal(balance.credit,$decimal[destCoinSymbol])}} {{$coins[destCoinSymbol].code}}
            </span>
        </div>
        <label for="" class="personal-label">
            <input class="personal-input" type="text" :placeholder=" 'مقدار ' +  $coins[destCoinSymbol].persianName" @input="inputsFun()" v-model="data.amount">
            <span class="input-title">
                مقدار {{$coins[destCoinSymbol].persianName}}
            </span>
        </label>
    </div>

    <hr>
    <div class="total-container">
        <span>
            مجموع
        </span>
        <span>
            {{data.totalPrice}} تومان
        </span>
    </div>
    <hr>
    <button class="sellButton" :disabled="!!buyReadOnly" @click="buy" v-if="state.token">
        <span v-if="waiting">
            لطفا منتظر بمانید ...
        </span>
        <span v-else-if="!!buyReadOnly">
            {{buyReadOnly}}
        </span>
        <span v-else>
            فروش
        </span>
    </button>
    <button v-else class="login-btn" @click="state.SellModal=false;state.login = true">
        ورود
    </button>
    <!-- <div class="buttons">
            <button :class="{'active':state.SellBamboo}" @click="sellBamboo">
                فروش از کیف پول بامبو
            </button>
            <button :class="{'active':state.SellOther}" @click="sellOther">
                فروش از کیف پول دیگر
            </button>
        </div> -->
    <!-- <hr> -->
    <!-- <SellFirst v-if="this.$store.state.SellFirst"/> -->
    <!-- <SellBamboo v-if="state.SellBamboo" /> -->
    <!-- <SellOther v-if="state.SellOther"/> -->
</div>
</template>

// 

<script>
import {
    Loop
} from "@/library/reuseableFunction";
// import SellFirst from './SellFirst.vue'
// import SellBamboo from './SellBamboo.vue'
// import SellOther from './SellOther.vue'
export default {
    name: 'BuyModal',
    components: {
        //   SellFirst,
        // SellBamboo,
        //   SellOther
    },
    props: ['destCoinSymbol', 'price'],
    data() {
        return {
            coin: [],
            nets: [],
            waiting: false,
            loop: undefined,
            netDrop: false,
            selected_net: '',
            minAmountExchange: null,
            maxAmountExchange: null,
            balance: {},
            temptData: {
                amount: null,
                totalPrice: null,
                destinationCoin: null,
                unitPrice: null,
                destinationWallet: '',
                tokenType: null,
            },
            data: {
                marketType: this.destCoinSymbol + '_TOMAN',
                amount: null,
                sourceCoin: "TOMAN",
                destinationCoin: this.destCoinSymbol,
                orderType: "EXCHANGE_SELL",
                unitPrice: this.price,
                totalPrice: null,
                destinationWallet: '',
                tokenType: null,
            },

        }
    },
    computed: {
        buyReadOnly() {
            console.log(this.balance.credit);
            let error = ''
            if (!this.$A2N(this.data.amount)) {
                error = 'مقدار ارز اجباری است'
            } else if (this.$A2N(this.data.amount) < this.$S2N(this.minAmountExchange)) {
                error = 'حداقل مقدار رعایت نشده'
            } else if (this.$S2N(this.data.amount) > this.$A2N(this.balance.credit)) {
                error = 'مقدار بیش از موجودی شماست'
            } else if (this.$A2N(this.data.amount) > this.$S2N(this.maxAmountExchange)) {
                error = 'حداکثر مقدار رعایت نشده'
            }
            return error

        },
    },

    methods: {
        async buy() {
            this.state.loading = true
            this.temptData = JSON.parse(JSON.stringify(this.data))
            this.temptData.amount = this.$S2N(this.data.amount)
            this.temptData.totalPrice = this.$S2N(this.data.totalPrice)
            this.temptData.destinationCoin = this.data.destinationCoin
            this.temptData.unitPrice = this.$S2N(this.data.unitPrice)
            this.temptData.destinationWallet = this.data.destinationWallet
            this.temptData.tokenType = this.selected_net
            // if (!this.state.BuyOther) {
            delete this.temptData.destinationWallet
            delete this.temptData.tokenType

            this.state.loading = true
            await this.$axios.post(
                '/exchange/sell', this.temptData
            )
            // this.success = true
            this.$emit('openSuccess', this.temptData)

            // } 
            // else {

            //     this.state.loading = true
            //     await this.$axios.post(
            //         '/exchange/buy', this.temptData
            //     )

            //     this.$emit('openSuccess', this.temptData)

            // }
            this.state.SellModal = false
            this.state.loading = false

        },
        buyBamboo() {
            this.state.BuyBamboo = true
            this.state.BuyOther = false
            this.state.BuyFirst = false
        },

        buyOther() {
            this.state.BuyBamboo = false
            this.state.BuyOther = true
            this.state.BuyFirst = false
        },
        async tomanTetherWallet() {
            if (this.state.token) {
                this.loading = true
                const [res2, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE&coins=' + this.destCoinSymbol)
                if (res2) {
                    this.balance = res2[this.destCoinSymbol]
                }
                this.loading = false
            }

        },

        async getPrice() {
            // this.state.loading = true
            const res = await this.$axios.get('coins/exchange-price/' + 'usd' + '?includeWeeklyChange=false&destCoinSymbol=' + this.destCoinSymbol)
            this.data.unitPrice = res.find(a => a.destCoinSymbol == this.destCoinSymbol).sellPrice
            this.data.totalPrice = this.$toLocal(this.$S2N(this.data.amount) * this.$S2N(this.data.unitPrice), this.$decimal['TOMAN'])
            // this.state.loading = false
        },

        async inputsFun() {
            this.waiting = true
            await this.getPrice()
            // if (e === 'amount') {
            this.data.amount = this.$toLocal(this.data.amount, this.$decimal[this.destCoinSymbol])
            this.data.totalPrice = this.$toLocal(this.$S2N(this.data.amount) * this.$S2N(this.data.unitPrice), this.$decimal['TOMAN'])

            // } else {
            //     this.data.totalPrice = this.$toLocal(this.data.totalPrice, this.$decimal['TOMAN'])
            //     this.data.amount = this.$toLocal(this.$S2N(this.data.totalPrice) / this.$S2N(this.data.unitPrice), this.$decimal[this.destCoinSymbol])
            // }
            this.waiting = false
        },
        async getCoin() {
            this.state.loading = true
            const res = await this.$axios.get('/coins/details')
            this.coin = res.content
            this.nets = this.coin.find((a) => a.coin === this.destCoinSymbol).tokenTypeDetails
            // this.getNets()
        },
        selectFun(index) {
            this.selected_net = this.nets[index].tokenType

            this.txIdIsNeeded = this.nets[index].txIdIsNeeded
            this.data.tokenType = this.nets[index].tokenType
            this.netDrop = false

        },
        MinMaxAmount() {
            this.minAmountExchange = this.coin.find(a => a.coin == this.destCoinSymbol).minAmountExchange
            this.maxAmountExchange = this.coin.find(a => a.coin == this.destCoinSymbol).maxAmountExchange
        },
        beforeLoop() {
            if (this.state.loop) {
                this.loop = new Loop(this.getPrice, 5000, 'realPriceBuy' + 'Toman')
                this.loop.start()
            } else {
                this.getPrice()
            }
        }
    },
    beforeDestroy() {
        if (this.loop) {
            this.loop.stop()
        }

    },
    async mounted() {
        this.state.loading = true
        await this.beforeLoop()
        this.tomanTetherWallet()
        await this.getCoin()
        this.MinMaxAmount()
        this.state.loading = false
    }

}
</script>

<style lang="scss" scoped>
hr {
    border: none;
    background-color: rgba(61, 73, 204, 0.1);
    height: 1px;
    width: 100%;
}

.buttons {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 20px 0;
}

button {
    height: 44px;
    flex-grow: 1;
    border: 1px solid rgba(61, 73, 204, 0.5);
    color: rgba(61, 73, 204, 0.5);
    background: transparent;
    border-radius: 3px;
}

.active {
    border: 1px solid rgba(61, 73, 204, 1);
    color: rgba(61, 73, 204, 1);
}

.balance {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.balance-increase {
    color: rgba(61, 73, 204, 1);
    border-bottom: 1px solid rgba(61, 73, 204, 1);
}

.balance-number {
    color: rgba(153, 153, 153, 1);

    span {
        border-bottom: 1px solid rgba(153, 153, 153, 1);
    }
}

.balance-container,
.total-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.total-container {
    margin: 20px 0;
}

.balance-container {
    font-size: 14px;
    color: rgba(153, 153, 153, 1);
}

.heade {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.name {
    color: #525CCC;
}

.name-container {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.sellBamboo {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.btc-img {
    width: 35px;
    height: 35px;
}

.sellButton {
    background: #D72F70;
    border-radius: 3px;
    color: white;
    width: 100%;
    border: none;
}

.login-btn {
    background: #3D49CC;
    border-radius: 3px;
    color: white;
    width: 100%;
    border: none;
}

.price-sell {
    color: #D72F70;
}
</style>
