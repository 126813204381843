<template>
    <transition name="modal-fade" >
        <div class="modal-backdrop" >
            <img class="close-img" src="@/assets/close.png" alt="" @click="closeLogin">
            <div class="success" >
                <img class="success-img" src="@/assets/success.png" alt="">
                <p class="success-txt">
                    درخواست شما با موفقیت ثبت شد.
                </p>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'SuccessChangePass',
  components :{
    
  },
  data() {
      return {
          
      }
  },
    
  methods : {
      onComplete() {
          this.active=true
      },
      closeLogin() {
          this.$store.state.successChange=false
      },
  }
  }
</script>

<style scoped>

    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(93, 109, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
  .success {
    background: #FFFFFF;
    border-radius: 3px;
    max-width: 400px;
    width: 90vw;
    min-height: 241px;
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
    justify-content: space-around;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
    .close-img {
        cursor: pointer;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 30px;
    }
    .success-img {
        width: 100px;
        height: 84px;
    }
    .success-txt {
        color: #3D49CC;
        font-size: 22px;
    }
</style>
