<template>
<transition name="modal-fade">
    <div class="modal-backdrop" @click.self="state.confirmEmail=false">
        <!-- <img class="close-img" src="@/assets/close.png" alt="" @click="closeLogin"> -->
        <div class="modal">
            <svg class="close-svg" width="23" height="22" viewBox="0 0 23 22" fill="black" xmlns="http://www.w3.org/2000/svg" @click="state.confirmEmail=false">
                <path d="M20.1625 0.393398L11.2132 9.34272L2.26388 0.393398C1.80622 -0.0642598 1.06426 -0.0642597 0.606602 0.393398C0.148943 0.851057 0.148943 1.59302 0.606602 2.05068L9.55592 11L0.606602 19.9493C0.148943 20.407 0.148943 21.1489 0.606602 21.6066C1.06426 22.0643 1.80622 22.0643 2.26388 21.6066L11.2132 12.6573L20.1625 21.6066C20.6202 22.0643 21.3621 22.0643 21.8198 21.6066C22.2775 21.1489 22.2775 20.407 21.8198 19.9493L12.8705 11L21.8198 2.05068C22.2775 1.59302 22.2775 0.851057 21.8198 0.393398C21.3621 -0.0642597 20.6202 -0.0642597 20.1625 0.393398Z" fill="black" />
            </svg>
            <p class="title" v-if="state.pathMatch=='EMAIL'">
                تأیید آدرس ایمیل 
            </p>
            <p class="title" v-if="state.pathMatch=='SMS'">
                شناسایی دو عاملی
            </p>
            <p class="title" v-if="state.pathMatch=='GOOGLE_AUTHENTICATOR'">
                شناسایی دو عاملی
            </p>
            <p class="describe" v-if="state.pathMatch=='EMAIL'">
                کد شش رقمی ارسال شده به آدرس ایمیل خود را وارد نمایید.
            </p>
            <p class="describe" v-if="state.pathMatch=='SMS'">
                کد شش رقمی ارسال شده به شماره موبایل خود را وارد نمایید.
            </p>
            <p class="describe" v-if="state.pathMatch=='GOOGLE_AUTHENTICATOR'">
               کد شش رقمی تولید شده توسط برنامه Google Authenticator را وارد نمایید.
            </p>
            <div>
                <CodeInput @change="onChange" @complete="submit" :fields="num" ref="code-input" />
            </div>
            <div class="count_area">
                {{countDown}} ثانیه
            </div>
            <button v-if="countDown===0" class="active-btn" @click.prevent="send">
                ارسال مجدد
            </button>
            <button v-else  @click="submit" class="active-btn" :disabled="disabled">
                ثبت
            </button>
        </div>
    </div>
</transition>
</template>

<script>
import CodeInput from "vue-verification-code-input";
import {

    reset,
    paste
} from "@/library/reuseableFunction";
export default {
    name: 'ConfirmEmail',
    components: {
        CodeInput
    },
    data() {
        return {
            countDown: 120,
            error: '',
            code: '',
            num: 6,
            loading: false,
            otp: {
                code: '',
                email: this.state.userInfo.email || this.$cookies.get('username')
            }
        }
    },
    computed: {
        disabled() {
            return this.otp.code.toString().length < this.num || this.countDown === 0 || this.loading
        }
    },
    methods: {
        checkCode() {
            this.state.confirmEmail = false
        },
        closeLogin() {
            this.state.confirmEmail = false
        },
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        async send() {
            this.state.loading = true
            this.reset()
            await this.$axios.post(`/users/resend-otp-code?email=${this.$cookies.get('username')}`)
            const text = {
                EMAIL: 'ایمیل',
                SMS: 'شماره'
            }
            this.$error('کد جدید به ' + text[this.state.pathMatch] + ' شما ارسال شد.', '', 'success')
            this.$cookies.set('username', this.state.userInfo.email)
            if (this.countDown === 0) {
                this.countDown = 120
                this.countDownTimer()
            }

        },
        onChange(e) {
            this.otp.code = e
        },
        async submit() {
            if (!this.disabled) {
                this.loading = true
                this.state.loading = true
                const res = await this.$axios.post('/users/check-otp-code', this.otp).catch(() => {})
                this.reset()
                this.loading = false
                if (res.token) {
                    await this.$setToken(res.token)
                    this.state.confirmEmail=false
                    this.state.login=false
                    await this.$router.push('/profile')
                }
            }
            this.state.confirmEmail = false
            this.state.loading = false
        },

        reset,
        paste
    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
    },
    mounted() {
        console.log(this.state.pathMatch);
        if (!this.otp.email) {
            this.$router.push('/')
        }
        if (this.state.pathMatch !== 'GOOGLE_AUTHENTICATOR') {
            this.countDownTimer()
        }
    },
}
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(93, 109, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    background: #FFFFFF;
    border-radius: 3px;
    max-width: 400px;
    width: 90vw;
    min-height: 332px;
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    position: relative;
}

.close-svg {
    position: absolute;
    right: 10px;
    z-index: 111;
}

.title {
    color: #3D49CC;
    font-size: 30px;
    margin: 0 10px;
}

.describe {
    font-size: 12px;
}

.deActive-btn,
.active-btn {

    border-radius: 3px;
    width: 100%;
    height: 44px;
    border: none;
    color: white;
}

.deActive-btn {
    background: rgba(61, 73, 204, 0.5);
}

.active-btn {
    background: rgba(61, 73, 204, 1);
}

.close-img {
    cursor: pointer;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 30px;
}
.count_area {
    text-align: right;
}
</style>
