<template>
<transition name="modal-fade">
    <div class="modal-backdrop">
        <!-- <img class="close-img" src="@/assets/close.png" alt="" @click="closeLogin"> -->
        <div class="modal">
            <svg class="close-svg" width="23" height="22" viewBox="0 0 23 22" fill="black" xmlns="http://www.w3.org/2000/svg" @click="close">
                <path d="M20.1625 0.393398L11.2132 9.34272L2.26388 0.393398C1.80622 -0.0642598 1.06426 -0.0642597 0.606602 0.393398C0.148943 0.851057 0.148943 1.59302 0.606602 2.05068L9.55592 11L0.606602 19.9493C0.148943 20.407 0.148943 21.1489 0.606602 21.6066C1.06426 22.0643 1.80622 22.0643 2.26388 21.6066L11.2132 12.6573L20.1625 21.6066C20.6202 22.0643 21.3621 22.0643 21.8198 21.6066C22.2775 21.1489 22.2775 20.407 21.8198 19.9493L12.8705 11L21.8198 2.05068C22.2775 1.59302 22.2775 0.851057 21.8198 0.393398C21.3621 -0.0642597 20.6202 -0.0642597 20.1625 0.393398Z" fill="black" />
            </svg>
            <p class="title">
                شناسایی دو عاملی
            </p>
            <p class="describe">
                برای فعالسازی شناسایی دوعاملی،ابتدا برنامه‌ی Google Authenticator را دانلود نموده و سپس QR کد زیر را توسط آن اسکن نمایید.
            </p>
            <img class="qrCode" src="@/assets/qrCode.png" alt="">
            <p class="describe">
                پس از اسکن بارکد، مورد مربوط به بامبو در برنامه شما ایجاد خواهد شد. برای تایید صحت فرآیند، کدی که در اپ خود مشاهده می‌کنید را در کادر زیر وارد نمایید.
            </p>
            <div>
                <CodeInput :loading="false" @change="onChange" @complete="submit" :fields="num" ref="code-input" />
            </div>
            <div>

            </div>
            <button :disabled=disablebtn @click="submit" :class="{'deActive-btn' :this.active==false , 'active-btn' : this.active==true}">
                ثبت و فعالسازی
            </button>
        </div>
    </div>
</transition>
</template>

<script>
import CodeInput from "vue-verification-code-input";
import {
    Copy,
    setPlaceholder,
    reset,
    paste
} from "@/library/reuseableFunction";
export default {
    name: 'TwoFAgoogle',
    components: {
        CodeInput
    },
    props: ['qrCode', 'qrLink'],
    data() {
        return {
            active: false,
            disablebtn: true,
            amount: '',
            twostepcode: '',
            walletaddress: '0x40beE7b4e50d756C57B4e59693905D36B9cCD1B6',
            smscode: [],
            error: '',
            num: 6,
            otp: {
                code: '',
            },
            timeOut: '',
            countDown: 120,
        }
    },

    methods: {
        onComplete() {
            this.active = true
            this.disablebtn = false
        },
        checkCode() {
            this.$store.state.successChange = true
            this.$store.state.twofaGoogle = false
            setTimeout(() => {
                this.$store.state.successChange = false;
            }, 1000);
        },
        close() {
            this.$emit('close')
        },
        onChange(e) {
            this.otp.code = e
        },
        async submit() {
            if (this.otp.code.toString().length === 6 && (this.twoStep === 'GOOGLE_AUTHENTICATOR' || this.countDown !== 0)) {

                this.state.loading = true
                const [res, ] = await this.$http.post(`/users/change-otp?twoStepAuthorization=${this.twoStep}&code=${this.otp.code}`)
                if (res) {
                    this.reset()
                    this.$emit('close')
                    this.$emit('submit', 'شناسایی دو مرحله ای شما به ' +' Google Authenticator' + ' تغییر یافت.')
                }

            }
        },
        Copy,
        paste,
        setPlaceholder,
        reset
    },
    mounted() {
        this.$refs['code-input'].$refs['input_0'][0].focus()
    },
}
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(93, 109, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    background: #FFFFFF;
    border-radius: 3px;
    max-width: 400px;
    width: 90vw;
    min-height: 575px;
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    position: absolute;
}

.close-svg {
    position: absolute;
    right: 10px;
    z-index: 111;
}

.title {
    color: #3D49CC;
    font-size: 30px;
    margin: 0 10px;
}

.describe {
    font-size: 12px;
}

.deActive-btn,
.active-btn {

    border-radius: 3px;
    width: 100%;
    height: 44px;
    border: none;
    color: white;
}

.deActive-btn {
    background: rgba(61, 73, 204, 0.5);
}

.active-btn {
    background: rgba(61, 73, 204, 1);
}

.close-img {
    cursor: pointer;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 30px;
}

.qrCode {
    width: 100px;
    height: 100px;
    align-self: center;
}
</style>
