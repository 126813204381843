<template>
<div class="statistics">
    <div class="statistic" >
        <p class="statistic-number">
           {{$toLocal(userscount)}}
        </p>
        <p class="statistic-title">
            کاربر فعال
        </p>
    </div>
     <div class="statistic" >
        <p class="statistic-number">
           جفت ارز
        </p>
        <p class="statistic-title">
            <!-- {{statistics.title}} -->
        </p>
    </div>
     <div class="statistic" >
        <p class="statistic-number">
            {{$toLocal(totaltrad)}}
        </p>
        <p class="statistic-title">
           معامله ی انجام شده
        </p>
    </div>
     <div class="statistic" >
        <p class="statistic-number">
            {{$toLocal(tradprice,0)}}
        </p>
        <p class="statistic-title">
            ارزش کل معاملات
        </p>
    </div>
</div>
</template>

<script>
export default {
    name: 'Statistics',
    components: {

    },
    data() {
        return {
            statistics: [{
                    title: "کاربر فعال",
                    number: 0
                },
                {
                    title: "جفت ارز",
                    number: 0
                },
                {
                    title: "معامله ی انجام شده",
                    number: 0
                },
                {
                    title: "ارزش کل معاملات",
                    number: 0
                }
            ],
            userscount: '0',
            tradprice: '0',
            totaltrad: '0'
        }
    },
    methods: {
        async getInfo() {
            const res = await this.$axios('trades/market-info-home')
            this.userscount = res.baseDTO.userCount
            this.tradprice = res.baseDTO.tradesValue
            this.totaltrad = res.baseDTO.tradesAmount
        }
    },
    mounted() {
        this.getInfo()
    }
}
</script>

<style lang="scss" scoped>
.statistics {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    min-height: 193px;
    column-gap: 20px;
    flex-wrap: wrap;
    row-gap: 40px;
    column-gap: 40px;
    max-width: 1250px;

    .statistic {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        flex-grow: 1;

        .statistic-number,
        .statistic-title {
            color: #ffff;
            margin: 5px;
        }

        .statistic-number {
            font-weight: bold;
            font-size: clamp(20px, 2vw, 36px);
        }

        .statistic-title {
            font-weight: normal;
            font-size: clamp(14px, 2vw, 18px);
        }
    }
}
</style>
