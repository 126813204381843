<template>
<div class="sideMenu">
    <div class="main-side">
        <div class="main-side-top">
            <div class="side-item" :class="{'active' : $route.path===sideMenus.path}" v-for="(sideMenus , index) in sideMenus" :key="index" @click="activeSide(index)">
                <a :href="sideMenus.path" @click.prevent="$router.push(sideMenus.path)">
                    <inline-svg :src=sideMenus.img />
                    <p class="side-item-p">
                        {{sideMenus.title}}
                    </p>
                </a>
            </div>
        </div>
        <div class="main-side-bottom">
            <div class="side-item" :class="{'active' : $route.path===otherSide.path}" v-for="(otherSide , index) in otherSide" :key="index" @click="activeSide(index)">
                <a :href="otherSide.path" @click.prevent="$router.push(otherSide.path)">
                    <inline-svg :src=otherSide.img />
                    <p class="side-item-p">
                        {{otherSide.title}}
                    </p>
                </a>
            </div>
        </div>
        <div class="exit-container" @click="state.exit = true">
            <inline-svg :src="require('@/assets/exit.svg')" alt=""/>
            <span class="side-item-p">
                خروج
            </span>
        </div>
    </div>
</div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
export default {
    name: 'SideMenus',
    components: {
        InlineSvg
    },
    data() {
        return {
            sideMenus: [{
                    title: "داشبورد",
                    img: require('../../assets/dashboard.svg'),
                    path: '/dashboard'
                },
                {
                    title: "حساب کاربری",
                    img: require('../../assets/account.svg'),
                    path: '/profile'
                },
                {
                    title: "کیف پول بازار",
                    img: require('../../assets/wallet.svg'),
                    path: '/wallet'
                },
                {
                    title: "کیف پول صرافی",
                    img: require('../../assets/wallet.svg'),
                    path: '/otc-wallet'
                },
                {
                    title: "تاریخچه",
                    img: require('../../assets/history.svg'),
                    path: '/history'
                },
                {
                    title: "تنظیمات",
                    img: require('../../assets/setting.svg'),
                    path: '/setting'
                }
            ],
            otherSide: [{
                    title: "اعلانات",
                    img: require('../../assets/notices.svg'),
                    path: '/notifications'
                },
                {
                    title: "دعوت دوستان",
                    img: require('../../assets/invite.svg'),
                    path: '/invite-friends'
                },
                // {
                //     title:"خروج",
                //     img:require('../../assets/invite.svg'),
                //     path:'/invite-friends'
                // }
            ]
        }
    },
    methods: {
        activeSide(index) {
            console.log(index)
        }
    }
}
</script>

<style lang="scss" scoped>
.sideMenu {
    width: 210px;
    // height: 220px;
    // background: #FFFFFF;
    border-radius: 3px;

    .main-side {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        position: sticky;
        top: 50px;

        .side-item,
        a {
            display: flex;
            column-gap: 10px;
            align-items: center;
            box-sizing: border-box;

            height: 44px;

            .side-item-p {
                font-size: 14px;
                font-size: clamp(12px, 1vw, 14px);
            }
            svg {
                fill: #3D49CC;
            }
        }

        .side-item {
            padding: 0 20px;
        }

        .active {
            background: rgba(61, 73, 204, 0.1);
            border-right: 5px solid #3D49CC
        }
    }
    a {
        color: #404040 !important;
    }
}

.main-side-top {
    height: 270px;
    background: #FFFFFF;
    border-radius: 3px;
}

.main-side-bottom {
    height: 88px;
    background: #FFFFFF;
    border-radius: 3px;
}

.exit-container {
    height: 50px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 14px;
    color: #404040;
    background: #FFFFFF;
    padding: 0 20px;
    cursor: pointer;
        svg {
            fill: #3D49CC;
            // width: 50px !important;
        }
}

@media only screen and (max-width:900px) {

    // .sideMenu {
    //     display: none;
    // }
    .side-item-p {
        display: none;
    }

    .sideMenu {
        width: 50px;
    }
}
</style>
