<template>
<div class='advanced'>
    <table>
        <tr class="tr-head">
            <td :class="{'priceTd':title.heade==='قیمت' , 
                            'feeTd':title.heade==='کارمزد' ,
                            'incomTd' : title.heade==='کارمزد' ,
                            'totalTd' : title.heade==='مجموع' , 
                            'amountTd': title.heade==='مقدار' , 
                            'incomeTd' : title.heade==='مقدار دریافتی'}" v-for="title , index in title" :key="index">
                {{title.heade}}
            </td>
        </tr>
        <tr class="tr-body" v-for="tableContent , index in tableContents " :key="index">
            <td>
                <!-- {{body.market}} -->
               {{$coins[tableContent.sourceCoin].persianName}} -
                {{$coins[tableContent.destinationCoin].persianName}} 
            </td>
            <td :class="{'buy':tableContent.orderType.includes('BUY') , 'sell':tableContent.orderType.includes('SELL')}">
                <span v-if="tableContent.orderType.includes('BUY')">
                    خرید
                </span>
                <span v-else>
                    فروش
                </span>
            </td>
            <td class="priceTd">
                {{$toLocal(tableContent.unitPrice,precision)}}
            </td>
            <td class="amountTd">
                {{$toLocal(tableContent.amount)}}
            </td>
            <td class="totalTd">
                {{$toLocal(tableContent.wholePrice,precision)}}
            </td>
            <!-- <td class="feeTd">
                {{body.fee}}
            </td> -->
            <td class="incomeTd">
                <!-- {{body.income}} -->
            </td>
            <td>
                {{$G2J(tableContent.createdAtDateTime)}}
            </td>
        </tr>
    </table>
</div>
</template>

<script>
import {
    tradeTo,
    tradeFrom,
    precision,
    statusColor,
    statusLabel,
    // Loop
} from "@/library/reuseableFunction";
export default {
    name: 'AdvancedBasic',
    components: {

    },
    data() {
        return {
            title: [{
                    heade: "بازار"
                },
                {
                    heade: "عملیات"
                },
                {
                    heade: "قیمت"
                },
                {
                    heade: "مقدار"
                },
                {
                    heade: "مجموع"
                },
                // {
                //     heade: "کارمزد"
                // },
                {
                    heade: "مقدار دریافتی"
                },
                {
                    heade: "زمان"
                }
            ],
            tableContents: [],
            loading: false,
            loop: '',
            page: 0,
            lastPage: 1,
        }
    },
    computed: {
        tradeTo,
        tradeFrom,
        precision,
        statusColor,
        statusLabel
    },
    methods: {
        async getLastOrders() {
            this.state.loading = true
            if (this.page <= this.lastPage) {
                //for scroll loading
                this.page++ //for scroll loading
                let res = await this.$axios.get('/trades', {
                    params: {
                        type : 'P2P',
                        // market: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo,
                        page: this.page,
                        size: 20
                    }
                })
                if (res.content)
                    // res.content = res.content.filter(i => i.orderStatus !== 'IS_OPEN');
                    this.tableContents = res.content;
                this.loading = false
            }
            this.state.loading = false
        },
        // async getLastOrdersBuy() {
        //     // let copyObject = {...this.tableContents}
        //     if (this.page <= this.lastPage) {
        //         //for scroll loading
        //         this.page++ //for scroll loading
        //         let res = await this.$axios.get('/trades', {
        //             params: {
        //                 type : 'BUY',
        //                 // market: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo,
        //                 page: this.page,
        //                 size: 20
        //             }
        //         })
        //         if (res.content)
        //             // res.content = res.content.filter(i => i.orderStatus !== 'IS_OPEN');
        //             this.tableContents.push(res.content);
        //         this.loading = false
        //     }
        // },
    },
    mounted() {
        // this.loading = true
        // if (this.state.loop) {
        //     this.loop = new Loop(this.getLastOrders, 5000, 'lastOrder')
        //     this.loop.start()
        // } else {
            this.getLastOrders()
        // }

    },
    // beforeDestroy() {
    //     this.loop.stop()
    // }
}
</script>

<style lang="scss" scoped>
table {
    width: 100%;
    text-align: center;
}

.tr-head {
    font-size: 12px;
    color: #999999;
    height: 40px;
}

.tr-body {
    font-size: 12px;
    color: #404040;
    height: 30px;
}

.buy {
    color: #80CC52;
}

.sell {
    color: #D72F70;
}

@media only screen and (max-width:900px) {

    .priceTd,
    .feeTd,
    .incomTd,
    .totalTd,
    .amountTd,
    .incomeTd {
        display: none;
    }
}
</style>
