<template>
<transition name="modal-fade">
    <div class="modal-backdrop" @click.self="state.setPass=false">
        <!-- <img class="close-img" src="@/assets/close.png" alt="" @click="closeLogin"> -->
        <div class="modal">
            <svg class="close-svg" width="23" height="22" viewBox="0 0 23 22" fill="black" xmlns="http://www.w3.org/2000/svg" @click="state.setPass=false">
                <path d="M20.1625 0.393398L11.2132 9.34272L2.26388 0.393398C1.80622 -0.0642598 1.06426 -0.0642597 0.606602 0.393398C0.148943 0.851057 0.148943 1.59302 0.606602 2.05068L9.55592 11L0.606602 19.9493C0.148943 20.407 0.148943 21.1489 0.606602 21.6066C1.06426 22.0643 1.80622 22.0643 2.26388 21.6066L11.2132 12.6573L20.1625 21.6066C20.6202 22.0643 21.3621 22.0643 21.8198 21.6066C22.2775 21.1489 22.2775 20.407 21.8198 19.9493L12.8705 11L21.8198 2.05068C22.2775 1.59302 22.2775 0.851057 21.8198 0.393398C21.3621 -0.0642597 20.6202 -0.0642597 20.1625 0.393398Z" fill="black" />
            </svg>
            <p class="title">
                تعیین رمز عبور
            </p>
            <p class="describe" v-if="state.pathMatch=='EMAIL'">
                کد شش رقمی ارسال شده به آدرس ایمیل خود را وارد نمایید.
            </p>
            <p class="describe" v-if="state.pathMatch=='SMS'">
                کد شش رقمی ارسال شده به شماره موبایل خود را وارد نمایید.
            </p>
            <p class="describe" v-if="state.pathMatch=='GOOGLE_AUTHENTICATOR'">
                کد شش رقمی تولید شده توسط برنامه Google Authenticator را وارد نمایید.
            </p>
            <CodeInput @change="onChange" :fields="num" ref="code-input" />
            <div class="count_area">
                {{countDown}} ثانیه
            </div>
            <p class="describe">
                رمز عبور جدید خود را وارد نمایید.
            </p>
            <label for="" class="personal-label">
                <input class="personal-input pass-input" :type="type" placeholder="رمز عبور" v-model="otp.password">
                <span class="input-title">رمز عبور</span>
                <img class="show-pass" src="@/assets/pass.png" alt="">
            </label>
            <button class="forget-btn" @click="send" v-if="countDown == 0">
                ارسال مجدد
            </button>
            <button v-else class="forget-btn" @click="submit" :disabled="disable">
                ثبت
            </button>
        </div>
    </div>
</transition>
</template>

<script>
import {
    reset
} from "@/library/reuseableFunction";
import CodeInput from "vue-verification-code-input";
export default {
    name: 'SetPass',
    components: {
        CodeInput
    },
    data() {
        return {
            type: 'password',
            passError: '',
            countDown: 120,
            num: 6,
            otp: {
                code: '',
                email: this.state.userInfo.email || this.$cookies.get('username') || '',
                password: ''
            }
        }
    },
    computed: {
        disable() {
            return !this.otp.password || this.passError !== '' || this.otp.code.length !== 6 || (this.state.pathMatch !== 'GOOGLE_AUTHENTICATOR' && this.countDown === 0) || this.state.loading
        }
    },
    methods: {
        checkPass() {
            if (!this.otp.password) {
                this.passError = 'رمز اجباری';
            } else {
                if (this.otp.password.length < 8) {
                    this.passError = 'حداقل 8 کاراکتر';
                } else if (!this.validPass(this.otp.password)) {
                    this.passError = 'ترکیبی از حروف کوچک و بزرگ و عدد';
                } else {
                    this.passError = ''
                }
            }
            if (!this.passError.length) {
                return true;
            }
        },
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        onChange(e) {
            this.otp.code = e
        },
        validPass(password) {
            let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
            return re.test(password);
        },
        async submit() {
            if (this.checkPass(this.otp.password) && !this.disabled) {
                this.state.loading = true
                let [res, ] = await this.$http.post('/users/reset-password', this.otp)
                this.reset()
                if (res) {
                    this.state.setPass = false
                    this.$error('رمز عبور با موفقیت تغییر یافت.', '', 'success')
                    this.state.login = true
                    // await this.$router.push('/login')
                }
            }
        },
        async send() {
            this.state.loading = true
            this.reset()
            await this.$axios.post('/users/forget-password?email=' + this.otp.email)
            this.$error('کد جدید ارسال شد.', '', 'success')
            this.$cookies.set('username', this.state.userInfo.email)

            if (this.countDown === 0) {
                if (this.state.userInfo.towStepAuthenticationType !== 'GOOGLE_AUTHENTICATOR')
                    this.countDown = 120
                this.countDownTimer()
            }
        },
        reset
    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
    },
    mounted() {
        if (!this.$cookies.isKey('username')) {
            this.state.login = true
            // this.$router.push('/login')
        }
        if (this.state.pathMatch !== 'GOOGLE_AUTHENTICATOR') {
            this.countDownTimer()
        }
    }

}
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(93, 109, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    background: #FFFFFF;
    border-radius: 3px;
    max-width: 400px;
    width: 90vw;
    min-height: 311px;
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    position: relative;
}

.close-svg {
    position: absolute;
    right: 10px;
    z-index: 111;
}

.title {
    color: #3D49CC;
    font-size: 30px;
    margin: 0 10px;
}

.describe {
    font-size: 12px;
    text-align: right;
}

.forget-btn {
    background: rgba(61, 73, 204, 1);
    border-radius: 3px;
    width: 100%;
    height: 44px;
    border: none;
    color: white;
    margin-top: 20px;
}

.close-img {
    cursor: pointer;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 30px;
}

.personal-label {
    flex-grow: unset;
}

.show-pass {
    width: 20px;
    height: 15px;
    position: absolute;
    top: 15px;
    right: 10px;
    cursor: pointer;
}

.personal-input {
    padding-right: 35px;
}

.count_area {
    font-size: 14px;
    text-align: right;
}
</style>
